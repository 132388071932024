import { gql } from '@apollo/client';
import { COLLECTION_ITEM_FRAGMENT } from 'data/fragments/collectionItem';

export const GET_ITEMS_OF_COLLECTION_QUERY = gql`
    query GetItemsOfCollection($collectionId: ID!) {
        itemsOfCollection(collectionId: $collectionId) {
            ...CollectionItem
        }
    }
    ${COLLECTION_ITEM_FRAGMENT}
`;
