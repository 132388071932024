import { TranslationsHelper } from 'hooks/useTranslations';

export const PreviewEquationsBlock = ({
    equations,
    translationsHelper,
}: {
    equations: string[];
    translationsHelper: TranslationsHelper;
}) => {
    return (
        <>
            {equations.map((equation, index) => {
                return <div key={index}> • {equation}</div>;
            })}
        </>
    );
};
