import { ContentBlockInput } from 'data/generated';
import { ContentBlocks } from '../ContentBlocks';
import styled, { css } from 'styled-components';
import { Title } from '../Title';
import { EditableBlocks } from './EditableBlocks';
import { Label } from 'components/Label';
import { TranslationsHelper } from 'hooks/useTranslations';

export const EditableContentBlocks = ({
    title,
    blocks,
    setBlocks,
    translationsHelper,
}: {
    title?: string;
    blocks: ContentBlockInput[];
    setBlocks: (blocks: ContentBlockInput[]) => void;
    translationsHelper: TranslationsHelper;
}) => {
    return (
        <Container>
            {title && <Title>{title}</Title>}
            <Panels>
                <Panel>
                    <TitleContainer>
                        <Label>Content</Label>
                    </TitleContainer>
                    <ContentContainer>
                        <EditableBlocks translationsHelper={translationsHelper} blocks={blocks} setBlocks={setBlocks} />
                    </ContentContainer>
                </Panel>
                <Panel isDisabled={true}>
                    <TitleContainer>
                        <Label>Preview</Label>
                    </TitleContainer>
                    <ContentContainer>
                        <ContentBlocks translationsHelper={translationsHelper} blocks={blocks} />
                    </ContentContainer>
                </Panel>
            </Panels>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Panels = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid lightgrey;
`;

const Panel = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;

    &:not(:first-child) {
        border-left: 1px solid lightgrey;
    }

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            background-color: ${({ theme }) => theme.colors.backgroundPrimary};
        `}
`;

const TitleContainer = styled.div`
    padding: 10px 15px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-top: 1px solid lightgrey;
    gap: 10px;
`;
