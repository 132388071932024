import { EditableContentBlocks } from 'components/EditableContentBlocks';
import { PuzzleTemplateInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';

export const ContentPuzzle = ({
    template,
    setTemplate,
    translationsHelper,
}: {
    template: PuzzleTemplateInput;
    setTemplate: (template: PuzzleTemplateInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { blocks } = template;

    return (
        <EditableContentBlocks
            blocks={blocks ?? []}
            setBlocks={(blocks) => setTemplate({ ...template, blocks })}
            translationsHelper={{
                ...translationsHelper,
                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.content`,
            }}
        />
    );
};
