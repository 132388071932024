import styled from 'styled-components';
import { MdArrowDownward, MdArrowUpward, MdDelete } from 'react-icons/md';
import { ContentBlockInput } from 'data/generated';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { TranslationsHelper } from 'hooks/useTranslations';
import { ImageTranslationInput } from 'components/ImageTranslationInput';

export const EditableImageBlock = ({
    block,
    setBlock,
    moveDownBlock,
    moveUpBlock,
    deleteBlock,
    translationsHelper,
}: {
    block: ContentBlockInput;
    setBlock: (block: ContentBlockInput) => void;
    moveDownBlock: () => void;
    moveUpBlock: () => void;
    deleteBlock: () => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { imageKey } = block;
    const {
        imageTranslationsHook: { deleteImageTranslation },
    } = translationsHelper;

    return (
        <Container>
            <ActionsContainer>
                <Title>Image</Title>
                <IconButton onClick={moveUpBlock}>
                    <MdArrowUpward size={15} />
                </IconButton>
                <IconButton onClick={moveDownBlock}>
                    <MdArrowDownward size={15} />
                </IconButton>
                <IconButton
                    onClick={() => {
                        deleteBlock();
                        deleteImageTranslation(imageKey);
                    }}
                >
                    <MdDelete size={15} />
                </IconButton>
            </ActionsContainer>
            <ImageTranslationInput
                label="Image"
                onChange={(value) => setBlock({ ...block, imageKey: value })}
                value={block.imageKey ?? ''}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(Label)`
    flex-grow: 1;
`;

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-left: 10px;
    padding: 0;
    width: 30px;
    height: 30px;
`;
