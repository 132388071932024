import { TableView, FullPageLoader, ScrollContainer } from 'components';
import { useGetDiscountsQuery } from 'data/generated';

export const Discounts = () => {
    const { data, loading, error } = useGetDiscountsQuery();

    if (loading) {
        return <FullPageLoader />;
    }

    if (error || !data) {
        return <>{error?.message}</>;
    }

    const DISCOUNT_FIELDS = [
        { name: '_id', label: 'ID' },
        { name: 'amount', label: 'Amount' },
        { name: 'code', label: 'Code' },
        { name: 'restrictedUserIds', label: 'Restricted User Ids' },
        { name: 'state', label: 'State' },
    ];

    const { discounts } = data;

    return (
        <ScrollContainer>
            <TableView fields={DISCOUNT_FIELDS} entries={discounts} />
        </ScrollContainer>
    );
};
