import styled, { useTheme } from 'styled-components';
import { Input } from './Input';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Label } from './Label';
import { TranslationInput } from './TranslationInput';
import { TranslationsHelper } from 'hooks/useTranslations';

type Option = {
    label: string;
    value: string;
};

export const OptionsArrayInput = ({
    label,
    values,
    setValues,
    buttonSize = 50,
    translationsHelper,
}: {
    label?: string;
    values: Option[];
    setValues: (values: Option[]) => void;
    buttonSize?: number;
    translationsHelper: TranslationsHelper;
}) => {
    const theme = useTheme();

    // todo deleteTranslation on element removal
    const { translationsHook } = translationsHelper;
    const { deleteTranslation } = translationsHook;

    return (
        <Container>
            {label ? <Label>{label}</Label> : null}
            {values.map((element, index) => (
                <Row key={values.length + index}>
                    <TranslationInput
                        value={element.label}
                        onChange={(newValue: string) => {
                            setValues(
                                values.map((prevValue, valueIndex) =>
                                    index === valueIndex ? { ...element, label: newValue } : prevValue
                                )
                            );
                        }}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${index}`,
                        }}
                    />
                    <Input
                        placeholder="Value"
                        value={element.value}
                        onChange={(newValue: string) => {
                            setValues(
                                values.map((prevValue, valueIndex) =>
                                    index === valueIndex ? { ...element, value: newValue } : prevValue
                                )
                            );
                        }}
                    />
                    <ButtonContainer
                        backgroundColor={theme.colors.backgroundError}
                        onClick={() => {
                            setValues(values.filter((_value, valueIndex) => valueIndex !== index));
                            deleteTranslation(element.label);
                        }}
                        buttonSize={buttonSize}
                    >
                        <FaTrash color="white" size={buttonSize / 2} />
                    </ButtonContainer>
                </Row>
            ))}
            <Row>
                <ButtonContainer
                    backgroundColor={theme.colors.backgroundSecondary}
                    onClick={() => setValues([...values, { label: '', value: '' }])}
                    buttonSize={buttonSize}
                >
                    <FaPlus color="white" size={buttonSize / 2} />
                </ButtonContainer>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const ButtonContainer = styled.div<{ backgroundColor: string; buttonSize: number }>`
    width: ${({ buttonSize }) => buttonSize}px;
    height: ${({ buttonSize }) => buttonSize}px;
    border-radius: ${({ buttonSize }) => buttonSize / 2}px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
