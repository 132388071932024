import { TableView, FullPageLoader, ScrollContainer } from 'components';
import { useGetCollectionsQuery } from 'data/generated';
import styled from 'styled-components';

export const Collections = () => {
    const { data, loading, error } = useGetCollectionsQuery();

    if (loading) {
        return <FullPageLoader />;
    }

    if (error || !data) {
        return <>{error?.message}</>;
    }

    const COLLECTION_FIELDS = [
        { name: '_id', label: 'ID' },
        { name: 'image', label: 'Image' },
        { name: 'name', label: 'Name' },
        { name: 'numberOfItems', label: 'Number of items' },
        { name: 'state', label: 'State' },
    ];

    const { collections } = data;

    const collectionEntries = collections.map(({ _id, image: { url }, name, numberOfItems, state }) => {
        return {
            _id,
            image: (
                <ImageContainer>
                    <img src={url} alt={name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </ImageContainer>
            ),
            name,
            numberOfItems,
            state,
        };
    });

    return (
        <ScrollContainer>
            <TableView fields={COLLECTION_FIELDS} entries={collectionEntries} path="/collection/" />
        </ScrollContainer>
    );
};

const ImageContainer = styled.div`
    height: 150px;
    aspect-ratio: 1;
`;
