import styled from 'styled-components';

import Logo from '../assets/logo.png';
import { ReactComponent as DeviceGamepad } from '../assets/icons/device-gamepad.svg';
import { ReactComponent as Discount2 } from '../assets/icons/discount-2.svg';
import { ReactComponent as Map } from '../assets/icons/map.svg';
import { ReactComponent as Tool } from '../assets/icons/tool.svg';
import { ReactComponent as Users } from '../assets/icons/users.svg';
import { ReactComponent as ZoomPan } from '../assets/icons/zoom-pan.svg';

import { CustomLink } from './CustomLink';
import Switch from 'react-switch';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { Colors } from 'styles/colors';
import { Input } from './Input';

export const NAVBAR_WIDTH = 180;

const PAGES = [
    {
        label: 'Scenarios',
        path: '/scenarios',
        icon: <Map width={35} height={35} />,
    },
    {
        label: 'Games',
        path: '/games',
        icon: <DeviceGamepad width={35} height={35} />,
    },
    {
        label: 'Collections',
        path: '/collections',
        icon: <ZoomPan width={35} height={35} />,
    },
    {
        label: 'Discounts',
        path: '/discounts',
        icon: <Discount2 width={35} height={35} />,
    },
    {
        label: 'Users',
        path: '/users',
        icon: <Users width={35} height={35} />,
    },
    {
        label: 'Tools',
        path: '/tools',
        icon: <Tool width={35} height={35} />,
    },
];

export const Navbar = () => {
    const {
        displayEnglish,
        displayFrench,
        displayKey,
        setDisplayEnglish,
        setDisplayFrench,
        setDisplayKey,
        useMarkdownEditor,
        setUseMarkdownEditor,
        keyPrefixCity,
        keyPrefixLocation,
        setKeyPrefixCity,
        setKeyPrefixLocation,
    } = useTranslationParameters();

    return (
        <SideBar>
            <Navigation>
                <ImageContainer>
                    <Image src={Logo} alt="Enigmo Logo" />
                </ImageContainer>

                {PAGES.map((pageInfo) => {
                    return (
                        <CustomLink key={pageInfo.label} to={pageInfo.path}>
                            <Entry>
                                {pageInfo.icon}
                                <PageLabel>{pageInfo.label}</PageLabel>
                            </Entry>
                        </CustomLink>
                    );
                })}
            </Navigation>
            <TranslationParameters>
                <Entry>
                    <Input
                        label="Trad key for city"
                        name="Trad key for city"
                        value={keyPrefixCity}
                        onChange={setKeyPrefixCity}
                    />
                </Entry>
                <Entry>
                    <Input
                        label="Trad key for location"
                        name="Trad key for location"
                        value={keyPrefixLocation}
                        onChange={setKeyPrefixLocation}
                    />
                </Entry>
                <Entry>
                    <Switch
                        onChange={() => setDisplayKey(!displayKey)}
                        checked={displayKey}
                        onColor={Colors.secondary.green}
                        offColor={Colors.secondary.lightGrey}
                    />
                    <PageLabel>Display Key</PageLabel>
                </Entry>
                <Entry>
                    <Switch
                        onChange={() => setDisplayFrench(!displayFrench)}
                        checked={displayFrench}
                        onColor={Colors.secondary.green}
                        offColor={Colors.secondary.lightGrey}
                    />
                    <PageLabel>Display FR</PageLabel>
                </Entry>
                <Entry>
                    <Switch
                        onChange={() => setDisplayEnglish(!displayEnglish)}
                        checked={displayEnglish}
                        onColor={Colors.secondary.green}
                        offColor={Colors.secondary.lightGrey}
                    />
                    <PageLabel>Display EN</PageLabel>
                </Entry>
                <Entry>
                    <Switch
                        onChange={() => setUseMarkdownEditor(!useMarkdownEditor)}
                        checked={useMarkdownEditor}
                        onColor={Colors.secondary.green}
                        offColor={Colors.secondary.lightGrey}
                    />
                    <PageLabel>Use markdown Editor</PageLabel>
                </Entry>
            </TranslationParameters>
        </SideBar>
    );
};

const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    height: 100%;
    width: ${NAVBAR_WIDTH}px;
    box-shadow: ${({ theme }) => theme.boxShadow};
    gap: 10px;
`;

const Navigation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
`;

const TranslationParameters = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
`;

const ImageContainer = styled.div`
    width: 100%;
    aspect-ratio: 1;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    overflow: hidden;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const Entry = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const PageLabel = styled.div`
    flex: 1;
`;
