import { ContentBlockInput, ContentBlockType } from 'data/generated';

export function createDefaultBlock(type: ContentBlockType): ContentBlockInput {
    switch (type) {
        case ContentBlockType.Equations:
            return { type: ContentBlockType.Equations, equations: [] };
        case ContentBlockType.Image:
            return {
                type: ContentBlockType.Image,
                imageKey: '',
            };
        case ContentBlockType.Paragraph:
            return { type: ContentBlockType.Paragraph, text: '' };
        case ContentBlockType.Subtitle:
            return { type: ContentBlockType.Subtitle, text: '' };
        case ContentBlockType.Title:
            return { type: ContentBlockType.Title, text: '' };
    }
}

export function isEquationsContentBlock(block: ContentBlockInput) {
    return block.type === ContentBlockType.Equations;
}

export function isParagraphContentBlock(block: ContentBlockInput) {
    return block.type === ContentBlockType.Paragraph;
}

export function isSubtitleContentBlock(block: ContentBlockInput) {
    return block.type === ContentBlockType.Subtitle;
}

export function isImageContentBlock(block: ContentBlockInput) {
    return block.type === ContentBlockType.Image;
}

export function isTitleContentBlock(block: ContentBlockInput) {
    return block.type === ContentBlockType.Title;
}
