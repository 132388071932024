import { TranslationsHelper } from 'hooks/useTranslations';
import styled from 'styled-components';

export const PreviewImageBlock = ({
    imageKey,
    translationsHelper,
}: {
    imageKey?: string | null;
    translationsHelper: TranslationsHelper;
}) => {
    const {
        translationsHook: { translateKey },
        imageTranslationsHook: { translateImageKey },
    } = translationsHelper;

    const usedImage = imageKey ? translateImageKey({ key: imageKey }) : null;

    if (!usedImage?.url) {
        return (
            <Container>
                <Caption>There is no image for this preview block, check if it's normal.</Caption>
            </Container>
        );
    }

    return (
        <Container>
            <Image src={usedImage?.url} />
            {usedImage?.caption ? (
                <Caption>
                    {translateKey({
                        key: usedImage?.caption,
                    })}
                </Caption>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Image = styled.img`
    height: 200px;
    object-fit: contain;
`;

const Caption = styled.div`
    font-size: 14px;
`;
