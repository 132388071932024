import { CollectionItemsMap } from 'routes/CollectionDetails/CollectionItemsMap';
import { CollectionFragment, useGetItemsOfCollectionQuery } from 'data/generated';
import styled from 'styled-components';
import { NewItemForm } from './NewItemForm';
import { useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useImageTranslations } from 'hooks/useImageTranslations';

export const CollectionItems = ({ collection }: { collection: CollectionFragment }) => {
    const { _id: collectionId, areaRadius } = collection;

    const { data } = useGetItemsOfCollectionQuery({ variables: { collectionId } });

    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: '',
        sectionId: collectionId,
        isGlobal: false,
    };

    const [newItemLocation, setNewItemLocation] = useState<{ lat: number; lng: number } | { lat: null; lng: null }>({
        lat: null,
        lng: null,
    });

    if (!data) {
        return null;
    }

    function onMapClick(e: google.maps.MapMouseEvent) {
        if (!e.latLng) {
            return;
        }
        setNewItemLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }

    const { itemsOfCollection } = data;

    return (
        <Container>
            <ItemsMap>
                <CollectionItemsMap
                    collectionItems={itemsOfCollection}
                    newItemLocation={newItemLocation}
                    areaRadius={areaRadius}
                    onClick={onMapClick}
                />
            </ItemsMap>
            <SidePanel>
                <div>
                    <Title>New item</Title>
                    <NewItemForm
                        newLocation={newItemLocation}
                        setNewItemLocation={setNewItemLocation}
                        collectionId={collectionId}
                        translationsHelper={translationsHelper}
                    />
                </div>
                <ItemsContainer>
                    <Title>Items' list</Title>
                    <ItemsList>
                        <Items>
                            {itemsOfCollection.map((collectionItem) => {
                                const {
                                    _id: collectionItemId,
                                    image: { url },
                                    name,
                                } = collectionItem;

                                return (
                                    <CollectionItemContainer key={collectionItemId}>
                                        <ImageContainer>
                                            <img
                                                src={url}
                                                alt={name}
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            />
                                        </ImageContainer>
                                        <ContentContainer>
                                            <ItemName>
                                                {translationsHook.translateKey({
                                                    key: name,
                                                })}
                                            </ItemName>
                                        </ContentContainer>
                                    </CollectionItemContainer>
                                );
                            })}
                        </Items>
                    </ItemsList>
                </ItemsContainer>
            </SidePanel>
        </Container>
    );
};

const Container = styled.div`
    flex: 1;
    display: flex;
    gap: 10px;
`;

const ItemsMap = styled.div`
    flex: 1;
    height: 100%;
`;

const SidePanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 100%;
    gap: 10px;
    overflow: hidden;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

const ItemsList = styled.div`
    overflow: hidden;
    flex: 1;
`;

const Items = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
    height: 100%;
`;

const CollectionItemContainer = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    background-color: white;
    flex-shrink: 0;
    overflow: hidden;
`;

const ImageContainer = styled.div`
    width: 150px;
    height: 150px;
`;

const ContentContainer = styled.div`
    flex: 1;
    padding: 10px;
`;

const ItemName = styled.span`
    font-size: 20px;
    font-weight: bold;
`;
