// import styled from 'styled-components';

import { SubmitBlockInput } from 'data/generated';

export const MusicSheetSubmitBlock = ({
    submitBlock,
    setSubmitBlock,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
}) => {
    // const { sequencesToGuess } = submitBlock;
    // const theme = useTheme();

    return <div>Needs implementation: nested array becomes object for some reason...</div>;

    // return (
    //     <Container>
    //         <Label>Sequences</Label>
    //         {sequencesToGuess.map((sequence, sequenceIndex) => (
    //             <Row key={sequencesToGuess.length + sequenceIndex}>
    //                 <SequenceContainer>
    //                     <Label>Sequence No{sequenceIndex + 1}</Label>
    //                     {sequence.map((element, elementIndex) => (
    //                         <Row key={sequence.length + elementIndex}>
    //                             <Input
    //                                 placeholder="Letter"
    //                                 value={element.letter}
    //                                 onChange={(newValue: string) =>
    //                                     setSubmitBlock({
    //                                         ...submitBlock,
    //                                         sequencesToGuess: sequencesToGuess.map((sequence, index) =>
    //                                             index !== sequenceIndex
    //                                                 ? sequence
    //                                                 : sequence.map((element, index) =>
    //                                                       index === elementIndex
    //                                                           ? { ...element, letter: newValue }
    //                                                           : element
    //                                                   )
    //                                         ),
    //                                     })
    //                                 }
    //                             />
    //                             <Input
    //                                 placeholder="Note index (0 = C4)"
    //                                 value={element.noteIndex}
    //                                 onChange={(newValue: string) =>
    //                                     setSubmitBlock({
    //                                         ...submitBlock,
    //                                         sequencesToGuess: sequencesToGuess.map((sequence, index) =>
    //                                             index !== sequenceIndex
    //                                                 ? sequence
    //                                                 : sequence.map((element, index) =>
    //                                                       index === elementIndex
    //                                                           ? { ...element, noteIndex: Number(newValue) }
    //                                                           : element
    //                                                   )
    //                                         ),
    //                                     })
    //                                 }
    //                             />
    //                             <ButtonContainer
    //                                 backgroundColor={theme.colors.backgroundError}
    //                                 onClick={() =>
    //                                     setSubmitBlock({
    //                                         ...submitBlock,
    //                                         sequencesToGuess: sequencesToGuess.map((sequence, index) =>
    //                                             index !== sequenceIndex
    //                                                 ? sequence
    //                                                 : sequence.filter((_, index) => index !== elementIndex)
    //                                         ),
    //                                     })
    //                                 }
    //                             >
    //                                 <FaTrash color="white" size={12} />
    //                             </ButtonContainer>
    //                         </Row>
    //                     ))}
    //                     <Row>
    //                         <ButtonContainer
    //                             backgroundColor={theme.colors.backgroundSecondary}
    //                             onClick={() =>
    //                                 setSubmitBlock({
    //                                     ...submitBlock,
    //                                     sequencesToGuess: sequencesToGuess.map((sequence, index) =>
    //                                         index !== sequenceIndex
    //                                             ? sequence
    //                                             : [...sequence, { letter: '', noteIndex: null }]
    //                                     ),
    //                                 })
    //                             }
    //                         >
    //                             <FaPlus color="white" size={12} />
    //                         </ButtonContainer>
    //                     </Row>
    //                 </SequenceContainer>
    //                 <ButtonContainer
    //                     backgroundColor={theme.colors.backgroundError}
    //                     onClick={() =>
    //                         setSubmitBlock({
    //                             ...submitBlock,
    //                             sequencesToGuess: sequencesToGuess.filter(
    //                                 (_, valueIndex) => valueIndex !== sequenceIndex
    //                             ),
    //                         })
    //                     }
    //                 >
    //                     <FaTrash color="white" size={12} />
    //                 </ButtonContainer>
    //             </Row>
    //         ))}
    //         <Row>
    //             <ButtonContainer
    //                 backgroundColor={theme.colors.backgroundSecondary}
    //                 onClick={() =>
    //                     setSubmitBlock({
    //                         ...submitBlock,
    //                         sequencesToGuess: [...sequencesToGuess, [{ letter: '', noteIndex: null }]],
    //                     })
    //                 }
    //             >
    //                 <FaPlus color="white" size={12} />
    //             </ButtonContainer>
    //         </Row>
    //     </Container>
    // );
};

// const Container = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 5px;
// `;

// const Row = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     gap: 5px;
// `;

// const ButtonContainer = styled.div<{ backgroundColor: string }>`
//     width: 25px;
//     height: 25px;
//     border-radius: 13px;
//     flex-shrink: 0;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: ${({ backgroundColor }) => backgroundColor};
// `;

// const SequenceContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 5px;
//     padding: 10px;
//     border-radius: 5px;
//     border: 1px solid lightgrey;
// `;
