import { gql } from '@apollo/client';
import { BACKPACK_ITEM_FRAGMENT } from 'data/fragments/backpackItem';

export const GET_BACKPACK_ITEMS_OF_SCENARIO_QUERY = gql`
    query GetBackpackItemsOfScenario($scenarioId: ID!) {
        backpackItemsOfScenario(scenarioId: $scenarioId) {
            ...BackpackItem
        }
    }
    ${BACKPACK_ITEM_FRAGMENT}
`;
