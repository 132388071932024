// @ts-nocheck

export function removeFieldFromObject<T extends Object>(object: T, fieldName: string): T {
    const fields = Object.keys(object);

    for (const field of fields) {
        if (typeof object[field] === 'object' && object[field] !== null) {
            if (Array.isArray(object[field])) {
                object[field] = object[field].map((elem) => {
                    if (typeof elem === 'object') {
                        return removeFieldFromObject({ ...elem }, fieldName);
                    }
                    return elem;
                });
            } else {
                object[field] = removeFieldFromObject({ ...object[field] }, fieldName);
            }
        }
    }

    delete object[fieldName];

    return object;
}
