import { Button, Modal, Selector, Title } from 'components';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { SelectorOption, isSelectorOptionSingleValue } from 'components/Selector';
import { TranslationInput } from 'components/TranslationInput';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { BackpackItemInput, useCreateBackpackItemMutation } from 'data/generated';
import { useBackpackItemHandlers } from 'hooks/useBackpackItemHandlers';
import { useImageTranslations } from 'hooks/useImageTranslations';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';

export const NewBackpackItemModal = ({
    scenarioId,
    scenarioLocationOptions,
    onClose,
}: {
    scenarioId: string;
    scenarioLocationOptions: SelectorOption<string>[];
    onClose: () => void;
}) => {
    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.backpack.${keyPrefixLocation}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [newBackpackItem, setNewBackpackItem] = useState<BackpackItemInput>({
        title: '',
        description: '',
        imageKey: '',
        blockingScenarioLocationId: null,
    });

    const [createBackpackItemMutation] = useCreateBackpackItemMutation();
    const { backpackItemAddedHandler } = useBackpackItemHandlers();

    async function submitBackpackItem() {
        const { data } = await createBackpackItemMutation({
            variables: { scenarioId, backpackItemInput: newBackpackItem },
        });

        if (data) {
            const {
                createBackpackItem: { backpackItem },
            } = data;

            if (backpackItem) {
                backpackItemAddedHandler(backpackItem);
                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
            }
        }
    }

    return (
        <Modal onClose={onClose}>
            <Container>
                <Header>
                    <Title>New backpack item</Title>
                    <Button onClick={submitBackpackItem}>
                        <FaSave />
                    </Button>
                </Header>
                <TranslationInput
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                    }}
                    label="Title"
                    value={newBackpackItem.title}
                    onChange={(value) => setNewBackpackItem({ ...newBackpackItem, title: value })}
                />
                <TranslationInput
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.description`,
                    }}
                    label="Description"
                    value={newBackpackItem.description}
                    onChange={(value) => setNewBackpackItem({ ...newBackpackItem, description: value })}
                />
                <ImageTranslationInput
                    label="Image"
                    onChange={(value) => setNewBackpackItem({ ...newBackpackItem, imageKey: value })}
                    value={newBackpackItem.imageKey}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                    }}
                />
                <Selector<string>
                    label="Blocking Location"
                    options={scenarioLocationOptions}
                    value={newBackpackItem.blockingScenarioLocationId ?? undefined}
                    onChange={(option) =>
                        isSelectorOptionSingleValue(option)
                            ? setNewBackpackItem({
                                  ...newBackpackItem,
                                  blockingScenarioLocationId: option?.value ?? null,
                              })
                            : undefined
                    }
                />
            </Container>
        </Modal>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
