import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaSave, FaTrash } from 'react-icons/fa';

import {
    useArchiveScenarioLocationMutation,
    useGetScenarioLocationDetailsQuery,
    useUpdateScenarioLocationMutation,
} from 'data/generated';

import { Button, Card, FullPageLoader, Header, ScrollContainer, Title } from 'components';
import { useParams } from 'react-router-dom';
import { Puzzles } from './Puzzles';
import { ScenarioLocationForm, ScenarioLocationInput } from './ScenarioLocationForm';
import { useTranslations } from 'hooks/useTranslations';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { Colors } from 'styles/colors';
import { useImageTranslations } from 'hooks/useImageTranslations';

export const ScenarioLocationDetails = () => {
    const { scenarioId, scenarioLocationId } = useParams() as {
        scenarioId: string;
        scenarioLocationId: string;
    };

    const [scenarioLocationForm, setScenarioLocationForm] = useState<ScenarioLocationInput | null>(null);

    const {
        data: scenarioLocationData,
        loading,
        error,
    } = useGetScenarioLocationDetailsQuery({
        variables: {
            scenarioLocationId,
        },
    });

    const [updateScenarioLocationMutation, { loading: updateLoading }] = useUpdateScenarioLocationMutation();
    const [archiveScenarioLocationMutation, { loading: archiveLoading }] = useArchiveScenarioLocationMutation();

    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.location.${keyPrefixLocation}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    useEffect(() => {
        if (scenarioLocationData) {
            const { scenarioLocation } = scenarioLocationData;
            const { blockingScenarioLocationIds, funFacts, imageKey, location, title, visitedLandmark } =
                scenarioLocation;

            setScenarioLocationForm({
                blockingScenarioLocationIds,
                funFacts: funFacts.map(({ title, text, imageKey }) => ({
                    title,
                    text,
                    imageKey,
                })),
                imageKey,
                location: { lat: location.latitude, lng: location.longitude },
                title,
                visitedLandmark: visitedLandmark ?? null,
            });
        }
    }, [scenarioLocationData, setScenarioLocationForm]);

    if (error) {
        return <>{error?.message || 'Error fetching scenario'}</>;
    }

    if (loading || !scenarioLocationData || !scenarioLocationForm) {
        return <FullPageLoader />;
    }

    const {
        scenarioLocation: { state, sameScenarioLocations },
    } = scenarioLocationData;

    const scenarioLocationOptions = sameScenarioLocations.map(({ _id, title }) => ({ value: _id, label: title }));

    async function onSubmit() {
        if (!scenarioLocationForm || !scenarioLocationForm.location) {
            return;
        }

        const {
            blockingScenarioLocationIds,
            funFacts,
            imageKey,
            location: { lat: latitude, lng: longitude },
            title,
            visitedLandmark,
        } = scenarioLocationForm;

        const { data } = await updateScenarioLocationMutation({
            variables: {
                scenarioLocationId,
                updateInput: {
                    blockingScenarioLocationIds,
                    funFacts,
                    imageKey,
                    location: { latitude, longitude },
                    title,
                    visitedLandmark,
                },
            },
        });

        if (data) {
            const {
                updateScenarioLocation: { scenarioLocation, errors },
            } = data;

            if (errors) {
                errors.map((error) => toast.error(error, { autoClose: 3000 }));
            }
            if (scenarioLocation) {
                toast.success('Scenario location updated!', { autoClose: 3000 });

                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
            }
        }
    }

    async function archiveScenarioLocation() {
        if (window.confirm('Do you want to archive the scenario location ?')) {
            await archiveScenarioLocationMutation({ variables: { scenarioLocationId } });
        }
    }

    return (
        <>
            <Header title={`Scenario location (${state})`}>
                <ButtonsContainer>
                    <Button
                        onClick={archiveScenarioLocation}
                        backgroundColor={Colors.secondary.red}
                        disabled={archiveLoading}
                    >
                        <FaTrash />
                    </Button>
                    <Button onClick={onSubmit} disabled={updateLoading}>
                        <FaSave />
                    </Button>
                </ButtonsContainer>
            </Header>
            <ScrollContainer>
                <Card>
                    <Title>Scenario Location Details</Title>
                    <ScenarioLocationContainer>
                        <ScenarioLocationForm
                            scenarioLocationOptions={scenarioLocationOptions}
                            scenarioLocationForm={scenarioLocationForm}
                            setScenarioLocationForm={setScenarioLocationForm}
                            translationsHelper={translationsHelper}
                        />
                    </ScenarioLocationContainer>
                </Card>
                <Card>
                    <Title>Puzzles</Title>
                    <Puzzles scenarioLocationId={scenarioLocationId} scenarioId={scenarioId} />
                </Card>
            </ScrollContainer>
        </>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    gap: 15px;
`;

const ScenarioLocationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    overflow: hidden;
`;
