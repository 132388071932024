import { useApolloClient } from '@apollo/client';
import { GetScenarioLocationPuzzlesQuery, PuzzleFragment } from 'data/generated';
import { GET_SCENARIO_LOCATION_PUZZLES_QUERY } from 'data/queries/puzzle';

export const usePuzzleHandlers = () => {
    const apolloClient = useApolloClient();

    function puzzleAddedHandler({
        puzzle,
        scenarioLocationId,
    }: {
        puzzle: PuzzleFragment;
        scenarioLocationId: string;
    }) {
        const data = apolloClient.readQuery<GetScenarioLocationPuzzlesQuery>({
            query: GET_SCENARIO_LOCATION_PUZZLES_QUERY,
            variables: { scenarioLocationId },
        });

        if (!data) {
            return;
        }

        const { scenarioLocationPuzzles } = data;

        apolloClient.writeQuery<GetScenarioLocationPuzzlesQuery>({
            query: GET_SCENARIO_LOCATION_PUZZLES_QUERY,
            variables: { scenarioLocationId },
            data: { scenarioLocationPuzzles: [...scenarioLocationPuzzles, puzzle] },
        });
    }

    return { puzzleAddedHandler };
};
