import { Label } from 'components/Label';
import styled from 'styled-components';

export const InfoField = ({
    label,
    value,
    isInline = false,
}: {
    label?: string;
    value?: string | number | null;
    isInline?: boolean;
}) => {
    return (
        <Container isInline={isInline}>
            {label && <Label>{label}</Label>}
            <Info>{value}</Info>
        </Container>
    );
};

const Container = styled.div<{ isInline: boolean }>`
    display: flex;
    flex-direction: ${({ isInline }) => (isInline ? 'row' : 'column')};
    gap: 5px;
`;

const Info = styled.div`
    display: flex;
    flex: 1;
`;
