import { useApolloClient } from '@apollo/client';
import { GameFragment, GetGamesQuery } from 'data/generated';
import { GET_GAMES_QUERY } from 'data/queries/game';

export const useGameHandlers = () => {
    const apolloClient = useApolloClient();

    function gameAddedHandler({ game }: { game: GameFragment }) {
        const data = apolloClient.readQuery<GetGamesQuery>({
            query: GET_GAMES_QUERY,
        });

        if (!data) {
            return;
        }

        const { games } = data;

        apolloClient.writeQuery<GetGamesQuery>({
            query: GET_GAMES_QUERY,
            data: { games: [...games, game] },
        });
    }

    return { gameAddedHandler };
};
