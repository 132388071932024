import { Button, Input, Label } from 'components';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { ImageUpload } from 'components/ImageUpload';
import { TranslationInput } from 'components/TranslationInput';
import { CreateCollectionItemInput, useCreateCollectionItemMutation } from 'data/generated';
import { useCollectionItemHandlers } from 'hooks/useCollectionItemHandlers';
import { TranslationsHelper } from 'hooks/useTranslations';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

export const NewItemForm = ({
    newLocation,
    setNewItemLocation,
    collectionId,
    translationsHelper,
}: {
    newLocation: { lat: number; lng: number } | { lat: null; lng: null };
    setNewItemLocation: (props: { lat: number; lng: number } | { lat: null; lng: null }) => void;
    collectionId: string;
    translationsHelper: TranslationsHelper;
}) => {
    const initialFormValue: CreateCollectionItemInput = {
        name: '',
        imageInput: { aspectRatio: 1, url: null, thumbhash: '', file: null, type: null },
        imageKey: '',
        location: { latitude: 0, longitude: 0 },
    };

    const [collectionItemForm, setCollectionItemForm] = useState<CreateCollectionItemInput>(initialFormValue);

    const [createCollectionItemMutation] = useCreateCollectionItemMutation();

    const { collectionItemAddedHandler } = useCollectionItemHandlers();

    useEffect(() => {
        setCollectionItemForm((collectionItemForm) => ({
            ...collectionItemForm,
            location: { latitude: newLocation?.lat ?? 0, longitude: newLocation?.lng ?? 0 },
        }));
    }, [newLocation]);

    async function onSubmit() {
        if (!newLocation.lat || !newLocation.lng) {
            return;
        }

        const { data } = await createCollectionItemMutation({
            variables: {
                collectionId,
                collectionItemInput: {
                    location: collectionItemForm.location,
                    name: collectionItemForm.name,
                    imageInput: collectionItemForm.imageInput,
                    imageKey: collectionItemForm.imageKey,
                },
            },
        });

        if (data?.createCollectionItem) {
            const { collectionItem, errors } = data.createCollectionItem;

            if (errors) {
                errors.forEach((error) => toast.error(error));
            } else if (collectionItem) {
                setNewItemLocation({ lat: null, lng: null });
                setCollectionItemForm(initialFormValue);
                collectionItemAddedHandler({ collectionId, collectionItem });
                await Promise.all([
                    translationsHelper.translationsHook.saveTranslations(),
                    translationsHelper.imageTranslationsHook.saveImageTranslations(),
                ]);
            }
        }
    }

    return (
        <Container>
            <Label>Location</Label>
            <span>You can click on the map to select a new location</span>
            <Row>
                <Input
                    label="Latitude"
                    value={newLocation.lat ?? 0}
                    onChange={(value) => setNewItemLocation({ lng: newLocation.lng ?? 0, lat: Number(value) })}
                />
                <Input
                    label="Longitude"
                    value={newLocation.lng ?? 0}
                    onChange={(value) => setNewItemLocation({ lat: newLocation.lat ?? 0, lng: Number(value) })}
                />
            </Row>
            <TranslationInput
                label="Name"
                value={collectionItemForm.name}
                onChange={(value) => setCollectionItemForm({ ...collectionItemForm, name: value })}
                translationsHelper={translationsHelper}
            />
            <ImageUpload
                label="Image"
                image={collectionItemForm.imageInput}
                setImage={(value) => setCollectionItemForm({ ...collectionItemForm, imageInput: value })}
                isCaptionDisabled
                hasVerticalDisplay
                isSmall
                translationsHelper={translationsHelper}
            />
            <ImageTranslationInput
                label="Image"
                onChange={(value) => setCollectionItemForm({ ...collectionItemForm, imageKey: value })}
                value={collectionItemForm.imageKey}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                }}
                previousImplementationImage={collectionItemForm.imageInput}
            />
            <Button onClick={onSubmit}>Create collection item</Button>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    background-color: white;
    padding: 10px;
    gap: 10px;
`;

const Row = styled.div`
    display: flex;
    gap: 5px;
`;
