import { gql } from '@apollo/client';
import { IMAGE_TRANSLATION_FRAGMENT } from 'data/fragments/imageTranslation';

export const GET_IMAGE_TRANSLATIONS_QUERY = gql`
    query GetImageTranslations {
        imageTranslations {
            ...ImageTranslation
        }
    }
    ${IMAGE_TRANSLATION_FRAGMENT}
`;
