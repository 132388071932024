import { Button, Input } from 'components';
import { useAuth } from 'contexts/AuthContext';
import { useLoginAdminMutation } from 'data/generated';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setUser } = useAuth();

    const [loginAdminMutation] = useLoginAdminMutation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const from = location.state?.from?.pathname || '/';

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const email = formData.get('email') as string;
        const password = formData.get('password') as string;

        const { data } = await loginAdminMutation({ variables: { email, password } });

        if (!data) {
            return;
        }

        const { loginAdmin } = data;

        const { __typename } = loginAdmin;
        if (__typename === 'SuccessAuthenticationInfo') {
            const { token, admin } = loginAdmin;

            if (!admin || !token) {
                return;
            }

            setUser(admin);
            window.localStorage.setItem('authentication-token', token);

            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page
            navigate(from, { replace: true });
        }
    }

    function updateEmail(value: string) {
        setEmail(value);
    }

    function updatePassword(value: string) {
        setPassword(value);
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Input label="Adresse mail" name="email" value={email} onChange={updateEmail} />
                <Input
                    label="Mot de passe"
                    name="password"
                    value={password}
                    onChange={updatePassword}
                    type="password"
                />
                <Button type="submit">Se connecter</Button>
            </Form>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
`;
