import { Card, FullPageLoader, Title } from 'components';
import { useGetBackpackItemsOfScenarioQuery } from 'data/generated';
import styled from 'styled-components';
import { BackpackItemForm } from './BackpackItemForm';
import { NewBackpackItemModal } from './NewBackpackItemModal';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { SelectorOption } from 'components/Selector';

export const BackpackItems = ({
    scenarioId,
    scenarioLocationOptions,
}: {
    scenarioId: string;
    scenarioLocationOptions: SelectorOption<string>[];
}) => {
    const [isBackpackItemModalOpen, setIsBackpackItemModalOpen] = useState(false);

    const { data, loading, error } = useGetBackpackItemsOfScenarioQuery({
        variables: {
            scenarioId,
        },
    });

    if (loading) {
        return <FullPageLoader />;
    }

    if (error || !data) {
        return <>{error?.message || 'Error fetching scenario'}</>;
    }

    const { backpackItemsOfScenario: backpackItems } = data;

    return (
        <Card style={{ marginTop: 10, gap: 10 }}>
            <Title>Scenario Backpack Items</Title>
            {backpackItems.map((backpackItem, index) => {
                const { _id: backpackItemId } = backpackItem;

                return (
                    <PreviewContainer key={backpackItemId}>
                        <BackpackItemForm
                            scenarioId={scenarioId}
                            backpackItem={backpackItem}
                            index={index}
                            scenarioLocationOptions={scenarioLocationOptions}
                        />
                    </PreviewContainer>
                );
            })}

            <BackpackItemAddContainer onClick={() => setIsBackpackItemModalOpen(true)}>
                <FaPlus size={40} />
            </BackpackItemAddContainer>

            {isBackpackItemModalOpen ? (
                <NewBackpackItemModal
                    scenarioId={scenarioId}
                    scenarioLocationOptions={scenarioLocationOptions}
                    onClose={() => setIsBackpackItemModalOpen(false)}
                />
            ) : null}
        </Card>
    );
};

const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const BackpackItemAddContainer = styled.button`
    height: 300px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
`;
