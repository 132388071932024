import styled from 'styled-components';
import { MdDelete, MdAdd, MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { ContentBlockInput } from 'data/generated';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { Input } from 'components/Input';
import { TranslationsHelper } from 'hooks/useTranslations';

export const EditableEquationsBlock = ({
    block,
    setBlock,
    moveDownBlock,
    moveUpBlock,
    deleteBlock,
    translationsHelper,
}: {
    block: ContentBlockInput;
    setBlock: (block: ContentBlockInput) => void;
    moveDownBlock: () => void;
    moveUpBlock: () => void;
    deleteBlock: () => void;
    translationsHelper: TranslationsHelper;
}) => {
    const updateEquation = (value: string, incomingIndex: number) => {
        const updatedEquations = (block.equations ?? []).map((equation, index) => {
            if (incomingIndex === index) {
                return value;
            }
            return equation;
        });
        setBlock({ ...block, equations: updatedEquations });
    };

    const deleteEquation = (incomingIndex: number) => {
        const updatedEquations = (block.equations ?? []).filter((_, index) => index !== incomingIndex);
        setBlock({ ...block, equations: updatedEquations });
    };

    return (
        <Container>
            <ActionsContainer>
                <Title>Equations</Title>
                <IconButton onClick={moveUpBlock}>
                    <MdArrowUpward size={15} />
                </IconButton>
                <IconButton onClick={moveDownBlock}>
                    <MdArrowDownward size={15} />
                </IconButton>
                <IconButton onClick={deleteBlock}>
                    <MdDelete size={15} />
                </IconButton>
            </ActionsContainer>
            <CenterContainer>
                {block.equations?.map((equation, index) => {
                    return (
                        <EquationContainer key={index}>
                            <Input value={equation} onChange={(value) => updateEquation(value, index)} />
                            <IconButton onClick={() => deleteEquation(index)}>
                                <MdDelete size={15} />
                            </IconButton>
                        </EquationContainer>
                    );
                })}
                <IconButton onClick={() => setBlock({ ...block, equations: [...(block.equations ?? []), ''] })}>
                    <MdAdd size={15} />
                </IconButton>
            </CenterContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(Label)`
    flex-grow: 1;
`;

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 0;
    margin-left: 10px;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
`;

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
`;

const EquationContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
`;
