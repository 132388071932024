import { Button, Input, Selector } from 'components';
import { isSelectorOptionSingleValue } from 'components/Selector';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { Language, ScenarioState, useCreateGameMutation, useGetScenariosQuery } from 'data/generated';
import { useGameHandlers } from 'hooks/useGameHandlers';
import { useState } from 'react';
import styled from 'styled-components';

export const GameCreationModal = ({ closeModal }: { closeModal: () => void }) => {
    const { displayFrench } = useTranslationParameters();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [scenarioId, setScenarioId] = useState('');

    const [createGameMutation] = useCreateGameMutation();

    const { gameAddedHandler } = useGameHandlers();

    const { data } = useGetScenariosQuery();

    if (!data) {
        return (
            <Container>
                <ContentContainer>
                    <span>Loading...</span>
                    <Button onClick={closeModal}>Fermer</Button>
                </ContentContainer>
            </Container>
        );
    }

    const { scenarios } = data;

    const scenarioOptions = scenarios
        .filter(({ state }) => state !== ScenarioState.Archived)
        .map(({ _id, title }) => ({ value: _id, label: title }));

    async function onSubmit() {
        const { data } = await createGameMutation({
            variables: {
                firstName,
                lastName,
                language: displayFrench ? Language.French : Language.English,
                email,
                scenarioId,
            },
        });

        if (data) {
            const {
                createGame: { game },
            } = data;

            if (game) {
                gameAddedHandler({ game });
                closeModal();
            }
        }
    }

    return (
        <Container onClick={closeModal}>
            <ContentContainer
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Input label="First name" value={firstName} onChange={setFirstName} />
                <Input label="Last name" value={lastName} onChange={setLastName} />
                <Input label="E-mail address" value={email} onChange={setEmail} />
                <Selector
                    label="Scenario"
                    options={scenarioOptions}
                    onChange={(option) =>
                        isSelectorOptionSingleValue(option) && option !== null ? setScenarioId(option.value) : undefined
                    }
                />
                <Button onClick={onSubmit}>Valider</Button>
                <Button onClick={closeModal}>Fermer</Button>
            </ContentContainer>
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: white;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    min-width: 400px;
`;
