import { Button, Header, ScrollContainer, TableView, Title } from 'components';
import { useGetGamesQuery } from 'data/generated';
import styled from 'styled-components';
import { GameStateTag } from './GameStateTag';
import { displayPrice } from 'utils/price';
import { FaPlus } from 'react-icons/fa';
import { useState } from 'react';
import { GameCreationModal } from './GameCreationModal';
import { getFormattedDateAndTime } from 'utils/time-formatting';
import { useTranslations } from 'hooks/useTranslations';
import { useImageTranslations } from 'hooks/useImageTranslations';

export const Games = () => {
    const { translateKey } = useTranslations();
    const { translateImageKey } = useImageTranslations();

    const { data: gamesData } = useGetGamesQuery();

    const [isGameCreationModalOpen, setIsGameCreationModalOpen] = useState(false);

    if (!gamesData) {
        return null;
    }

    const { games } = gamesData;

    const GAME_FIELDS = [
        { name: '_id', label: 'ID' },
        { name: 'scenarioDetails', label: 'Scénario' },
        { name: 'state', label: 'State' },
    ];

    const gameEntries = [...games]
        .sort((gameA, gameB) => gameB.createdAt - gameA.createdAt)
        .map(
            ({
                _id,
                createdAt,
                isInDemoMode,
                joinCode,
                scenario: { imageKey, title },
                state,
                transaction,
                user: { email, firstName, lastName },
            }) => {
                const { url } = translateImageKey({ key: imageKey });

                return {
                    _id,
                    scenarioDetails: (
                        <ScenarioDetails>
                            <ImageContainer>
                                <img
                                    src={url ?? undefined}
                                    alt={title}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </ImageContainer>
                            <ScenarioInfo>
                                <Title>
                                    {translateKey({
                                        key: title,
                                    })}
                                </Title>
                                <Row>
                                    <b>Buyer</b>
                                    <Value>
                                        {firstName} {lastName} ({email})
                                    </Value>
                                </Row>
                                <Row>
                                    <b>Price details</b>
                                    {transaction ? (
                                        <Value>
                                            {displayPrice(transaction.priceDetails.paid)} paid +{' '}
                                            {displayPrice(transaction.priceDetails.discount)} discount ={' '}
                                            {displayPrice(transaction.priceDetails.discount)} total (
                                            {transaction.origin})
                                        </Value>
                                    ) : (
                                        <Value>None</Value>
                                    )}
                                </Row>
                                <Row>
                                    Created on {getFormattedDateAndTime(new Date(createdAt), { showWeekday: true })}
                                </Row>
                                <Row>
                                    <b>Join code</b>
                                    <Value>{joinCode.match(new RegExp('.{1,4}', 'g'))?.join('-')}</Value>
                                </Row>
                                <Row>
                                    <b>Demo mode ?</b>
                                    <Value>{isInDemoMode ? 'Yes' : 'No'}</Value>
                                </Row>
                            </ScenarioInfo>
                        </ScenarioDetails>
                    ),
                    state: <GameStateTag state={state} />,
                };
            }
        );

    function openGameCreationModal() {
        setIsGameCreationModalOpen(true);
    }

    function closeGameCreationModal() {
        setIsGameCreationModalOpen(false);
    }

    return (
        <>
            <Header title="Games">
                <Button onClick={openGameCreationModal}>
                    <FaPlus />
                </Button>
            </Header>
            <ScrollContainer>
                <TableView fields={GAME_FIELDS} entries={gameEntries} path="/game/" />
            </ScrollContainer>
            {isGameCreationModalOpen ? <GameCreationModal closeModal={closeGameCreationModal} /> : null}
        </>
    );
};

const ScenarioDetails = styled.div`
    display: flex;
    gap: 10px;
`;

const ImageContainer = styled.div`
    height: 100px;
    aspect-ratio: 2;
    border-radius: 10px;
    overflow: hidden;
`;

const ScenarioInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    gap: 10px;
`;

const Value = styled.span`
    color: grey;
`;
