import { Selector } from 'components';
import { createDefaultBlock } from 'components/EditableContentBlocks/utils';
import { ContentBlockInput, ContentBlockType } from 'data/generated';
import { EditableBlock } from './EditableBlock';
import { isSelectorOptionSingleValue } from 'components/Selector';
import { TranslationsHelper } from 'hooks/useTranslations';

export const EditableBlocks = ({
    blocks,
    setBlocks,
    translationsHelper,
}: {
    blocks: ContentBlockInput[];
    setBlocks: (blocks: ContentBlockInput[]) => void;
    translationsHelper: TranslationsHelper;
}) => {
    function setBlock({ block, index }: { block: ContentBlockInput; index: number }) {
        setBlocks(
            blocks.map((prevBlock, blockIndex) => {
                if (blockIndex === index) {
                    return block;
                }
                return prevBlock;
            })
        );
    }

    function moveUpBlock(index: number) {
        if (index === 0) {
            return;
        }
        const newBlocks = [...blocks];
        newBlocks[index - 1] = blocks[index];
        newBlocks[index] = blocks[index - 1];
        setBlocks(newBlocks);
    }

    function moveDownBlock(index: number) {
        if (index === blocks.length - 1) {
            return;
        }
        const newBlocks = [...blocks];
        newBlocks[index + 1] = blocks[index];
        newBlocks[index] = blocks[index + 1];
        setBlocks(newBlocks);
    }

    function deleteBlock(index: number) {
        setBlocks(blocks.filter((_, blockIndex) => blockIndex !== index));
    }

    function addBlock(type: ContentBlockType) {
        const newBlock = createDefaultBlock(type);
        setBlocks([...blocks, newBlock]);
    }

    const contentBlockOptions = Object.keys(ContentBlockType).map((key) => ({
        value: key as ContentBlockType,
        label: key,
    }));

    return (
        <>
            {blocks.map((block, index) => (
                <EditableBlock
                    key={index}
                    block={block}
                    setBlock={(block) => setBlock({ block, index })}
                    moveUpBlock={() => moveUpBlock(index)}
                    moveDownBlock={() => moveDownBlock(index)}
                    deleteBlock={() => deleteBlock(index)}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${index}`,
                    }}
                />
            ))}
            <Selector<ContentBlockType>
                label="Add new block"
                options={contentBlockOptions}
                onChange={(input) =>
                    isSelectorOptionSingleValue(input) && input !== null ? addBlock(input.value) : undefined
                }
            />
        </>
    );
};
