import { Label } from 'components';
import { useTranslations } from 'hooks/useTranslations';
import styled from 'styled-components';

type Dictionnary = { [key: string]: Object | Dictionnary };

function addObjectAtPathToDictionnary(path: string[], dictionnary: Dictionnary, object: Object) {
    const [firstElem, ...other] = path;
    if (!other.length) {
        dictionnary[firstElem] = object;
        return;
    }

    if (!dictionnary[firstElem]) {
        dictionnary[firstElem] = {};
    }
    addObjectAtPathToDictionnary(other, dictionnary[firstElem] as Dictionnary, object);
}

export const TranslationViewer = () => {
    const { translations } = useTranslations();

    const dictionnary = {};
    for (const translation of translations) {
        const { __typename, _id, ...others } = translation;
        addObjectAtPathToDictionnary(translation.key.split('.'), dictionnary, { ...others });
    }

    function displayDictionnary(dictionnary: Object) {
        const dictionnaryKeys = Object.keys(dictionnary) as (keyof typeof dictionnary)[];
        return dictionnaryKeys.sort().map((key) => {
            if (typeof dictionnary[key] === 'object') {
                return (
                    <Details key={key}>
                        <Summary>{key}</Summary>
                        {displayDictionnary(dictionnary[key])}
                    </Details>
                );
            }
            return (
                <Text key={key}>
                    <Key>{key} :</Key> {dictionnary[key].toString()}
                </Text>
            );
        });
    }

    return (
        <Container>
            <Label>Translations</Label>
            {displayDictionnary(dictionnary)}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Text = styled.div`
    padding-left: 20px;
    margin-top: 5px;
`;

const Key = styled.span`
    font-weight: bold;
`;

const Details = styled.details`
    padding-left: 20px;
    margin-top: 5px;
`;

const Summary = styled.summary`
    font-style: italic;
`;
