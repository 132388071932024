import { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import {
    PuzzleTemplateInput,
    PuzzleType,
    SubmitBlockInput,
    SubmitBlockType,
    useCreatePuzzleMutation,
} from 'data/generated';
import { usePuzzleHandlers } from 'hooks/usePuzzleHandlers';

import { Button, Modal, Selector } from 'components';
import { PuzzleInfo } from 'routes/ScenarioLocations/Puzzles/PuzzleInfo';
import { TemplateSection } from 'routes/ScenarioLocations/Puzzles/TemplateSection';
import { isSelectorOptionSingleValue } from 'components/Selector';
import { getInitialTemplateForType } from 'routes/ScenarioLocations/Puzzles/utils';
import { useTranslations } from 'hooks/useTranslations';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { useImageTranslations } from 'hooks/useImageTranslations';

export type PuzzleInput = {
    blockingPuzzleIds: string[];
    expectedAnswer: string | null;
    hints: string[];
    scoreTiers: string[]; // incorrect type, will update later, currently sending empty array
    submitBlock: SubmitBlockInput;
    template: PuzzleTemplateInput;
    title: string;
};
export const CreatePuzzleModal = ({
    scenarioLocationId,
    scenarioLocationPuzzleOptions,
    onClose,
    newPuzzleIndex,
    scenarioId,
}: {
    scenarioLocationId: string;
    scenarioLocationPuzzleOptions: { value: string; label: string }[];
    onClose: () => void;
    newPuzzleIndex: number;
    scenarioId: string;
}) => {
    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.location.${keyPrefixLocation}.puzzles.${newPuzzleIndex}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [form, setForm] = useState<PuzzleInput>({
        blockingPuzzleIds: [],
        expectedAnswer: null,
        hints: [],
        scoreTiers: [],
        title: '',
        submitBlock: { type: SubmitBlockType.Button, label: '' },
        template: { type: PuzzleType.Content, blocks: [] },
    });

    const [createPuzzleMutation] = useCreatePuzzleMutation();
    const { puzzleAddedHandler } = usePuzzleHandlers();

    async function onSubmit() {
        const { data } = await createPuzzleMutation({
            variables: {
                puzzleInput: { ...form, scenarioLocationId },
            },
        });
        if (data) {
            const {
                createPuzzle: { puzzle, errors },
            } = data;

            if (errors) {
                errors.map((error) => toast.error(error, { autoClose: 5000 }));
            }

            if (puzzle) {
                puzzleAddedHandler({ puzzle, scenarioLocationId });
                toast.success('Puzzle has been created', { autoClose: 3000 });

                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);

                onClose();
            }
        }
    }

    const templateOptions = Object.keys(PuzzleType).map((key) => ({
        value: key as PuzzleType,
        label: key,
    }));

    return (
        <Modal onClose={onClose} minHeight={500}>
            <Content>
                <PuzzleInfo
                    puzzleForm={form}
                    setPuzzleForm={setForm}
                    scenarioLocationPuzzleOptions={scenarioLocationPuzzleOptions}
                    translationsHelper={translationsHelper}
                />
                <Selector
                    label="Puzzle type"
                    options={templateOptions}
                    value={form.template.type}
                    onChange={(option) =>
                        isSelectorOptionSingleValue(option) && option !== null
                            ? setForm({ ...form, template: getInitialTemplateForType(option.value) })
                            : undefined
                    }
                />
                {form.template ? (
                    <TemplateSection
                        template={form.template}
                        setTemplate={(template) => setForm({ ...form, template })}
                        translationsHelper={translationsHelper}
                    />
                ) : null}
                <Warning>
                    When creating a new puzzle on a Regular scenario, do not select blocking puzzle in this modal, add
                    them after the puzzle creation.
                </Warning>
                <Button onClick={onSubmit}>Create</Button>
            </Content>
        </Modal>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Warning = styled.div`
    color: red;
`;
