import { useApolloClient } from '@apollo/client';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import {
    GetImageTranslationsQuery,
    ImageInput,
    ImageTranslationCreateInput,
    ImageTranslationFragment,
    ImageTranslationUpdateInput,
    useGetImageTranslationsQuery,
    useUpdateImageTranslationsMutation,
} from 'data/generated';
import { GET_IMAGE_TRANSLATIONS_QUERY } from 'data/queries/imageTranslation';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { LanguageType } from './useTranslations';

export type ImageTranslation = ImageTranslationCreateInput | ImageTranslationUpdateInput;

export type ImageTranslationsHook = {
    imageTranslations: ImageTranslationFragment[];
    doesImageKeyExist: (key: string) => boolean;
    generateUniqueImageTranslationKey: () => string;
    createEmptyImageTranslationFromKey: ({
        key,
        sectionId,
        isGlobal,
    }: {
        key: string;
        sectionId: string;
        isGlobal: boolean;
    }) => ImageTranslation;
    getImageTranslationFromKey: (key: string) => ImageTranslation | null;
    translateImageKey: ({ key }: { key: string }) => ImageInput;
    updateImageTranslation: (imageTranslationInput: ImageTranslation) => void;
    deleteImageTranslation: (imageTranslationKey?: string | null) => void;
    saveImageTranslations: () => Promise<void>;
    areImageTranslationsSaved: boolean;
};

export const useImageTranslations = (): ImageTranslationsHook => {
    const apolloClient = useApolloClient();

    const { displayFrench } = useTranslationParameters();

    const [createdImageTranslations, setCreatedImageTranslations] = useState<ImageTranslationCreateInput[]>([]);
    const [updatedImageTranslations, setUpdatedImageTranslations] = useState<ImageTranslationUpdateInput[]>([]);
    const [deletedImageTranslationIds, setDeletedImageTranslationIds] = useState<string[]>([]);

    const { data } = useGetImageTranslationsQuery();
    const [updateImageTranslations] = useUpdateImageTranslationsMutation();

    const imageTranslations = data?.imageTranslations ?? [];

    function doesImageKeyExist(key: string): boolean {
        const existingCreatedImageTranslation = createdImageTranslations?.find(
            (imageTranslation) => imageTranslation.key === key
        );
        const existingUpdatedImageTranslation = updatedImageTranslations?.find(
            (imageTranslation) => imageTranslation.key === key
        );
        const existingImageTranslation = imageTranslations?.find((imageTranslation) => imageTranslation.key === key);

        if (existingCreatedImageTranslation || existingUpdatedImageTranslation) {
            return true;
        }

        if (!existingImageTranslation) {
            return false;
        }

        const isExistingImageTranslationAlreadyUpdated = !!updatedImageTranslations?.find(
            (imageTranslation) => imageTranslation._id === existingImageTranslation._id
        );

        return !isExistingImageTranslationAlreadyUpdated;
    }

    function generateUniqueImageTranslationKey(): string {
        return new Date().getTime().toString();
    }

    function createEmptyImageTranslationFromKey({
        key,
        sectionId,
        isGlobal,
    }: {
        key: string;
        sectionId: string;
        isGlobal: boolean;
    }): ImageTranslation {
        return {
            english: {
                aspectRatio: 1,
                thumbhash: '',
                caption: null,
                file: undefined,
                type: undefined,
                url: undefined,
            },
            french: {
                aspectRatio: 1,
                thumbhash: '',
                caption: null,
                file: undefined,
                type: undefined,
                url: undefined,
            },
            isGlobal,
            sectionId,
            key,
        };
    }

    function getImageTranslationFromKey(key: string): ImageTranslation | null {
        return (
            createdImageTranslations?.find((imageTranslation) => imageTranslation.key === key) ??
            updatedImageTranslations?.find((imageTranslation) => imageTranslation.key === key) ??
            imageTranslations?.find((imageTranslation) => imageTranslation.key === key) ??
            null
        );
    }

    function translateImageKey({ key }: { key: string }): ImageInput {
        const imageTranslation = getImageTranslationFromKey(key);
        const language = displayFrench ? LanguageType.French : LanguageType.English;
        const emptyImage = {
            aspectRatio: 1,
            thumbhash: '',
            caption: undefined,
            file: undefined,
            type: undefined,
            url: undefined,
        };
        switch (language) {
            case LanguageType.French:
                return imageTranslation?.french || emptyImage;
            case LanguageType.English:
                return (
                    (imageTranslation?.english?.url
                        ? imageTranslation?.english
                        : { ...imageTranslation?.french, caption: imageTranslation?.english?.caption ?? null }) ||
                    emptyImage
                );
        }
    }

    function updateImageTranslation(imageTranslationInput: ImageTranslation): void {
        if (imageTranslationInput.key === '') {
            toast.warning(`You cannot have an empty imageTranslation key`);
            return;
        }
        // if no _id, it means it's a new imageTranslation input
        if (!('_id' in imageTranslationInput)) {
            // we update a already added new imageTranslation
            const createdImageTranslationsIndex = createdImageTranslations.findIndex(
                ({ key }) => key === imageTranslationInput.key
            );
            if (createdImageTranslationsIndex !== -1) {
                const newCreatedImageTranslations = [...createdImageTranslations];
                newCreatedImageTranslations[createdImageTranslationsIndex] = imageTranslationInput;
                setCreatedImageTranslations(newCreatedImageTranslations);
                return;
            }

            // we create a new imageTranslation
            // first check that the key doesn't already exist
            if (doesImageKeyExist(imageTranslationInput.key)) {
                toast.error('We already have a imageTranslation with this key');
                return;
            }

            const newCreatedImageTranslations = [...createdImageTranslations];
            newCreatedImageTranslations.push(imageTranslationInput);
            setCreatedImageTranslations(newCreatedImageTranslations);

            return;
        }

        // we update an already updated imageTranslation
        const updatedImageTranslationsIndex = updatedImageTranslations.findIndex(
            ({ _id }) => _id === imageTranslationInput._id
        );
        if (updatedImageTranslationsIndex !== -1) {
            const newUpdatedImageTranslations = [...updatedImageTranslations];
            newUpdatedImageTranslations[updatedImageTranslationsIndex] = imageTranslationInput;
            setUpdatedImageTranslations(newUpdatedImageTranslations);
            return;
        }

        // we update an existing imageTranslation
        const imageTranslationsIndex = data?.imageTranslations?.findIndex(
            ({ _id }) => _id === imageTranslationInput._id
        );
        if (imageTranslationsIndex !== -1) {
            const newUpdatedImageTranslations = [...updatedImageTranslations];
            newUpdatedImageTranslations.push(imageTranslationInput);
            setUpdatedImageTranslations(newUpdatedImageTranslations);
            return;
        }
    }

    function deleteImageTranslation(imageTranslationKey?: string | null): void {
        if (imageTranslationKey === undefined || imageTranslationKey === null) {
            return;
        }
        const imageTranslationId =
            updatedImageTranslations.find(({ key }) => key === imageTranslationKey)?._id ??
            imageTranslations.find(({ key }) => key === imageTranslationKey)?._id;

        if (imageTranslationId) {
            setDeletedImageTranslationIds([...deletedImageTranslationIds, imageTranslationId]);
            setUpdatedImageTranslations(updatedImageTranslations.filter(({ _id }) => _id !== imageTranslationId));
            toast.info(`ImageTranslation key ${imageTranslationKey} will be deleted in base after saving`);
        } else {
            setCreatedImageTranslations(createdImageTranslations.filter(({ key }) => key !== imageTranslationKey));
            toast.info(`ImageTranslation key ${imageTranslationKey} was only created in front. It will not be saved`);
        }
    }

    function updateCacheOnImageTranslationsSaved(newImageTranslations: ImageTranslationFragment[]) {
        const data = apolloClient.readQuery<GetImageTranslationsQuery>({
            query: GET_IMAGE_TRANSLATIONS_QUERY,
        });

        if (!data) {
            return;
        }

        apolloClient.writeQuery<GetImageTranslationsQuery>({
            query: GET_IMAGE_TRANSLATIONS_QUERY,
            data: { imageTranslations: newImageTranslations },
        });
    }

    async function saveImageTranslations(): Promise<void> {
        // we remove __typename before calling the mutation
        const loadingToastId = toast.warning('Waiting for ImageTranslations to be updated...', { autoClose: false });
        const { data } = await updateImageTranslations({
            variables: {
                archivedImageTranslationIds: deletedImageTranslationIds,
                newImageTranslations: createdImageTranslations.map(({ english, french, key, isGlobal, sectionId }) => ({
                    english: { ...english, __typename: undefined },
                    french: { ...french, __typename: undefined },
                    key,
                    isGlobal,
                    sectionId,
                })),
                updatedImageTranslations: updatedImageTranslations.map(
                    ({ _id, english, french, key, isGlobal, sectionId }) => ({
                        _id,
                        english: { ...english, __typename: undefined },
                        french: { ...french, __typename: undefined },
                        key,
                        isGlobal,
                        sectionId,
                    })
                ),
            },
        });

        if (data) {
            const { updateImageTranslations } = data;

            if (updateImageTranslations.errors) {
                for (const error of updateImageTranslations.errors) {
                    toast.error(error, { autoClose: 3000 });
                }
            }
            if (updateImageTranslations.imageTranslations) {
                updateCacheOnImageTranslationsSaved(updateImageTranslations.imageTranslations);
                toast.dismiss(loadingToastId);
                toast.success('ImageTranslations updated!', { autoClose: 3000 });
            }
        }

        setCreatedImageTranslations([]);
        setUpdatedImageTranslations([]);
        setDeletedImageTranslationIds([]);
    }

    return {
        imageTranslations,
        doesImageKeyExist,
        generateUniqueImageTranslationKey,
        createEmptyImageTranslationFromKey,
        getImageTranslationFromKey,
        translateImageKey,
        updateImageTranslation,
        deleteImageTranslation,
        saveImageTranslations,
        areImageTranslationsSaved:
            !createdImageTranslations.length && !updatedImageTranslations.length && !deletedImageTranslationIds.length,
    };
};
