import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import styled from 'styled-components';

export type MarkerType = { identifier: string; location: { lat: number; lng: number } };

export const DraggableMarker = ({
    marker,
    updateMarkerPosition,
}: {
    marker: MarkerType;
    updateMarkerPosition: (identifier: string) => (e: google.maps.MapMouseEvent) => void;
}) => {
    const [markerRef, setMarkerRef] = useState<google.maps.Marker | null>();

    return (
        <Marker
            key={marker.identifier}
            position={marker.location}
            draggable
            onDragEnd={updateMarkerPosition(marker.identifier)}
            onLoad={(marker) => setMarkerRef(marker)}
        >
            {markerRef && (
                <InfoWindow position={marker.location}>
                    <MarkerIndicator color={marker.identifier.slice(0, 6)} />
                </InfoWindow>
            )}
        </Marker>
    );
};

const MarkerIndicator = styled.div<{ color: string }>`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #${({ color }) => color};
`;
