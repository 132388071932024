import { Selector } from 'components';
import { TranslationArrayInputWithImage } from 'components/TranslationArrayInputWithImage';
import { LocationSelector, LocationType, Path } from 'components/LocationSelector';
import { isSelectorOptionMultiValue } from 'components/Selector';
import { TranslationInput } from 'components/TranslationInput';
import { ImageInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';
import styled from 'styled-components';
import { GoogleLocation } from 'types/location';
import { NullableTranslationInput } from 'components/NullableTranslationInput';
import { ImageTranslationInput } from 'components/ImageTranslationInput';

export type ScenarioLocationInput = {
    blockingScenarioLocationIds: string[];
    title: string;
    location: GoogleLocation | null;
    funFacts: { title?: string | null; text: string; image?: ImageInput | null }[];
    imageKey: string;
    visitedLandmark: string | null;
};

export const ScenarioLocationForm = ({
    scenarioLocationForm,
    setScenarioLocationForm,
    scenarioLocationOptions,
    translationsHelper,
}: {
    scenarioLocationForm: ScenarioLocationInput;
    setScenarioLocationForm: (input: ScenarioLocationInput) => void;
    scenarioLocationOptions: { value: string; label: string }[];
    translationsHelper: TranslationsHelper;
}) => {
    const { blockingScenarioLocationIds, funFacts, imageKey, location, title, visitedLandmark } = scenarioLocationForm;

    return (
        <Container>
            <Row>
                {location ? (
                    <LocationSelector
                        allowedTypes={[LocationType.Point]}
                        path={[{ lat: location.lat, lng: location.lng }]}
                        setPath={([location]: Path) =>
                            setScenarioLocationForm({
                                ...scenarioLocationForm,
                                location,
                            })
                        }
                    />
                ) : null}
                <InputsContainer>
                    <TranslationInput
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                        }}
                        label="Title"
                        value={title}
                        onChange={(value) =>
                            value ? setScenarioLocationForm({ ...scenarioLocationForm, title: value }) : undefined
                        }
                    />
                    <Selector<string>
                        isMulti
                        label="Blocking scenario locations"
                        options={scenarioLocationOptions}
                        value={blockingScenarioLocationIds}
                        onChange={(options) =>
                            isSelectorOptionMultiValue(options)
                                ? setScenarioLocationForm({
                                      ...scenarioLocationForm,
                                      blockingScenarioLocationIds: options.map(({ value }) => value),
                                  })
                                : undefined
                        }
                    />
                    <ImageTranslationInput
                        label="Image"
                        onChange={(value) => setScenarioLocationForm({ ...scenarioLocationForm, imageKey: value })}
                        value={imageKey}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                        }}
                    />
                    <NullableTranslationInput
                        label="Visited landmark (as it will appear on the scenario summary on mobile)"
                        value={visitedLandmark}
                        onChange={(value) =>
                            setScenarioLocationForm({ ...scenarioLocationForm, visitedLandmark: value })
                        }
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.visited_landmark`,
                        }}
                    />
                </InputsContainer>
            </Row>
            <TranslationArrayInputWithImage
                label="Fun facts"
                values={funFacts}
                setValues={(value) => setScenarioLocationForm({ ...scenarioLocationForm, funFacts: value })}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.fun_facts`,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;
