import styled, { useTheme } from 'styled-components';
import { FaPlus, FaTrash } from 'react-icons/fa';

import { SubmitBlockInput } from 'data/generated';
import { Input, Label } from 'components';

export const ColorLettersSubmitBlock = ({
    submitBlock,
    setSubmitBlock,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
}) => {
    const { colorLetters } = submitBlock;
    const theme = useTheme();

    if (!colorLetters) {
        throw Error('colorLetters is not defined in ColorLetter block');
    }

    return (
        <Container>
            <Label>Color-letters</Label>
            {colorLetters.map((colorLetter, colorLetterIndex) => (
                <Row key={colorLetters.length + colorLetterIndex}>
                    <SequenceContainer>
                        <Label>Color-letter No{colorLetterIndex + 1}</Label>
                        <Row key={colorLetterIndex}>
                            <Input
                                placeholder="Letter"
                                value={colorLetter.letter}
                                onChange={(newValue: string) =>
                                    setSubmitBlock({
                                        ...submitBlock,
                                        colorLetters: colorLetters.map((colorLetter, index) =>
                                            index !== colorLetterIndex
                                                ? colorLetter
                                                : { ...colorLetter, letter: newValue }
                                        ),
                                    })
                                }
                            />
                            <Input
                                placeholder="Color"
                                value={colorLetter.color}
                                onChange={(newValue: string) =>
                                    setSubmitBlock({
                                        ...submitBlock,
                                        colorLetters: colorLetters.map((colorLetter, index) =>
                                            index !== colorLetterIndex
                                                ? colorLetter
                                                : { ...colorLetter, color: newValue }
                                        ),
                                    })
                                }
                            />
                        </Row>
                    </SequenceContainer>
                    <ButtonContainer
                        backgroundColor={theme.colors.backgroundError}
                        onClick={() =>
                            setSubmitBlock({
                                ...submitBlock,
                                colorLetters: colorLetters.filter((_, valueIndex) => valueIndex !== colorLetterIndex),
                            })
                        }
                    >
                        <FaTrash color="white" size={12} />
                    </ButtonContainer>
                </Row>
            ))}
            <Row>
                <ButtonContainer
                    backgroundColor={theme.colors.backgroundSecondary}
                    onClick={() =>
                        setSubmitBlock({
                            ...submitBlock,
                            colorLetters: [...colorLetters, { letter: '', color: '' }],
                        })
                    }
                >
                    <FaPlus color="white" size={12} />
                </ButtonContainer>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const ButtonContainer = styled.div<{ backgroundColor: string }>`
    width: 25px;
    height: 25px;
    border-radius: 13px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const SequenceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgrey;
`;
