import { Card, Title } from 'components';
import { MultipleLocationsSelector } from 'components/MultipleLocationsSelector';
import { LandmarkFragment, useUpdateLandmarksLocationsMutation } from 'data/generated';
import { useState } from 'react';
import styled from 'styled-components';
import { NewLandmarkForm } from './NewLandmarkForm';
import { UpdateLandmarkForm } from './UpdateLandmarkForm';

export const ScenarioLandmarks = ({ landmarks, scenarioId }: { landmarks: LandmarkFragment[]; scenarioId: string }) => {
    const [newLandmarkLocation, setNewLandmarkLocation] = useState<{ lat: number; lng: number } | null>(null);

    const [updateLandmarksLocationsMutation] = useUpdateLandmarksLocationsMutation();

    async function saveMarkerLocations(
        landmarkIdsAndLocations: { identifier: string; location: { lat: number; lng: number } }[]
    ) {
        await updateLandmarksLocationsMutation({
            variables: {
                landmarkIdsAndLocations: landmarkIdsAndLocations.map(
                    ({ identifier, location: { lat: latitude, lng: longitude } }) => ({
                        landmarkId: identifier,
                        location: { latitude, longitude },
                    })
                ),
            },
        });
    }

    return (
        <Card style={{ marginTop: 10, gap: 10 }}>
            <Title>Scenario landmarks</Title>
            <Row>
                <MultipleLocationsSelector
                    markers={landmarks.map(({ _id: identifier, location: { latitude: lat, longitude: lng } }) => ({
                        identifier,
                        location: { lat, lng },
                    }))}
                    saveMarkerLocations={saveMarkerLocations}
                    newMarker={newLandmarkLocation}
                    addMarker={(location) => setNewLandmarkLocation(location)}
                />
                {newLandmarkLocation ? (
                    <NewLandmarkForm
                        location={newLandmarkLocation}
                        scenarioId={scenarioId}
                        resetLocation={() => setNewLandmarkLocation(null)}
                    />
                ) : null}
            </Row>
            {landmarks.map((landmark, index) => {
                const { _id: landmarkId } = landmark;

                return (
                    <PreviewContainer key={landmarkId}>
                        <UpdateLandmarkForm landmark={landmark} index={index} />
                    </PreviewContainer>
                );
            })}
        </Card>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
