import { Button } from 'components';
import { EditableContentBlocks } from 'components/EditableContentBlocks';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { TranslationInput } from 'components/TranslationInput';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import {
    ContentBlockInput,
    LandmarkFragment,
    useArchiveLandmarkMutation,
    useUpdateLandmarkMutation,
} from 'data/generated';
import { useImageTranslations } from 'hooks/useImageTranslations';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { FaSave, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { removeFieldFromObject } from 'routes/utils';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const UpdateLandmarkForm = ({ landmark, index }: { landmark: LandmarkFragment; index: number }) => {
    const { _id: landmarkId, title, blocks, imageKey, location, scenarioId } = landmark;

    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.landmark.${keyPrefixLocation}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [landmarkForm, setLandmarkForm] = useState<{
        title: string;
        blocks: ContentBlockInput[];
        imageKey: string;
        location: { latitude: number; longitude: number };
    }>({
        title,
        blocks: blocks.map((block) => removeFieldFromObject({ ...block }, '__typename')),
        imageKey,
        location: { latitude: location.latitude, longitude: location.longitude },
    });

    const [updateLandmark] = useUpdateLandmarkMutation();
    const [archiveLandmarkMutation] = useArchiveLandmarkMutation();

    async function submitLandmark() {
        const { data } = await updateLandmark({ variables: { landmarkId, updateInput: landmarkForm } });

        if (data) {
            const {
                updateLandmark: { errors, landmark },
            } = data;

            if (errors) {
                errors.forEach((error) => toast.error(error, { autoClose: 5000 }));
            }

            if (landmark) {
                toast.success('Landmark has been updated', { autoClose: 5000 });

                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
            }
        }
    }

    async function archiveLandmark() {
        const { data } = await archiveLandmarkMutation({ variables: { landmarkId } });

        if (data) {
            const {
                archiveLandmark: { errors, landmark },
            } = data;

            if (errors) {
                errors.forEach((error) => toast.error(error, { autoClose: 5000 }));
            }

            if (landmark) {
                toast.success('Landmark has been archived', { autoClose: 5000 });
            }
        }
    }

    return (
        <Container>
            <LandmarkIdentifier color={landmarkId.slice(0, 6)} />
            <LandmarkContainer>
                <Header>
                    <HeaderSubtitle>
                        Landmark {landmarkId} (No {index + 1})
                    </HeaderSubtitle>
                    <Button onClick={archiveLandmark} backgroundColor={Colors.secondary.red}>
                        <FaTrash />
                    </Button>
                    <Button onClick={submitLandmark}>
                        <FaSave />
                    </Button>
                </Header>
                <TranslationInput
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                    }}
                    label="Title"
                    value={landmarkForm.title}
                    onChange={(value) => setLandmarkForm({ ...landmarkForm, title: value })}
                />
                <ImageTranslationInput
                    label="Image"
                    onChange={(value) => setLandmarkForm({ ...landmarkForm, imageKey: value })}
                    value={landmarkForm.imageKey}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                    }}
                />
                <EditableContentBlocks
                    blocks={landmarkForm.blocks}
                    setBlocks={(blocks) => setLandmarkForm({ ...landmarkForm, blocks })}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.content`,
                    }}
                />
            </LandmarkContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const LandmarkIdentifier = styled.div<{ color: string }>`
    height: 100%;
    width: 10px;
    background-color: #${({ color }) => color};
`;

const LandmarkContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
    gap: 10px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

const HeaderSubtitle = styled.span`
    flex: 1;
    font-size: 16px;
    font-style: italic;
`;
