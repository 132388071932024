import { useState } from 'react';
import styled from 'styled-components';

import { ImageInput, useCreateScenarioLocationMutation } from 'data/generated';
import { useScenarioLocationHandlers } from 'hooks/useScenarioLocationHandlers';

import { GoogleLocation } from 'types/location';

import { Button, Modal, Selector } from 'components';
import { TranslationArrayInputWithImage } from 'components/TranslationArrayInputWithImage';
import { LocationSelector, LocationType } from 'components/LocationSelector';
import { isSelectorOptionMultiValue } from 'components/Selector';
import { useTranslations } from 'hooks/useTranslations';
import { TranslationInput } from 'components/TranslationInput';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { NullableTranslationInput } from 'components/NullableTranslationInput';
import { useImageTranslations } from 'hooks/useImageTranslations';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { toast } from 'react-toastify';

export const ScenarioLocationFormModal = ({
    scenarioId,
    scenarioLocationOptions,
    initialMapBounds,
    onClose,
}: {
    scenarioId: string;
    scenarioLocationOptions: { value: string; label: string }[];
    initialMapBounds: google.maps.LatLngBounds | undefined;
    onClose: () => void;
}) => {
    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.location.${keyPrefixLocation}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [form, setForm] = useState<{
        blockingScenarioLocationIds: string[];
        title: string;
        location: GoogleLocation | null;
        funFacts: { title?: string | null; text: string; image?: ImageInput | null }[];
        imageKey: string;
        visitedLandmark: string | null;
    }>({
        blockingScenarioLocationIds: [],
        title: '',
        location: null,
        funFacts: [],
        imageKey: '',
        visitedLandmark: null,
    });

    const { blockingScenarioLocationIds, funFacts, imageKey, location, title, visitedLandmark } = form;

    const [createScenarioLocationMutation] = useCreateScenarioLocationMutation();

    const { scenarioLocationAddedHandler } = useScenarioLocationHandlers();

    async function onSubmit() {
        if (!location?.lat || !location?.lng) {
            toast.error('you should define a location');
            return;
        }

        const { data } = await createScenarioLocationMutation({
            variables: {
                scenarioLocationInput: {
                    blockingScenarioLocationIds,
                    funFacts,
                    imageKey,
                    location: { latitude: location.lat, longitude: location.lng },
                    title,
                    visitedLandmark,
                },
                scenarioId,
            },
        });

        if (data) {
            const {
                createScenarioLocation: { scenarioLocation, errors },
            } = data;

            if (errors?.length) {
                for (const error of errors) {
                    toast.error(error);
                }
            }

            if (scenarioLocation) {
                scenarioLocationAddedHandler({ scenarioLocation, scenarioId });
                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
                onClose();
            } else {
                toast.error('There was an unknown error');
            }
        }
    }

    return (
        <Modal onClose={onClose}>
            <Content>
                <LocationSelector
                    allowedTypes={[LocationType.Point]}
                    path={location ? [location] : []}
                    setPath={(path) => setForm({ ...form, location: path[0] })}
                    initialMapBounds={initialMapBounds}
                />
                <TranslationInput
                    label="Title"
                    value={title}
                    onChange={(value) => setForm({ ...form, title: value })}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                    }}
                />
                <NullableTranslationInput
                    label="Visited landmark (as it will appear on the scenario summary on mobile)"
                    value={visitedLandmark}
                    onChange={(value) => setForm({ ...form, visitedLandmark: value })}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.visited_landmark`,
                    }}
                />
                <Selector<string>
                    isMulti
                    label="Blocking scenario locations"
                    options={scenarioLocationOptions}
                    value={blockingScenarioLocationIds}
                    onChange={(options) =>
                        isSelectorOptionMultiValue(options)
                            ? setForm({ ...form, blockingScenarioLocationIds: options.map(({ value }) => value) })
                            : undefined
                    }
                />
                <TranslationArrayInputWithImage
                    label="Fun facts"
                    values={funFacts}
                    setValues={(value) => setForm({ ...form, funFacts: value })}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.fun_facts`,
                    }}
                />
                <ImageTranslationInput
                    label="Image"
                    onChange={(value) => setForm({ ...form, imageKey: value })}
                    value={imageKey}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                    }}
                />
                <Warning>
                    When creating a new location on a Regular scenario, do not select blocking location in this modal,
                    add them after the location creation.
                </Warning>
                <Button onClick={onSubmit}>Create</Button>
            </Content>
        </Modal>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Warning = styled.div`
    color: red;
`;
