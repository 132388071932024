import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';
import 'react-toastify/dist/ReactToastify.css';

import { Discounts } from './Discounts';
import { Games } from './Games';
import { Scenarios } from './Scenarios';
import { ScenarioDetails } from 'routes/Scenarios/ScenarioDetails';
import { Users } from './Users';
import { ScenarioLocationDetails } from './ScenarioLocations/ScenarioLocationDetails';
import { Navbar } from 'components';
import styled from 'styled-components';
import { GameDetails } from './GameDetails';
import { Tools } from './Tools';
import { Collections } from './Collections';
import { CollectionDetails } from './CollectionDetails';

function RequireAuth({ children }: { children: JSX.Element }) {
    const auth = useAuth();
    const location = useLocation();

    if (!auth?.user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export const Home = () => {
    return (
        <RequireAuth>
            <>
                <Navbar />
                <DisplayContainer>
                    <Routes>
                        <Route path="/collections" element={<Collections />} />
                        <Route path="/collection/:collectionId" element={<CollectionDetails />} />
                        <Route path="/discounts" element={<Discounts />} />
                        <Route path="/games" element={<Games />} />
                        <Route path="/game/:gameId" element={<GameDetails />} />
                        <Route path="/scenarios" element={<Scenarios />} />
                        <Route path="/scenario/:scenarioId" element={<ScenarioDetails />} />
                        <Route
                            path="/scenario/:scenarioId/scenario-location/:scenarioLocationId"
                            element={<ScenarioLocationDetails />}
                        />
                        <Route path="/tools" element={<Tools />} />
                        <Route path="/users" element={<Users />} />
                    </Routes>
                </DisplayContainer>
                <ToastContainer position="bottom-right" newestOnTop />
            </>
        </RequireAuth>
    );
};

const DisplayContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: hidden;
`;
