import styled, { useTheme } from 'styled-components';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { TranslationsHelper } from 'hooks/useTranslations';
import { Title } from 'components';

export const PreviewImages = ({
    previewImageKeys,
    onChange,
    translationsHelper,
}: {
    previewImageKeys: string[];
    onChange: (previewImageKeys: string[]) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const theme = useTheme();

    const { imageTranslationsHook } = translationsHelper;
    const { deleteImageTranslation } = imageTranslationsHook;

    return (
        <Container>
            <Title>Preview Images</Title>
            {previewImageKeys.map((previewImageKey, index) => {
                return (
                    <InputWithImage key={previewImageKeys.length + index}>
                        <InputsContainer>
                            <ImageTranslationInput
                                label="Image"
                                onChange={(newImageKey) =>
                                    onChange(
                                        previewImageKeys.map((previewImageKeys, previewImageKeysIndex) =>
                                            index === previewImageKeysIndex ? newImageKey : previewImageKeys
                                        )
                                    )
                                }
                                value={previewImageKey}
                                translationsHelper={{
                                    ...translationsHelper,
                                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${index}.image`,
                                }}
                            />
                        </InputsContainer>
                        <ButtonContainer
                            backgroundColor={theme.colors.backgroundError}
                            onClick={() => {
                                onChange(
                                    previewImageKeys.filter((_, previewImageKeyIndex) => previewImageKeyIndex !== index)
                                );
                                deleteImageTranslation(previewImageKey);
                            }}
                        >
                            <FaTrash color="white" />
                        </ButtonContainer>
                    </InputWithImage>
                );
            })}
            <Row>
                <ButtonContainer
                    backgroundColor={theme.colors.backgroundSecondary}
                    onClick={() => onChange([...previewImageKeys, ''])}
                >
                    <FaPlus color="white" />
                </ButtonContainer>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const InputWithImage = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-left: 7px solid #222222;
    border-radius: 7px;
    padding-left: 5px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const ButtonContainer = styled.div<{ backgroundColor: string }>`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
