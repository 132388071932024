import { TranslationsHelper } from 'hooks/useTranslations';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import styled from 'styled-components';

export const PreviewParagraphBlock = ({
    text,
    translationsHelper,
}: {
    text: string;
    translationsHelper: TranslationsHelper;
}) => {
    const {
        translationsHook: { translateKey },
    } = translationsHelper;

    const textTranslation = translateKey({
        key: text,
    });
    // @ts-ignore
    return <ReactMarkdown components={{ p: Paragraph }}>{textTranslation}</ReactMarkdown>;
};

const Paragraph = styled.p`
    margin: 0;
    white-space: pre-wrap;
`;
