import { GameLocationFragment, useGetGameQuery } from 'data/generated';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GameLocationDetails } from './GameLocationDetails';
import { GameStateTag } from 'routes/Games/GameStateTag';
import { getFormattedDateAndTime } from 'utils/time-formatting';
import { displayPrice } from 'utils/price';
import { useTranslations } from 'hooks/useTranslations';

function orderGameLocationsByBlocking(gameLocations: GameLocationFragment[]) {
    const unblockedLocations = gameLocations.filter(
        (gameLocation) => gameLocation.scenarioLocation.blockingScenarioLocationIds.length === 0
    );
    let locationsToOrder = gameLocations.filter(
        (gameLocation) => gameLocation.scenarioLocation.blockingScenarioLocationIds.length > 0
    );
    const orderedLocations = [...unblockedLocations];

    let operations = 0;

    while (locationsToOrder.length > 0 && operations < 100) {
        const unblockedScenarioLocationIds = unblockedLocations.map(({ scenarioLocation }) => scenarioLocation._id);
        const newUnblockedLocations = locationsToOrder.filter((gameLocation) =>
            gameLocation.scenarioLocation.blockingScenarioLocationIds.every((scenarioLocationId) =>
                unblockedScenarioLocationIds.includes(scenarioLocationId)
            )
        );

        orderedLocations.push(...newUnblockedLocations);
        unblockedLocations.push(...newUnblockedLocations);

        const orderedLocationIds = orderedLocations.map(({ _id }) => _id);
        locationsToOrder = locationsToOrder.filter((gameLocation) => !orderedLocationIds.includes(gameLocation._id));
        operations++;
    }

    return orderedLocations;
}

export const GameDetails = () => {
    const { gameId } = useParams<{ gameId: string }>() as { gameId: string };

    const { translateKey } = useTranslations();

    const { data } = useGetGameQuery({ variables: { gameId } });

    if (!data) {
        return null;
    }

    const {
        game: {
            completedAt,
            createdAt,
            gameLocations,
            isInDemoMode,
            joinCode,
            scenario: { title },
            state,
            transaction,
            user: { email, firstName, lastName },
            startedAt,
        },
    } = data;

    const numberOfHints = gameLocations.reduce(
        (locationAcc, gameLocation) =>
            gameLocation.gamePuzzles.reduce(
                (puzzleAcc, gamePuzzle) => puzzleAcc + gamePuzzle.numberOfUsedHints,
                locationAcc
            ),
        0
    );
    const numberOfWrongGuesses = gameLocations.reduce(
        (locationAcc, gameLocation) =>
            gameLocation.gamePuzzles.reduce(
                (puzzleAcc, gamePuzzle) => puzzleAcc + Math.max(0, (gamePuzzle.guesses?.length ?? 0) - 1),
                locationAcc
            ),
        0
    );

    const scenarioLocationsOrderedByBlocking = orderGameLocationsByBlocking(gameLocations);

    return (
        <Container>
            <Header>
                <Title>
                    {translateKey({
                        key: title,
                    })}
                </Title>
                <GameStateTag state={state} />
            </Header>
            <Row>
                <b>Buyer</b>
                <Value>
                    {firstName} {lastName} ({email})
                </Value>
            </Row>
            <Row>
                <b>Price details</b>
                {transaction ? (
                    <Value>
                        {displayPrice(transaction.priceDetails.paid)} paid +{' '}
                        {displayPrice(transaction.priceDetails.discount)} discount ={' '}
                        {displayPrice(transaction.priceDetails.discount)} total ({transaction.origin})
                    </Value>
                ) : (
                    <Value>None</Value>
                )}
            </Row>
            <Row>
                <b>Join code</b>
                <Value>{joinCode.match(new RegExp('.{1,4}', 'g'))?.join('-')}</Value>
            </Row>
            <Row>
                <b>Demo mode ?</b>
                <Value>{isInDemoMode ? 'Yes' : 'No'}</Value>
            </Row>
            <Row>Creation date: {getFormattedDateAndTime(new Date(createdAt), { showWeekday: true })}</Row>
            <span>
                Start date: {startedAt ? getFormattedDateAndTime(new Date(startedAt), { showWeekday: true }) : '-'}
            </span>
            <span>
                Completion date:{' '}
                {completedAt ? getFormattedDateAndTime(new Date(completedAt), { showWeekday: true }) : '-'}
            </span>
            <span>Number of hints: {numberOfHints}</span>
            <span>Number of wrong guesses: {numberOfWrongGuesses}</span>
            {scenarioLocationsOrderedByBlocking.map((gameLocation) => {
                return <GameLocationDetails key={gameLocation._id} gameLocation={gameLocation} />;
            })}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
    overflow: scroll;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

const Title = styled.h1`
    margin: 0;
`;

const Row = styled.div`
    display: flex;
    gap: 10px;
`;

const Value = styled.span`
    color: grey;
`;
