import { FullPageLoader, ScrollContainer, TableView } from 'components';
import { useGetUsersQuery } from 'data/generated';

export const Users = () => {
    const { data, loading, error } = useGetUsersQuery();

    if (loading) {
        return <FullPageLoader />;
    }

    if (error || !data) {
        return <>{error?.message}</>;
    }

    const USER_FIELDS = [
        { name: '_id', label: 'ID' },
        { name: 'email', label: 'email' },
        { name: 'firstName', label: 'First name' },
        { name: 'lastName', label: 'Last name' },
        { name: 'stripeCustomerId', label: 'stripeCustomerId' },
        { name: 'deviceId', label: 'deviceId' },
    ];

    const { users } = data;

    return (
        <ScrollContainer>
            <TableView fields={USER_FIELDS} entries={users} />
        </ScrollContainer>
    );
};
