import { gql } from '@apollo/client';
import { CONTENT_BLOCK_FRAGMENT } from './content';

export const LANDMARK_FRAGMENT = gql`
    fragment Landmark on Landmark {
        _id
        blocks {
            ...ContentBlock
        }
        imageKey
        location {
            latitude
            longitude
        }
        scenarioId
        state
        title
    }
    ${CONTENT_BLOCK_FRAGMENT}
`;
