import { Input } from 'components';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { SubmitBlockInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';
import styled from 'styled-components';

export const PhotoPuzzleSubmitBlock = ({
    submitBlock,
    setSubmitBlock,
    translationsHelper,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { numberOfColumns, numberOfRows, photoPuzzleImageKey } = submitBlock;

    return (
        <Container>
            <Input
                label="Number of rows"
                value={numberOfRows ?? 0}
                type="number"
                onChange={(value) => setSubmitBlock({ ...submitBlock, numberOfRows: Number(value) })}
            />
            <Input
                label="Number of columns"
                value={numberOfColumns ?? 0}
                type="number"
                onChange={(value) => setSubmitBlock({ ...submitBlock, numberOfColumns: Number(value) })}
            />
            <ImageTranslationInput
                label="Image"
                onChange={(value) => setSubmitBlock({ ...submitBlock, photoPuzzleImageKey: value })}
                value={photoPuzzleImageKey ?? ''}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
