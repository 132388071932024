import { DefaultTheme } from 'styled-components';
import { Colors } from 'styles/colors';

export const lightTheme: DefaultTheme = {
    borderRadius: { sm: 5, md: 10, lg: 15, xl: 20 },
    boxShadow: '0 0 5px #888',
    colors: {
        textPrimary: Colors.primary.black,
        textSecondary: Colors.primary.white,
        textError: Colors.secondary.red,
        textSuccess: Colors.secondary.green,
        backgroundPrimary: Colors.primary.lightGrey,
        backgroundSecondary: Colors.primary.black,
        backgroundError: Colors.secondary.red,
        backgroundSuccess: Colors.secondary.green,
        borderPrimary: Colors.secondary.lightGrey,
    },
};

export const darkTheme: DefaultTheme = {
    borderRadius: { sm: 5, md: 10, lg: 15, xl: 20 },
    boxShadow: '0 0 5px #888',
    colors: {
        textPrimary: Colors.primary.black,
        textSecondary: Colors.primary.white,
        textError: Colors.secondary.red,
        textSuccess: Colors.secondary.green,
        backgroundPrimary: Colors.primary.lightGrey,
        backgroundSecondary: Colors.primary.black,
        backgroundError: Colors.secondary.red,
        backgroundSuccess: Colors.secondary.green,
        borderPrimary: Colors.secondary.lightGrey,
    },
};
