import { ContentBlockInput } from 'data/generated';

import { PreviewTitleBlock } from 'components/ContentBlocks/PreviewTitleBlock';
import { PreviewParagraphBlock } from 'components/ContentBlocks/PreviewParagraphBlock';

import {
    isEquationsContentBlock,
    isImageContentBlock,
    isParagraphContentBlock,
    isSubtitleContentBlock,
    isTitleContentBlock,
} from 'components/EditableContentBlocks/utils';
import { PreviewSubtitleBlock } from 'components/ContentBlocks/PreviewSubtitleBlock';
import { PreviewImageBlock } from 'components/ContentBlocks/PreviewImageBlock';
import { PreviewEquationsBlock } from 'components/ContentBlocks/PreviewEquationsBlock';
import { TranslationsHelper } from 'hooks/useTranslations';

export const ContentBlock = ({
    block,
    translationsHelper,
}: {
    block: ContentBlockInput;
    translationsHelper: TranslationsHelper;
}) => {
    if (isParagraphContentBlock(block)) {
        return <PreviewParagraphBlock text={block.text ?? ''} translationsHelper={translationsHelper} />;
    }

    if (isTitleContentBlock(block)) {
        return <PreviewTitleBlock text={block.text ?? ''} translationsHelper={translationsHelper} />;
    }

    if (isSubtitleContentBlock(block)) {
        return <PreviewSubtitleBlock text={block.text ?? ''} translationsHelper={translationsHelper} />;
    }

    if (isImageContentBlock(block)) {
        return <PreviewImageBlock imageKey={block.imageKey} translationsHelper={translationsHelper} />;
    }

    if (isEquationsContentBlock(block)) {
        return <PreviewEquationsBlock equations={block.equations ?? []} translationsHelper={translationsHelper} />;
    }

    return null;
};
