import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { Button, Label } from 'components';
import { TranslationsHelper } from 'hooks/useTranslations';
import { useState } from 'react';
import Switch from 'react-switch';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { MarkdownTextArea } from './MarkdownTextArea';

export const TranslationInput = ({
    label,
    sublabel,
    name,
    value,
    onChange,
    disabled = false,
    isMarkdown = false,
    isTextArea = false,
    translationsHelper,
}: {
    label?: string;
    sublabel?: string;
    name?: string;
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    isMarkdown?: boolean;
    isTextArea?: boolean;
    translationsHelper: TranslationsHelper;
}) => {
    const { translationsHook, translationsKeyPrefix, isGlobal, sectionId } = translationsHelper;
    const {
        getTranslationFromKey,
        generateUniqueTranslationKey,
        createEmptyTranslationFromKey,
        updateTranslation,
        doesKeyExist,
        areTranslationsSaved,
    } = translationsHook;
    const { displayEnglish, displayFrench, displayKey } = useTranslationParameters();

    const [newKey, setNewKey] = useState<string>(value);

    const translation =
        getTranslationFromKey(value) ?? createEmptyTranslationFromKey({ isGlobal, key: value, sectionId });

    return (
        <Container areTranslationsSaved={areTranslationsSaved}>
            {label && <Label>{label}</Label>}
            {sublabel && <Sublabel>{sublabel}</Sublabel>}

            {translation === null ? (
                <>
                    <Button
                        onClick={() => {
                            onChange(generateUniqueTranslationKey()); // for new translations it's better to not have the value ''. It's better to generate an 'unique' key
                        }}
                    >
                        Ajouter
                    </Button>
                </>
            ) : (
                <>
                    {displayKey ? (
                        <InputContainer>
                            <Sublabel>{value}</Sublabel>
                            ➡️
                            <StyledInput
                                value={newKey}
                                name={name}
                                onChange={(e) => setNewKey(e.target.value)}
                                disabled={disabled}
                                style={newKey !== value && doesKeyExist(newKey) ? { color: Colors.secondary.red } : {}}
                            />
                            <KeyGenerationButton
                                onClick={() => {
                                    setNewKey(translationsKeyPrefix);
                                }}
                            >
                                💡
                            </KeyGenerationButton>
                            <Button
                                onClick={() => {
                                    updateTranslation({ ...translation, key: newKey });
                                    onChange(newKey);
                                }}
                                disabled={doesKeyExist(newKey)}
                            >
                                Changer
                            </Button>
                            <Switch
                                onChange={() => updateTranslation({ ...translation, isGlobal: !translation.isGlobal })}
                                checked={translation.isGlobal}
                                onColor={Colors.secondary.green}
                                offColor={Colors.secondary.lightGrey}
                            />
                            {translation.isGlobal ? 'Global' : null}
                        </InputContainer>
                    ) : null}

                    {displayFrench ? (
                        <>
                            <Sublabel>FR 🇫🇷</Sublabel>
                            <InputContainer>
                                {isMarkdown ? (
                                    <MarkdownTextArea
                                        value={translation.french}
                                        onChange={(markdown) => {
                                            if (markdown !== translation.french) {
                                                updateTranslation({ ...translation, french: markdown });
                                            }
                                        }}
                                    />
                                ) : isTextArea ? (
                                    <StyledTextArea
                                        value={translation.french}
                                        name={name}
                                        rows={6}
                                        onChange={(e) => updateTranslation({ ...translation, french: e.target.value })}
                                        disabled={disabled}
                                    />
                                ) : (
                                    <StyledInput
                                        value={translation.french}
                                        name={name}
                                        onChange={(e) => updateTranslation({ ...translation, french: e.target.value })}
                                        disabled={disabled}
                                    />
                                )}
                            </InputContainer>
                        </>
                    ) : null}

                    {displayEnglish ? (
                        <>
                            <Sublabel>EN 🇬🇧</Sublabel>
                            <InputContainer>
                                {isMarkdown ? (
                                    <MarkdownTextArea
                                        value={translation.english}
                                        onChange={(markdown) => {
                                            if (markdown !== translation.english) {
                                                updateTranslation({ ...translation, english: markdown });
                                            }
                                        }}
                                    />
                                ) : isTextArea ? (
                                    <StyledTextArea
                                        value={translation.english}
                                        name={name}
                                        rows={6}
                                        onChange={(e) => updateTranslation({ ...translation, english: e.target.value })}
                                        disabled={disabled}
                                    />
                                ) : (
                                    <StyledInput
                                        value={translation.english}
                                        name={name}
                                        onChange={(e) => updateTranslation({ ...translation, english: e.target.value })}
                                        disabled={disabled}
                                    />
                                )}
                            </InputContainer>
                        </>
                    ) : null}
                </>
            )}
        </Container>
    );
};

const Container = styled.div<{ areTranslationsSaved: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    border: 1px solid
        ${({ areTranslationsSaved }) => (areTranslationsSaved ? Colors.secondary.lightGrey : Colors.secondary.red)};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    padding: 5px;
`;

const Sublabel = styled.span``;

const StyledInput = styled.input`
    flex-grow: 1;
    font-size: 16px;
    padding: 10px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border: 1px solid ${Colors.secondary.lightGrey};
    :focus {
        outline: none;
    }
`;

const StyledTextArea = styled.textarea`
    font-size: 16px;
    padding: 10px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border: 1px solid ${Colors.secondary.lightGrey};
    :focus {
        outline: none;
    }
    width: 100%;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;
`;

const KeyGenerationButton = styled.div`
    cursor: pointer;
`;
