import { gql } from '@apollo/client';
import { LANDMARK_FRAGMENT } from 'data/fragments/landmark';
import { SCENARIO_FRAGMENT } from 'data/fragments/scenario';
import { SCENARIO_LOCATION_OF_SCENARIO_FRAGMENT } from 'data/fragments/scenarioLocation';

export const GET_SCENARIOS_QUERY = gql`
    query GET_SCENARIOS {
        scenarios {
            _id
            imageKey
            price
            state
            title
        }
    }
`;

export const GET_SCENARIO_DETAILS_QUERY = gql`
    query GET_SCENARIO_DETAILS($scenarioId: ID!) {
        scenario(scenarioId: $scenarioId) {
            ...Scenario
            landmarks {
                ...Landmark
            }
            scenarioLocations {
                ...ScenarioLocationOfScenario
            }
        }
    }
    ${LANDMARK_FRAGMENT}
    ${SCENARIO_FRAGMENT}
    ${SCENARIO_LOCATION_OF_SCENARIO_FRAGMENT}
`;
