import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';

import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const center = {
    lat: 48.8575943077439,
    lng: 2.341761833549674,
};

type Path = { lat: number; lng: number }[];

export const PathPreview = ({ paths }: { paths: Path[] }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDSwTo3v5o77PoHFlyMTLgtWBgXVDB_oDY',
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);

    useEffect(() => {
        if (map && paths.length > 0) {
            const points = paths.flatMap((path) => path);

            const { minLat, minLng, maxLat, maxLng } = points.reduce(
                (acc, point) => {
                    return {
                        minLat: Math.min(acc.minLat, point.lat),
                        minLng: Math.min(acc.minLng, point.lng),
                        maxLat: Math.max(acc.maxLat, point.lat),
                        maxLng: Math.max(acc.maxLng, point.lng),
                    };
                },
                { minLat: 100, minLng: 100, maxLat: 0, maxLng: 0 }
            );

            const coordinatesDelta = 0;

            map.fitBounds(
                new google.maps.LatLngBounds(
                    new google.maps.LatLng(minLat - coordinatesDelta, minLng - coordinatesDelta),
                    new google.maps.LatLng(maxLat + coordinatesDelta, maxLng + coordinatesDelta)
                )
            );
        }
    }, [map, paths]);

    const onMapLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map);
    }, []);

    const onMapUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    return (
        <MapContainer>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={center}
                    zoom={12}
                    onLoad={onMapLoad}
                    onUnmount={onMapUnmount}
                    options={{ draggableCursor: 'crosshair', gestureHandling: 'cooperative' }}
                >
                    {paths.map((path, index) => (
                        <Polyline
                            key={index}
                            path={path}
                            options={{
                                strokeColor: 'rgb(0, 120, 240)',
                                strokeWeight: 3,
                            }}
                        />
                    ))}
                </GoogleMap>
            ) : (
                <></>
            )}
        </MapContainer>
    );
};

const MapContainer = styled.div`
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
    width: 600px;
    aspect-ratio: 1.5;
`;
