import { gql } from '@apollo/client';

export const TRANSLATION_FRAGMENT = gql`
    fragment Translation on Translation {
        _id
        key
        isGlobal
        sectionId
        french
        english
    }
`;
