import styled from 'styled-components';

import { SubmitBlockInput } from 'data/generated';
import { Input, Label } from 'components';
import { TranslationInput } from 'components/TranslationInput';
import { TranslationsHelper } from 'hooks/useTranslations';

export const CesarCodeSubmitBlock = ({
    submitBlock,
    setSubmitBlock,
    translationsHelper,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { stringCode, plainText } = submitBlock;

    if (typeof stringCode !== 'string' || typeof plainText !== 'string') {
        throw Error('stringCode or plainText is missing in CesarCode block');
    }

    return (
        <Container>
            <Label>Cesar code</Label>

            <Input
                placeholder="0123"
                value={stringCode}
                onChange={(newValue: string) =>
                    setSubmitBlock({
                        ...submitBlock,
                        stringCode: newValue,
                    })
                }
            />

            <TranslationInput
                label="Plain text"
                sublabel="This text will be 'encrypted' on the mobile app to solve the puzzle"
                value={plainText}
                onChange={(newValue) =>
                    setSubmitBlock({
                        ...submitBlock,
                        plainText: newValue,
                    })
                }
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.plainText`,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
