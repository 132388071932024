import { ContentBlockInput } from 'data/generated';

import { EditableTextBlock } from 'components/EditableContentBlocks//EditableTextBlock';
import { EditableImageBlock } from 'components/EditableContentBlocks/EditableImageBlock';
import { EditableEquationsBlock } from 'components/EditableContentBlocks/EditableEquationsBlock';

import {
    isEquationsContentBlock,
    isImageContentBlock,
    isParagraphContentBlock,
    isSubtitleContentBlock,
    isTitleContentBlock,
} from 'components/EditableContentBlocks/utils';
import { TranslationsHelper } from 'hooks/useTranslations';

export const EditableBlock = ({
    block,
    setBlock,
    moveDownBlock,
    moveUpBlock,
    deleteBlock,
    translationsHelper,
}: {
    block: ContentBlockInput;
    setBlock: (block: ContentBlockInput) => void;
    moveDownBlock: () => void;
    moveUpBlock: () => void;
    deleteBlock: () => void;
    translationsHelper: TranslationsHelper;
}) => {
    if (isParagraphContentBlock(block) || isSubtitleContentBlock(block) || isTitleContentBlock(block)) {
        return (
            <EditableTextBlock
                label={block.type}
                text={block.text ?? ''}
                onChange={(text) => setBlock({ ...block, text })}
                moveDownBlock={moveDownBlock}
                moveUpBlock={moveUpBlock}
                deleteBlock={deleteBlock}
                translationsHelper={translationsHelper}
            />
        );
    }

    if (isImageContentBlock(block)) {
        return (
            <EditableImageBlock
                block={block}
                setBlock={setBlock}
                moveDownBlock={moveDownBlock}
                moveUpBlock={moveUpBlock}
                deleteBlock={deleteBlock}
                translationsHelper={translationsHelper}
            />
        );
    }

    if (isEquationsContentBlock(block)) {
        return (
            <EditableEquationsBlock
                block={block}
                setBlock={setBlock}
                moveDownBlock={moveDownBlock}
                moveUpBlock={moveUpBlock}
                deleteBlock={deleteBlock}
                translationsHelper={translationsHelper}
            />
        );
    }

    return null;
};
