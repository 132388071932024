import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT, NULLABLE_IMAGE_FRAGMENT } from './image';

export const IMAGE_TRANSLATION_FRAGMENT = gql`
    fragment ImageTranslation on ImageTranslation {
        _id
        key
        isGlobal
        sectionId
        french {
            ...Image
        }
        english {
            ...NullableImage
        }
    }
    ${IMAGE_FRAGMENT}
    ${NULLABLE_IMAGE_FRAGMENT}
`;
