import { useApolloClient } from '@apollo/client';
import { GetScenarioDetailsQuery, ScenarioLocationOfScenarioFragment } from 'data/generated';
import { GET_SCENARIO_DETAILS_QUERY } from 'data/queries/scenario';

export const useScenarioLocationHandlers = () => {
    const apolloClient = useApolloClient();

    function scenarioLocationAddedHandler({
        scenarioLocation,
        scenarioId,
    }: {
        scenarioLocation: ScenarioLocationOfScenarioFragment;
        scenarioId: string;
    }) {
        const data = apolloClient.readQuery<GetScenarioDetailsQuery>({
            query: GET_SCENARIO_DETAILS_QUERY,
            variables: { scenarioId },
        });

        if (!data) {
            return;
        }

        const { scenario } = data;
        const { scenarioLocations } = scenario;

        apolloClient.writeQuery<GetScenarioDetailsQuery>({
            query: GET_SCENARIO_DETAILS_QUERY,
            variables: { scenarioId },
            data: { scenario: { ...scenario, scenarioLocations: [...scenarioLocations, scenarioLocation] } },
        });
    }

    return { scenarioLocationAddedHandler };
};
