import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaCheck, FaExclamationTriangle, FaPen, FaPlus, FaTimes } from 'react-icons/fa';

import { Button, Card, ColumnsContainer, InfoField, Title } from 'components';
import { FunFactFragment } from 'data/generated';
import { useState } from 'react';
import { ScenarioLocationFormModal } from './ScenarioLocationFormModal';
import { PathPreview } from 'components/PathPreview';
import { GoogleLocation, Location } from 'types/location';
import { TranslationsHelper } from 'hooks/useTranslations';
import { useJsApiLoader } from '@react-google-maps/api';

type ScenarioLocationsDisplayType = {
    _id: string;
    blockingScenarioLocationIds: string[];
    funFacts: FunFactFragment[];
    imageKey: string;
    location: Location;
    title: string;
    numberOfPuzzles: number;
};

function orderScenarioLocationsByBlocking(scenarioLocations: ScenarioLocationsDisplayType[]) {
    let operations = 0;

    const unlockedScenarioLocations = scenarioLocations.filter(
        (scenarioLocation) => scenarioLocation.blockingScenarioLocationIds.length === 0
    );

    const sortedScenarioLocations = [...unlockedScenarioLocations];

    let lockedScenarioLocations = scenarioLocations.filter(
        (scenarioLocation) => scenarioLocation.blockingScenarioLocationIds.length > 0
    );

    while (sortedScenarioLocations.length !== scenarioLocations.length) {
        if (operations > 5000) {
            throw Error('Circular dependency found in blocking scenario location ids causing an infinite loop');
        }

        const sortedScenarioLocationIds = sortedScenarioLocations.map(({ _id }) => _id);

        const newUnlockedScenarioLocations = lockedScenarioLocations.filter((scenarioLocation) =>
            scenarioLocation.blockingScenarioLocationIds.every((scenarioLocationId) =>
                sortedScenarioLocationIds.includes(scenarioLocationId)
            )
        );

        sortedScenarioLocations.push(...newUnlockedScenarioLocations);
        const newUnlockedScenarioLocationIds = newUnlockedScenarioLocations.map(({ _id }) => _id);
        lockedScenarioLocations = lockedScenarioLocations.filter(
            (scenarioLocation) => !newUnlockedScenarioLocationIds.includes(scenarioLocation._id)
        );

        operations++;
    }

    return sortedScenarioLocations;
}

export const ScenarioLocations = ({
    scenarioId,
    scenarioLocations,
    translationsHelper,
}: {
    scenarioId: string;
    scenarioLocations: ScenarioLocationsDisplayType[];
    translationsHelper: TranslationsHelper;
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDSwTo3v5o77PoHFlyMTLgtWBgXVDB_oDY',
    });
    const theme = useTheme();

    const { translationsHook, imageTranslationsHook } = translationsHelper;
    const { translateKey } = translationsHook;
    const { translateImageKey } = imageTranslationsHook;

    const scenarioLocationsOrderedByBlocking = orderScenarioLocationsByBlocking(scenarioLocations);

    const [isScenarioLocationFormModalOpen, setIsScenarioLocationFormModalOpen] = useState(false);

    const scenarioLocationOptions = scenarioLocations.map((scenarioLocation) => ({
        value: scenarioLocation._id,
        label: scenarioLocation.title,
    }));

    const paths = scenarioLocations.reduce<GoogleLocation[][]>((acc, scenarioLocation) => {
        scenarioLocation.blockingScenarioLocationIds.forEach((scenarioLocationId) => {
            const blockingScenario = scenarioLocations.find(
                (scenarioLocation) => scenarioLocation._id === scenarioLocationId
            );

            if (blockingScenario) {
                acc.push([
                    { lat: blockingScenario.location.latitude, lng: blockingScenario.location.longitude },
                    { lat: scenarioLocation.location.latitude, lng: scenarioLocation.location.longitude },
                ]);
            }
        });

        return acc;
    }, []);

    const locations = scenarioLocations.map(({ location }) => location);

    const { minLat, minLng, maxLat, maxLng } = locations.reduce(
        (acc, point) => {
            return {
                minLat: Math.min(acc.minLat, point.latitude),
                minLng: Math.min(acc.minLng, point.longitude),
                maxLat: Math.max(acc.maxLat, point.latitude),
                maxLng: Math.max(acc.maxLng, point.longitude),
            };
        },
        { minLat: 100, minLng: 100, maxLat: 0, maxLng: 0 }
    );

    const coordinatesDelta = 0.0003;

    const initialMapBounds = isLoaded
        ? new google.maps.LatLngBounds(
              new google.maps.LatLng(minLat - coordinatesDelta, minLng - coordinatesDelta),
              new google.maps.LatLng(maxLat + coordinatesDelta, maxLng + coordinatesDelta)
          )
        : undefined;

    return (
        <Card>
            <Title>Scenario Locations</Title>
            <PathPreview paths={paths} />
            <ColumnsContainer numberOfColumns={3}>
                {scenarioLocationsOrderedByBlocking.map((scenarioLocation) => {
                    const { _id: scenarioLocationId, funFacts, imageKey, title, numberOfPuzzles } = scenarioLocation;

                    const image = translateImageKey({ key: imageKey });

                    const isIncomplete = numberOfPuzzles === 0 || funFacts.length === 0;

                    return (
                        <ScenarioLocationPreview key={scenarioLocationId}>
                            <ImageContainer>
                                <Image src={image.url ?? undefined} alt="Lieu de scénario" />
                                {isIncomplete ? (
                                    <IncompleteContainer>
                                        <BadgeContainer>
                                            <FaExclamationTriangle size={20} color={theme.colors.textError} />
                                        </BadgeContainer>
                                    </IncompleteContainer>
                                ) : null}
                            </ImageContainer>
                            <InfoContainer>
                                <InfoHeader>
                                    <ScenarioLocationTitle>{translateKey({ key: title })}</ScenarioLocationTitle>
                                    <Link to={`/scenario/${scenarioId}/scenario-location/${scenarioLocationId}`}>
                                        <Button>
                                            <FaPen />
                                        </Button>
                                    </Link>
                                </InfoHeader>
                                <Row>
                                    {numberOfPuzzles > 0 ? (
                                        <FaCheck size={24} color={theme.colors.textSuccess} />
                                    ) : (
                                        <FaTimes size={24} color={theme.colors.textError} />
                                    )}
                                    <InfoField label="Number of puzzles" value={numberOfPuzzles} isInline />
                                </Row>
                                <Row>
                                    {funFacts.length > 0 ? (
                                        <FaCheck size={24} color={theme.colors.textSuccess} />
                                    ) : (
                                        <FaTimes size={24} color={theme.colors.textError} />
                                    )}
                                    <InfoField label="Number of fun facts" value={funFacts.length} isInline />
                                </Row>
                            </InfoContainer>
                        </ScenarioLocationPreview>
                    );
                })}
                <ScenarioLocationAddContainer onClick={() => setIsScenarioLocationFormModalOpen(true)}>
                    <FaPlus size={40} />
                </ScenarioLocationAddContainer>
            </ColumnsContainer>
            {isScenarioLocationFormModalOpen ? (
                <ScenarioLocationFormModal
                    scenarioId={scenarioId}
                    scenarioLocationOptions={scenarioLocationOptions}
                    initialMapBounds={initialMapBounds}
                    onClose={() => setIsScenarioLocationFormModalOpen(false)}
                />
            ) : null}
        </Card>
    );
};

const ScenarioLocationPreview = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
`;

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 250px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const IncompleteContainer = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
`;

const BadgeContainer = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const InfoContainer = styled.div`
    padding: 0 15px 15px;
`;

const InfoHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ScenarioLocationTitle = styled.h2`
    flex: 1;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const ScenarioLocationAddContainer = styled.button`
    height: 300px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
`;
