import { gql } from '@apollo/client';
import { CONTENT_BLOCK_FRAGMENT } from 'data/fragments/content';
import { QUIZ_FRAGMENT } from './quiz';

export const SCENARIO_FRAGMENT = gql`
    fragment Scenario on Scenario {
        _id
        accessibility
        cityId
        conclusionBlocks {
            ...ContentBlock
        }
        description
        difficulty
        distanceEstimate
        durationEstimate
        endQuiz {
            ...Quiz
        }
        introductionBlocks {
            ...ContentBlock
        }
        imageKey
        meta {
            title
            description
        }
        previewImageKeys
        price
        recommendedCommuteStations {
            description
            commuteLines {
                metroLines
                rerLines
            }
            name
        }
        recommendedHours
        slug
        state
        title
    }
    ${CONTENT_BLOCK_FRAGMENT}
    ${QUIZ_FRAGMENT}
`;
