import { gql } from '@apollo/client';
import { PUZZLE_FRAGMENT } from 'data/fragments/puzzle';

export const GET_SCENARIO_LOCATION_PUZZLES_QUERY = gql`
    query getScenarioLocationPuzzles($scenarioLocationId: ID!) {
        scenarioLocationPuzzles(scenarioLocationId: $scenarioLocationId) {
            ...Puzzle
        }
    }
    ${PUZZLE_FRAGMENT}
`;
