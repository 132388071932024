import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { Button, Label } from 'components';
import { TranslationsHelper } from 'hooks/useTranslations';
import { TranslationInput } from './TranslationInput';

export const NullableTranslationInput = ({
    label,
    sublabel,
    name,
    value,
    onChange,
    disabled = false,
    isTextArea = false,
    translationsHelper,
}: {
    label?: string;
    sublabel?: string;
    name?: string;
    value: string | null;
    onChange: (value: string | null) => void;
    disabled?: boolean;
    isTextArea?: boolean;
    translationsHelper: TranslationsHelper;
}) => {
    const { translationsHook } = translationsHelper;
    const { generateUniqueTranslationKey, deleteTranslation, areTranslationsSaved } = translationsHook;

    return (
        <Container areTranslationsSaved={areTranslationsSaved}>
            {value === null ? (
                <>
                    {label && <Label>{label}</Label>}
                    {sublabel && <Sublabel>{sublabel}</Sublabel>}
                    <Button
                        onClick={() => {
                            onChange(generateUniqueTranslationKey()); // for new translations it's better to not have the value ''. It's better to generate an 'unique' key
                        }}
                    >
                        Ajouter
                    </Button>
                </>
            ) : (
                <>
                    <TranslationInput
                        label={label}
                        sublabel={sublabel}
                        name={name}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        isTextArea={isTextArea}
                        translationsHelper={translationsHelper}
                    />
                    {
                        <Button
                            onClick={() => {
                                deleteTranslation(value);
                                onChange(null);
                            }}
                        >
                            Retirer
                        </Button>
                    }
                </>
            )}
        </Container>
    );
};

const Container = styled.div<{ areTranslationsSaved: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    border: 1px solid
        ${({ areTranslationsSaved }) => (areTranslationsSaved ? Colors.secondary.lightGrey : Colors.secondary.red)};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    padding: 5px;
`;

const Sublabel = styled.span``;
