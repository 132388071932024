import { EditableContentBlocks } from 'components/EditableContentBlocks';
import { LocationSelector, LocationType } from 'components/LocationSelector';
import { PuzzleTemplateInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';
import styled from 'styled-components';

export const HotAndColdPuzzleTemplate = ({
    template,
    setTemplate,
    translationsHelper,
}: {
    template: PuzzleTemplateInput;
    setTemplate: (value: PuzzleTemplateInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { targetLocation, blocks } = template;
    return (
        <Container>
            <Row>
                <LocationSelector
                    allowedTypes={[LocationType.Point]}
                    path={[{ lat: targetLocation?.latitude ?? 0, lng: targetLocation?.longitude ?? 0 }]}
                    setPath={(path) =>
                        setTemplate({
                            ...template,
                            targetLocation: { latitude: path[0].lat, longitude: path[0].lng },
                        })
                    }
                />
            </Row>
            <EditableContentBlocks
                title="Content blocks"
                blocks={blocks ?? []}
                setBlocks={(blocks) => setTemplate({ ...template, blocks })}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.content`,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
`;
