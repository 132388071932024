import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { FaSave } from 'react-icons/fa';
import { DraggableMarker, MarkerType } from './DraggableMarker';

const center = {
    lat: 48.8575943077439,
    lng: 2.341761833549674,
};

export type Path = { lat: number; lng: number }[];

export const MultipleLocationsSelector = ({
    markers,
    newMarker,
    addMarker,
    saveMarkerLocations,
}: {
    markers: MarkerType[];
    newMarker: { lat: number; lng: number } | null;
    addMarker: (location: { lat: number; lng: number }) => void;
    saveMarkerLocations: (markerWithLocations: MarkerType[]) => Promise<void>;
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDSwTo3v5o77PoHFlyMTLgtWBgXVDB_oDY',
    });

    function onClick({ latLng }: google.maps.MapMouseEvent) {
        if (!latLng) {
            return;
        }

        const lat = latLng.lat();
        const lng = latLng.lng();

        addMarker({ lat, lng });
    }

    const [, setMap] = useState<google.maps.Map | null>(null);
    const [modifiedMarkers, setModifiedMarkers] = useState<MarkerType[]>([]);

    const onMapLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map);
    }, []);

    const onMapUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    const updateMarkerPosition = useCallback(
        (identifier: string) => {
            return function (e: google.maps.MapMouseEvent) {
                if (!e.latLng) {
                    return;
                }
                const modifiedMarker = { identifier, location: { lat: e.latLng.lat(), lng: e.latLng.lng() } };
                setModifiedMarkers([
                    ...modifiedMarkers.filter(
                        ({ identifier: modifiedIdentifier }) => modifiedIdentifier !== identifier
                    ),
                    modifiedMarker,
                ]);
            };
        },
        [setModifiedMarkers, modifiedMarkers]
    );

    const updateNewMarkerPosition = useCallback(
        (e: google.maps.MapMouseEvent) => {
            if (!e.latLng) {
                return;
            }
            addMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        },
        [addMarker]
    );

    async function saveModifiedMarkers() {
        await saveMarkerLocations(modifiedMarkers);
        setModifiedMarkers([]);
    }

    const markersToDisplay = markers.map((marker) => {
        const modifiedMarker = modifiedMarkers.find(
            ({ identifier: modifiedIdentifier }) => modifiedIdentifier === marker.identifier
        );
        return modifiedMarker || marker;
    });

    return (
        <Container>
            <MapContainer>
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={center}
                        zoom={12}
                        onClick={onClick}
                        onLoad={onMapLoad}
                        onUnmount={onMapUnmount}
                        options={{ draggableCursor: 'crosshair', gestureHandling: 'cooperative' }}
                    >
                        {markersToDisplay.map((marker) => (
                            <DraggableMarker
                                key={marker.identifier}
                                marker={marker}
                                updateMarkerPosition={updateMarkerPosition}
                            />
                        ))}
                        {newMarker ? (
                            <Marker
                                key="newMarker"
                                position={newMarker}
                                draggable
                                onDragEnd={updateNewMarkerPosition}
                            />
                        ) : null}
                    </GoogleMap>
                ) : (
                    <></>
                )}
            </MapContainer>
            {modifiedMarkers.length > 0 ? (
                <ButtonContainer>
                    <Button onClick={saveModifiedMarkers}>
                        <FaSave />
                    </Button>
                </ButtonContainer>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

const MapContainer = styled.div`
    width: 600px;
    height: 400px;
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
`;

const ButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 60px;
`;
