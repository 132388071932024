import { gql } from '@apollo/client';
import { GAME_LOCATION_FRAGMENT } from './gameLocation';

export const GAME_FRAGMENT = gql`
    fragment Game on Game {
        _id
        completedAt
        createdAt
        gameLocations {
            ...GameLocation
        }
        isInDemoMode
        joinCode
        scenario {
            imageKey
            title
        }
        startedAt
        state
        transaction {
            origin
            priceDetails {
                total
                paid
                discount
            }
        }
        user {
            email
            firstName
            lastName
        }
    }
    ${GAME_LOCATION_FRAGMENT}
`;
