import { Button, Input, Label } from 'components';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { TranslationInput } from 'components/TranslationInput';
import { useImageTranslations } from 'hooks/useImageTranslations';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';

export const TranslationCreator = () => {
    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();

    const [sectionId, setSectionId] = useState<string>('');
    const [keyToCheck, setKeyToCheck] = useState<string>('');
    const [translationKey, setTranslationKey] = useState<string>('');
    const [imageTranslationKey, setImageTranslationKey] = useState<string>('');

    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: '',
        sectionId,
        isGlobal: false,
    };

    return (
        <Container>
            <Label>Section ID</Label>
            <Input onChange={setSectionId} value={sectionId} />
            <Label>Utiliser la clef :</Label>
            <Row>
                <Input onChange={(key) => setKeyToCheck(key)} value={keyToCheck} />
                <Button
                    onClick={() => {
                        setTranslationKey(keyToCheck);
                        setImageTranslationKey(keyToCheck);
                    }}
                >
                    Changer
                </Button>
            </Row>
            <Label>Texte Translation</Label>
            <Row>
                <TranslationInput
                    onChange={(key) => setTranslationKey(key)}
                    translationsHelper={translationsHelper}
                    value={translationKey}
                    isTextArea
                />

                <Button onClick={() => translationsHook.saveTranslations()}>
                    <FaSave />
                </Button>
            </Row>
            <Label>Image Translation</Label>
            <Row>
                <ImageTranslationInput
                    onChange={(key) => setImageTranslationKey(key)}
                    translationsHelper={translationsHelper}
                    value={imageTranslationKey}
                />

                <Button onClick={() => imageTranslationsHook.saveImageTranslations()}>
                    <FaSave />
                </Button>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;
