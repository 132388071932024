import { Input, Label } from 'components';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { SubmitBlockInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';
import { FaPlus, FaTrash } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';

export const ImageAreasSubmitBlock = ({
    submitBlock,
    setSubmitBlock,
    translationsHelper,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const theme = useTheme();
    const { answerCells, imageKey, numberOfColumns, numberOfRows } = submitBlock;

    if (!answerCells || imageKey === undefined || imageKey === null || !numberOfColumns || !numberOfRows) {
        throw Error('Some params are not defined in ImageAreasSubmitBlock');
    }

    return (
        <>
            <ImageTranslationInput
                label="Image"
                onChange={(value) => setSubmitBlock({ ...submitBlock, imageKey: value })}
                value={imageKey}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                }}
            />
            <Input
                label="Number of rows"
                value={numberOfRows}
                type="number"
                onChange={(value) => setSubmitBlock({ ...submitBlock, numberOfRows: Number(value) })}
            />
            <Input
                label="Number of columns"
                value={numberOfColumns}
                type="number"
                onChange={(value) => setSubmitBlock({ ...submitBlock, numberOfColumns: Number(value) })}
            />
            <Container>
                <Label>Answer cells</Label>
                {answerCells.map((cell, index) => (
                    <Row key={answerCells.length + index}>
                        <Input
                            placeholder="Row"
                            value={cell.row}
                            onChange={(newValue: string) =>
                                setSubmitBlock({
                                    ...submitBlock,
                                    answerCells: answerCells.map((prevValue, valueIndex) =>
                                        index === valueIndex ? { ...cell, row: Number(newValue) } : prevValue
                                    ),
                                })
                            }
                        />
                        <Input
                            placeholder="Column"
                            value={cell.column}
                            onChange={(newValue: string) =>
                                setSubmitBlock({
                                    ...submitBlock,
                                    answerCells: answerCells.map((prevValue, valueIndex) =>
                                        index === valueIndex ? { ...cell, column: Number(newValue) } : prevValue
                                    ),
                                })
                            }
                        />
                        <ButtonContainer
                            backgroundColor={theme.colors.backgroundError}
                            onClick={() =>
                                setSubmitBlock({
                                    ...submitBlock,
                                    answerCells: answerCells.filter((_, valueIndex) => index !== valueIndex),
                                })
                            }
                        >
                            <FaTrash color="white" size={13} />
                        </ButtonContainer>
                    </Row>
                ))}
                <Row>
                    <ButtonContainer
                        backgroundColor={theme.colors.backgroundSecondary}
                        onClick={() =>
                            setSubmitBlock({
                                ...submitBlock,
                                answerCells: [...answerCells, { row: 0, column: 0 }],
                            })
                        }
                    >
                        <FaPlus color="white" size={13} />
                    </ButtonContainer>
                </Row>
            </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const ButtonContainer = styled.div<{ backgroundColor: string }>`
    width: 25px;
    height: 25px;
    border-radius: 25px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
