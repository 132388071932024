import { gql } from '@apollo/client';
import { TRANSLATION_FRAGMENT } from 'data/fragments/translation';

export const GET_TRANSLATIONS_QUERY = gql`
    query GetTranslations {
        translations {
            ...Translation
        }
    }
    ${TRANSLATION_FRAGMENT}
`;
