import { gql } from '@apollo/client';

import { CONTENT_BLOCK_FRAGMENT } from './content';
import { SUBMIT_BLOCK_FRAGMENT } from './submitBlock';

const SCORE_TIER = gql`
    fragment ScoreTier on ScoreTier {
        ... on NumberOfTriesScoreTier {
            numberOfTries
            pointsReward
        }
        ... on NumberOfMovesScoreTier {
            numberOfMoves
            pointsReward
        }
        ... on TimedScoreTier {
            elapsedMinutes
            pointsReward
        }
        ... on DefaultScoreTier {
            pointsReward
        }
    }
`;

const PUZZLE_TEMPLATE_FRAGMENT = gql`
    fragment PuzzleTemplate on PuzzleTemplate {
        ... on ContentTemplate {
            type
            blocks {
                ...ContentBlock
            }
        }
        ... on HotAndColdTemplate {
            type
            targetLocation {
                latitude
                longitude
            }
            blocks {
                ...ContentBlock
            }
        }
        ... on NavigateAndFindTemplate {
            type
            imageKey
            locationPoints {
                latitude
                longitude
            }
            blocks {
                ...ContentBlock
            }
        }
    }
`;

export const PUZZLE_FRAGMENT = gql`
    fragment Puzzle on Puzzle {
        _id
        blockingPuzzleIds
        expectedAnswer
        hints
        scoreTiers {
            ...ScoreTier
        }
        state
        submitBlock {
            ...SubmitBlock
        }
        template {
            ...PuzzleTemplate
        }
        title
    }
    ${CONTENT_BLOCK_FRAGMENT}
    ${SCORE_TIER}
    ${SUBMIT_BLOCK_FRAGMENT}
    ${PUZZLE_TEMPLATE_FRAGMENT}
`;
