import styled from 'styled-components';

import { PuzzleFragment, PuzzleState, useGetScenarioLocationPuzzlesQuery } from 'data/generated';

import { Button, FullPageLoader } from 'components';
import { Puzzle } from './Puzzle';
import { CreatePuzzleModal } from 'routes/ScenarioLocations/Puzzles/CreatePuzzleModal';
import { useState } from 'react';

function orderPuzzlesByBlocking(puzzles: PuzzleFragment[]) {
    let index = 0;
    let operations = 0;

    const puzzlesLength = puzzles.length;
    const updatedPuzzles = puzzles.map((item) => ({
        ...item,
    }));

    while (index < puzzlesLength - 1) {
        if (operations > 5000) {
            throw Error('Circular dependency found in blocking scenario location ids causing an infinite loop');
        }

        if (updatedPuzzles[index].blockingPuzzleIds.includes(updatedPuzzles[index + 1]._id)) {
            const swap = { ...updatedPuzzles[index] };
            updatedPuzzles[index] = {
                ...updatedPuzzles[index + 1],
            };
            updatedPuzzles[index + 1] = swap;
            index = 0;
            operations++;
            continue;
        }
        operations++;
        index++;
    }

    return updatedPuzzles;
}

export const Puzzles = ({ scenarioId, scenarioLocationId }: { scenarioId: string; scenarioLocationId: string }) => {
    const { data, error } = useGetScenarioLocationPuzzlesQuery({ variables: { scenarioLocationId } });
    const [createPuzzleModalOpen, setCreatePuzzleModalOpen] = useState<boolean>(false);

    if (error) {
        return <>{error?.message || 'Error fetching scenario'}</>;
    }

    if (!data) {
        return <FullPageLoader />;
    }

    const { scenarioLocationPuzzles } = data;

    const regularPuzzles = scenarioLocationPuzzles.filter((puzzle) => puzzle.state === PuzzleState.Regular);

    const orderedPuzzles = orderPuzzlesByBlocking(regularPuzzles);
    const allScenarioLocationPuzzleOptions = scenarioLocationPuzzles.map(({ _id, title }) => ({
        value: _id,
        label: title,
    }));

    return (
        <PuzzlesContainer>
            <ButtonContainer>
                <Button onClick={() => setCreatePuzzleModalOpen(true)}>Create a puzzle</Button>
            </ButtonContainer>

            {orderedPuzzles.map((puzzle, index) => {
                const scenarioLocationPuzzleOptions = allScenarioLocationPuzzleOptions.filter(
                    ({ value }) => value !== puzzle._id
                );

                return (
                    <Puzzle
                        key={puzzle._id}
                        puzzle={puzzle}
                        index={index}
                        scenarioLocationPuzzleOptions={scenarioLocationPuzzleOptions}
                        scenarioId={scenarioId}
                    />
                );
            })}
            {createPuzzleModalOpen ? (
                <CreatePuzzleModal
                    scenarioLocationId={scenarioLocationId}
                    scenarioLocationPuzzleOptions={allScenarioLocationPuzzleOptions}
                    onClose={() => setCreatePuzzleModalOpen(false)}
                    newPuzzleIndex={orderedPuzzles.length}
                    scenarioId={scenarioId}
                />
            ) : null}
        </PuzzlesContainer>
    );
};

const PuzzlesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
`;
