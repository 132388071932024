import { ScrollContainer, Title } from 'components';
import { ImageToThumbhash } from './ImageToThumbhash';
import { ImageUrlToThumbhash } from './ImageUrlToThumbhash';
import styled from 'styled-components';
import { TranslationViewer } from './TranslationViewer';
import { TranslationCreator } from './TranslationCreator';

export const Tools = () => {
    return (
        <ScrollContainer>
            <Title>Thumbhash Generator</Title>
            <Row>
                <ToolContainer>
                    <ImageToThumbhash />
                </ToolContainer>
                <ToolContainer>
                    <ImageUrlToThumbhash />
                </ToolContainer>
            </Row>
            <Row>
                <ToolContainer>
                    <TranslationCreator />
                </ToolContainer>
            </Row>
            <Row>
                <ToolContainer>
                    <TranslationViewer />
                </ToolContainer>
            </Row>
        </ScrollContainer>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const ToolContainer = styled.div`
    flex: 1;
    border-radius: 5px;
    border: 1px solid grey;
    padding: 10px;
`;
