import styled, { useTheme } from 'styled-components';

import { SubmitBlockInput } from 'data/generated';
import { Input, Label } from 'components';
import { TranslationInput } from 'components/TranslationInput';
import { TranslationsHelper } from 'hooks/useTranslations';
import { FaPlus, FaTrash } from 'react-icons/fa';

export const AugmentedRealitySubmitBlock = ({
    submitBlock,
    setSubmitBlock,
    translationsHelper,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const theme = useTheme();

    const { anchorImages } = submitBlock;

    if (!anchorImages) {
        throw Error('anchorImages is missing in AugmentedReality block');
    }

    return (
        <Container>
            <Label>Augmented Reality</Label>
            {anchorImages.map((anchorImage, anchorImageIndex) => (
                <Row key={anchorImages.length + anchorImageIndex}>
                    <AnchorImageContainer>
                        <Label>Anchor image N°{anchorImageIndex + 1}</Label>
                        <Input
                            label="Url"
                            value={anchorImage.url}
                            onChange={(newValue: string) =>
                                setSubmitBlock({
                                    ...submitBlock,
                                    anchorImages: anchorImages.map((anchorImage, index) =>
                                        index !== anchorImageIndex ? anchorImage : { ...anchorImage, url: newValue }
                                    ),
                                })
                            }
                        />
                        <Input
                            label="Name"
                            sublabel="Used to identify objects in Augmented Reality (not seen by user)"
                            placeholder="targetOne"
                            value={anchorImage.name}
                            onChange={(newValue: string) =>
                                setSubmitBlock({
                                    ...submitBlock,
                                    anchorImages: anchorImages.map((anchorImage, index) =>
                                        index !== anchorImageIndex ? anchorImage : { ...anchorImage, name: newValue }
                                    ),
                                })
                            }
                        />
                        <TranslationInput
                            label="Hidden message"
                            sublabel="The message to display when the user scans the anchor image"
                            value={anchorImage.hiddenMessage}
                            onChange={(newValue: string) =>
                                setSubmitBlock({
                                    ...submitBlock,
                                    anchorImages: anchorImages.map((anchorImage, index) =>
                                        index !== anchorImageIndex
                                            ? anchorImage
                                            : { ...anchorImage, hiddenMessage: newValue }
                                    ),
                                })
                            }
                            translationsHelper={{
                                ...translationsHelper,
                                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${anchorImageIndex}.hiddenMessage`,
                            }}
                        />
                        <Input
                            label="Physical width"
                            sublabel='In meter. For example "0.1" for 10 centimeters'
                            value={anchorImage.physicalWidth}
                            type="numerical"
                            onChange={(newValue: string) =>
                                setSubmitBlock({
                                    ...submitBlock,
                                    anchorImages: anchorImages.map((anchorImage, index) =>
                                        index !== anchorImageIndex
                                            ? anchorImage
                                            : { ...anchorImage, physicalWidth: Number(newValue) }
                                    ),
                                })
                            }
                        />
                    </AnchorImageContainer>
                    <ButtonContainer
                        backgroundColor={theme.colors.backgroundError}
                        onClick={() =>
                            setSubmitBlock({
                                ...submitBlock,
                                anchorImages: anchorImages.filter((_, valueIndex) => valueIndex !== anchorImageIndex),
                            })
                        }
                    >
                        <FaTrash color="white" size={12} />
                    </ButtonContainer>
                </Row>
            ))}
            <Row>
                <ButtonContainer
                    backgroundColor={theme.colors.backgroundSecondary}
                    onClick={() =>
                        setSubmitBlock({
                            ...submitBlock,
                            anchorImages: [
                                ...anchorImages,
                                { url: '', physicalWidth: 1, name: 'targetN', hiddenMessage: '' },
                            ],
                        })
                    }
                >
                    <FaPlus color="white" size={12} />
                </ButtonContainer>
            </Row>
        </Container>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const AnchorImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgrey;
`;

const ButtonContainer = styled.div<{ backgroundColor: string }>`
    width: 25px;
    height: 25px;
    border-radius: 13px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
