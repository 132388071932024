import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Column = ({ children }: PropsWithChildren) => {
    return <StyledColumn>{children}</StyledColumn>;
};

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;
