import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { SubmitBlockInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';

export const HideAndSeekSubmitBlock = ({
    submitBlock,
    setSubmitBlock,
    translationsHelper,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { hidingImageKey, hiddenImageKey } = submitBlock;

    if (
        hidingImageKey === undefined ||
        hidingImageKey === null ||
        hiddenImageKey === undefined ||
        hiddenImageKey === null
    ) {
        throw Error('Some params are not defined in HideAndSeekSubmitBlock');
    }

    return (
        <>
            <ImageTranslationInput
                label="Hiding Image"
                sublabel="Image that will be displayed on top of the hidden image"
                onChange={(value) => setSubmitBlock({ ...submitBlock, hidingImageKey: value })}
                value={hidingImageKey}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.hiding.image`,
                }}
            />
            <ImageTranslationInput
                label="Hidden Image"
                sublabel="Image that will be revealed when the user touch the screen"
                onChange={(value) => setSubmitBlock({ ...submitBlock, hiddenImageKey: value })}
                value={hiddenImageKey}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.hidden.image`,
                }}
            />
        </>
    );
};
