import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { useCallback, useState } from 'react';
import '@mdxeditor/editor/style.css';
import {
    MDXEditor,
    toolbarPlugin,
    headingsPlugin,
    listsPlugin,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    InsertTable,
    ListsToggle,
    UndoRedo,
    markdownShortcutPlugin,
    linkDialogPlugin,
    tablePlugin,
    linkPlugin,
    DiffSourceToggleWrapper,
    diffSourcePlugin,
} from '@mdxeditor/editor';
import { toast } from 'react-toastify';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';

export const MarkdownTextArea = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
    const { useMarkdownEditor } = useTranslationParameters();

    const [isTextAreaFocused, setIsTextAreaFocused] = useState<boolean>(false);

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLDivElement, Element>) => {
            const currentTarget = e.currentTarget;

            // Give browser time to focus the next element
            requestAnimationFrame(() => {
                // Check if the new focused element is a child of the original container

                const mdxEditorPopupContainer = document.getElementsByClassName('mdxeditor-popup-container');

                if (mdxEditorPopupContainer?.[0]?.contains(document.activeElement)) {
                    return;
                }

                if (!currentTarget.contains(document.activeElement)) {
                    setIsTextAreaFocused(false);
                }
            });
        },
        [setIsTextAreaFocused]
    );

    return (
        <Container onBlur={handleBlur} onFocus={() => setIsTextAreaFocused(true)}>
            {isTextAreaFocused && useMarkdownEditor ? (
                <MDXEditor
                    markdown={value}
                    onChange={(markdown) => {
                        if (markdown !== value) {
                            onChange(markdown);
                        }
                    }}
                    onError={({ error }) => toast.warn(error)}
                    plugins={[
                        toolbarPlugin({
                            toolbarContents: () => (
                                <DiffSourceToggleWrapper>
                                    <UndoRedo />

                                    <BlockTypeSelect />
                                    <BoldItalicUnderlineToggles />
                                    <CreateLink />
                                    <InsertTable />
                                    <ListsToggle />
                                </DiffSourceToggleWrapper>
                            ),
                        }),
                        markdownShortcutPlugin(),
                        linkPlugin(),
                        linkDialogPlugin(),
                        tablePlugin(),
                        headingsPlugin(),
                        listsPlugin(),
                        diffSourcePlugin(),
                    ]}
                    autoFocus={{ defaultSelection: 'rootEnd' }}
                />
            ) : (
                <StyledTextArea value={value} rows={6} onChange={(e) => onChange(e.target.value)} />
            )}
        </Container>
    );
};

const Container = styled.div`
    font-size: 16px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border: 1px solid ${Colors.secondary.lightGrey};
    width: 100%;
`;

const StyledTextArea = styled.textarea`
    font-size: 16px;
    padding: 10px;
    border: none;
    :focus {
        outline: none;
    }
    width: 100%;
`;
