import { EditableContentBlocks } from 'components/EditableContentBlocks';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { LocationSelector, LocationType } from 'components/LocationSelector';
import { PuzzleTemplateInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';
import styled from 'styled-components';

export const NavigateAndFindPuzzle = ({
    template,
    setTemplate,
    translationsHelper,
}: {
    template: PuzzleTemplateInput;
    setTemplate: (template: PuzzleTemplateInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { imageKey, locationPoints, blocks } = template;

    return (
        <Container>
            <Row>
                <LocationSelector
                    allowedTypes={[LocationType.Polygon]}
                    path={(locationPoints ?? []).map(({ latitude, longitude }) => ({ lat: latitude, lng: longitude }))}
                    setPath={(path) =>
                        setTemplate({
                            ...template,
                            locationPoints: path.map(({ lat: latitude, lng: longitude }) => ({ latitude, longitude })),
                        })
                    }
                />
                <ImageTranslationInput
                    label="Image to find"
                    onChange={(value) => setTemplate({ ...template, imageKey: value })}
                    value={imageKey ?? ''}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                    }}
                />
            </Row>
            <EditableContentBlocks
                title="Content blocks"
                blocks={blocks ?? []}
                setBlocks={(blocks) => setTemplate({ ...template, blocks })}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.content`,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
`;
