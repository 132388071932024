import { useState } from 'react';
import styled from 'styled-components';

import {
    PuzzleFragment,
    PuzzleTemplateInput,
    SubmitBlockInput,
    useArchivePuzzleMutation,
    useUpdatePuzzleMutation,
} from 'data/generated';

import { Button, InfoField } from 'components';
import { SelectorOption } from 'components/Selector';
import { TemplateSection } from 'routes/ScenarioLocations/Puzzles/TemplateSection';
import { FaSave, FaTrash } from 'react-icons/fa';
import { PuzzleInfo } from 'routes/ScenarioLocations/Puzzles/PuzzleInfo';
import { toast } from 'react-toastify';
import { removeFieldFromObject } from 'routes/utils';
import { PuzzleInput } from './CreatePuzzleModal';
import { Colors } from 'styles/colors';
import { useTranslations } from 'hooks/useTranslations';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { useImageTranslations } from 'hooks/useImageTranslations';

export const Puzzle = ({
    puzzle,
    scenarioLocationPuzzleOptions,
    scenarioId,
    index,
}: {
    puzzle: PuzzleFragment;
    scenarioLocationPuzzleOptions: SelectorOption<string>[];
    scenarioId: string;
    index: number;
}) => {
    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.location.${keyPrefixLocation}.puzzles.${index}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [puzzleForm, setPuzzleForm] = useState<PuzzleInput>({
        blockingPuzzleIds: puzzle.blockingPuzzleIds,
        expectedAnswer: puzzle.expectedAnswer ?? null,
        hints: puzzle.hints,
        scoreTiers: [],
        submitBlock: removeFieldFromObject<SubmitBlockInput>({ ...puzzle.submitBlock }, '__typename'),
        template: removeFieldFromObject<PuzzleTemplateInput>({ ...puzzle.template }, '__typename'),
        title: puzzle.title,
    });

    const [updatePuzzleMutation, { loading: updateLoading }] = useUpdatePuzzleMutation();
    const [archivePuzzleMutation, { loading: archiveLoading }] = useArchivePuzzleMutation();

    const { _id: puzzleId } = puzzle;

    async function onSubmit() {
        if (updateLoading) {
            return;
        }

        const { data } = await updatePuzzleMutation({
            variables: {
                puzzleId,
                updateInput: puzzleForm,
            },
        });
        if (data) {
            const {
                updatePuzzle: { puzzle, errors },
            } = data;

            if (errors) {
                errors.map((error) => toast.error(error, { autoClose: 5000 }));
            }

            if (puzzle) {
                toast.success('Puzzle has been updated', { autoClose: 3000 });

                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
            }
        }
    }

    async function archivePuzzle() {
        if (window.confirm('Voulez vous archiver le puzzle ?')) {
            await archivePuzzleMutation({ variables: { puzzleId } });
        }
    }

    return (
        <Container>
            <Content>
                <Row>
                    <HeaderSubtitle>Puzzle No {index + 1}</HeaderSubtitle>
                    <Button onClick={archivePuzzle} backgroundColor={Colors.secondary.red} disabled={archiveLoading}>
                        <FaTrash />
                    </Button>
                    <Button onClick={onSubmit} disabled={updateLoading}>
                        <FaSave />
                    </Button>
                </Row>
                <InfoField label="Id" value={puzzle._id} />
                <PuzzleInfo
                    puzzleForm={puzzleForm}
                    setPuzzleForm={setPuzzleForm}
                    scenarioLocationPuzzleOptions={scenarioLocationPuzzleOptions}
                    translationsHelper={translationsHelper}
                />
                <TemplateSection
                    template={puzzleForm.template}
                    setTemplate={(template) => setPuzzleForm({ ...puzzleForm, template })}
                    translationsHelper={translationsHelper}
                />
            </Content>
            <SaveAside onClick={onSubmit} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px;
    gap: 10px;
`;

const SaveAside = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 10px;
    border-radius: 0 ${({ theme }) => theme.borderRadius.md}px ${({ theme }) => theme.borderRadius.md}px 0;
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;

const Row = styled.div`
    display: flex;
    gap: 10px;
`;

const HeaderSubtitle = styled.div`
    flex: 1;
    margin-bottom: 5px;
    font-size: 16px;
    font-style: italic;
`;
