import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CustomLink = styled(Link)`
    color: ${({ theme }) => theme.colors.textPrimary};
    text-decoration: none;
    padding: 5px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;

    &:hover {
        background-color: #dddddd;
    }
`;
