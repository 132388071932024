import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Modal = ({
    children,
    onClose,
    minHeight,
}: PropsWithChildren & { onClose: () => void; minHeight?: number }) => {
    return (
        <ModalContainer onClick={onClose}>
            <ContentContainer onClick={(e) => e.stopPropagation()} minHeight={minHeight}>
                {children}
            </ContentContainer>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContentContainer = styled.div<{ minHeight?: number }>`
    padding: 15px;
    border-radius: ${({ theme }) => theme.borderRadius.xl}px;
    background-color: white;
    max-height: 95%;
    overflow: scroll;
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
`;
