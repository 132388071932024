import styled, { useTheme } from 'styled-components';
import { Input } from './Input';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Label } from './Label';

export const NumericArrayInput = ({
    label,
    values,
    setValues,
    buttonSize = 50,
}: {
    label?: string;
    values: number[];
    setValues: (values: number[]) => void;
    buttonSize?: number;
}) => {
    const theme = useTheme();

    return (
        <Container>
            {label ? <Label>{label}</Label> : null}
            {values.map((value, index) => (
                <Row key={values.length + index}>
                    <Input
                        type="number"
                        value={value}
                        onChange={(newValue) => {
                            setValues(
                                values.map((prevValue, valueIndex) =>
                                    index === valueIndex ? Number(newValue) : prevValue
                                )
                            );
                        }}
                    />
                    <ButtonContainer
                        backgroundColor={theme.colors.backgroundError}
                        onClick={() => setValues(values.filter((_value, valueIndex) => valueIndex !== index))}
                        buttonSize={buttonSize}
                    >
                        <FaTrash color="white" size={buttonSize / 2} />
                    </ButtonContainer>
                </Row>
            ))}
            <Row>
                <ButtonContainer
                    backgroundColor={theme.colors.backgroundSecondary}
                    onClick={() => setValues([...values, 0])}
                    buttonSize={buttonSize}
                >
                    <FaPlus color="white" size={buttonSize / 2} />
                </ButtonContainer>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const ButtonContainer = styled.div<{ backgroundColor: string; buttonSize: number }>`
    width: ${({ buttonSize }) => buttonSize}px;
    height: ${({ buttonSize }) => buttonSize}px;
    border-radius: ${({ buttonSize }) => buttonSize / 2}px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
