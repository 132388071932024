import { ContentBlockInput } from 'data/generated';
import { ContentBlock } from './ContentBlock';
import styled from 'styled-components';
import { TranslationsHelper } from 'hooks/useTranslations';

export const ContentBlocks = ({
    blocks,
    translationsHelper,
}: {
    blocks: ContentBlockInput[];
    translationsHelper: TranslationsHelper;
}) => {
    return (
        <BlocksContainer>
            {blocks.map((block, index) => (
                <ContentBlock key={index} block={block} translationsHelper={translationsHelper} />
            ))}
        </BlocksContainer>
    );
};

const BlocksContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
