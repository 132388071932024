import { ArrayInput, Input, NumericArrayInput, OptionsArrayInput, Selector } from 'components';
import { isSelectorOptionSingleValue } from 'components/Selector';
import { KeyboardType, SubmitBlockInput, SubmitBlockType } from 'data/generated';
import { ImageAreasSubmitBlock } from 'routes/ScenarioLocations/Puzzles/ImageAreasSubmitBlock';
import { MusicSheetSubmitBlock } from 'routes/ScenarioLocations/Puzzles/MusicSheetSubmitBlock';
import {
    getInitialSubmitBlockForType,
    isAugmentedRealitySubmitBlock,
    isAutoPlayingSoundSubmitBlock,
    isButtonSubmitBlock,
    isCameraLayerPuzzleTemplate,
    isCarouselSubmitBlock,
    isCesarCodeSubmitBlock,
    isColorLettersSubmitBlock,
    isCombinationPadlockSubmitBlock,
    isDigicodeSubmitBlock,
    isFourDolphinsSubmitBlock,
    isGalerieColbertSubmitBlock,
    isHideAndSeekSubmitBlock,
    isImageAreasSubmitBlock,
    isImagesOrderingSubmitBlock,
    isImagesSelectionSubmitBlock,
    isImagesSortingSubmitBlock,
    isInputSubmitBlock,
    isLightBulbsSubmitBlock,
    isMorseCodeSubmitBlock,
    isMultiSelectSubmitBlock,
    isMusicSheetSubmitBlock,
    isPavingSubmitBlock,
    isPhotoPuzzleSubmitBlock,
    isRotondeSubmitBlock,
    isSelectSubmitBlock,
    isSimonSaysSubmitBlock,
    isSkylineSubmitBlock,
    isSundialSubmitBlock,
    isWineCellarSubmitBlock,
} from 'routes/ScenarioLocations/Puzzles/utils';
import { ColorLettersSubmitBlock } from './ColorLettersSubmitBlock';
import { ImagesOrderingSubmitBlock } from './ImageOrderingSubmitBlock';
import { ImagesSelectionSubmitBlock } from './ImageSelectionSubmitBlock';
import { ImagesSortingSubmitBlock } from './ImageSortingSubmitBlock';
import { TranslationsHelper } from 'hooks/useTranslations';
import { TranslationInput } from 'components/TranslationInput';
import { TranslationArrayInput } from 'components/TranslationArrayInput';
import { CesarCodeSubmitBlock } from './CesarCodeSubmitBlock';
import { AugmentedRealitySubmitBlock } from './AugmentedRealitySubmitBlock';
import { HideAndSeekSubmitBlock } from './HideAndSeekSubmitBlock';
import { PavingSubmitBlock } from './PavingSubmitBlock';
import { PhotoPuzzleSubmitBlock } from './PhotoPuzzleSubmitBlock';

export const SubmitSection = ({
    submitBlock,
    setSubmitBlock,
    translationsHelper,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const submitTypeOptions = Object.keys(SubmitBlockType).map((key) => ({
        value: key as SubmitBlockType,
        label: key,
    }));

    const keyboardTypeOptions = Object.keys(KeyboardType).map((key) => ({
        value: key as KeyboardType,
        label: key,
    }));

    return (
        <>
            <Selector
                label="Submit type"
                value={submitBlock?.type}
                options={submitTypeOptions}
                onChange={(option) =>
                    isSelectorOptionSingleValue(option) && option !== null
                        ? setSubmitBlock(getInitialSubmitBlockForType(option.value))
                        : undefined
                }
            />
            {isAugmentedRealitySubmitBlock(submitBlock) ? (
                <AugmentedRealitySubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isButtonSubmitBlock(submitBlock) ? (
                <TranslationInput
                    label="Button label"
                    value={submitBlock.label ?? ''}
                    onChange={(value) => setSubmitBlock({ ...submitBlock, label: value })}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.button`,
                    }}
                />
            ) : isCameraLayerPuzzleTemplate(submitBlock) ? (
                <Input
                    label="Layer image url"
                    value={submitBlock.layerImageUrl ?? ''}
                    onChange={(value) => setSubmitBlock({ ...submitBlock, layerImageUrl: value })}
                />
            ) : isCarouselSubmitBlock(submitBlock) ? (
                <>
                    <Input
                        label="Answer image url"
                        value={submitBlock.answerImageUrl ?? ''}
                        onChange={(value) => setSubmitBlock({ ...submitBlock, answerImageUrl: value })}
                    />
                    <Input
                        label="Images size"
                        value={submitBlock.imagesSize ?? 0}
                        type="number"
                        onChange={(value) => {
                            setSubmitBlock({ ...submitBlock, imagesSize: Number(value) });
                        }}
                    />
                    <ArrayInput
                        label="Wrong image urls"
                        values={submitBlock.wrongImageUrls ?? []}
                        setValues={(array) => setSubmitBlock({ ...submitBlock, wrongImageUrls: array })}
                    />
                </>
            ) : isCesarCodeSubmitBlock(submitBlock) ? (
                <CesarCodeSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isColorLettersSubmitBlock(submitBlock) ? (
                <ColorLettersSubmitBlock submitBlock={submitBlock} setSubmitBlock={setSubmitBlock} />
            ) : isCombinationPadlockSubmitBlock(submitBlock) || isDigicodeSubmitBlock(submitBlock) ? (
                <span>No additional fields, fill the expected answer field</span>
            ) : isHideAndSeekSubmitBlock(submitBlock) ? (
                <HideAndSeekSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isImageAreasSubmitBlock(submitBlock) ? (
                <ImageAreasSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isImagesOrderingSubmitBlock(submitBlock) ? (
                <ImagesOrderingSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isImagesSelectionSubmitBlock(submitBlock) ? (
                <ImagesSelectionSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isImagesSortingSubmitBlock(submitBlock) ? (
                <ImagesSortingSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isInputSubmitBlock(submitBlock) ? (
                <>
                    <Selector
                        label="Keyboard type"
                        value={submitBlock.keyboardType}
                        options={keyboardTypeOptions}
                        onChange={(option) =>
                            isSelectorOptionSingleValue(option) && option !== null
                                ? setSubmitBlock({ ...submitBlock, keyboardType: option.value })
                                : undefined
                        }
                    />
                    <TranslationInput
                        label="Input label (optional)"
                        value={submitBlock.inputLabel ?? ''}
                        onChange={(value) => setSubmitBlock({ ...submitBlock, inputLabel: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.label`,
                        }}
                    />
                    <TranslationInput
                        label="Placeholder (optional)"
                        value={submitBlock.placeholder ?? ''}
                        onChange={(value) => setSubmitBlock({ ...submitBlock, placeholder: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.placeholder`,
                        }}
                    />
                </>
            ) : isLightBulbsSubmitBlock(submitBlock) ? (
                <>
                    <Input
                        label="Image on url"
                        value={submitBlock.imageOnUrl ?? ''}
                        onChange={(value) => setSubmitBlock({ ...submitBlock, imageOnUrl: value })}
                    />
                    <Input
                        label="Image off url"
                        value={submitBlock.imageOffUrl ?? ''}
                        onChange={(value) => setSubmitBlock({ ...submitBlock, imageOffUrl: value })}
                    />
                </>
            ) : isMorseCodeSubmitBlock(submitBlock) ? (
                <Input
                    label="Code"
                    value={submitBlock.code ?? ''}
                    onChange={(value) => setSubmitBlock({ ...submitBlock, code: value })}
                />
            ) : isMultiSelectSubmitBlock(submitBlock) ? (
                <>
                    <TranslationArrayInput
                        label="Option labels"
                        values={submitBlock.optionLabels ?? []}
                        setValues={(array) => setSubmitBlock({ ...submitBlock, optionLabels: array })}
                        translationsHelper={translationsHelper}
                    />
                    <ArrayInput
                        label="Answer indices"
                        values={(submitBlock.answerIndices ?? []).map((value) => value.toString())}
                        setValues={(array) =>
                            setSubmitBlock({
                                ...submitBlock,
                                answerIndices: array.map(Number),
                            })
                        }
                    />
                </>
            ) : isMusicSheetSubmitBlock(submitBlock) ? (
                <MusicSheetSubmitBlock submitBlock={submitBlock} setSubmitBlock={setSubmitBlock} />
            ) : isPavingSubmitBlock(submitBlock) ? (
                <PavingSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isPhotoPuzzleSubmitBlock(submitBlock) ? (
                <PhotoPuzzleSubmitBlock
                    submitBlock={submitBlock}
                    setSubmitBlock={setSubmitBlock}
                    translationsHelper={translationsHelper}
                />
            ) : isRotondeSubmitBlock(submitBlock) ? (
                <span>No additional fields, fill the expected answer field with "1"</span>
            ) : isSelectSubmitBlock(submitBlock) ? (
                <OptionsArrayInput
                    label="Answer options"
                    values={submitBlock.options ?? []}
                    setValues={(array) => setSubmitBlock({ ...submitBlock, options: array })}
                    buttonSize={25}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.anser_options`,
                    }}
                />
            ) : isSimonSaysSubmitBlock(submitBlock) ? (
                <NumericArrayInput
                    label="Sequence"
                    values={submitBlock.sequence ?? []}
                    setValues={(array) => setSubmitBlock({ ...submitBlock, sequence: array })}
                    buttonSize={25}
                />
            ) : isSkylineSubmitBlock(submitBlock) ? (
                <span>Not implemented, you mignt need to update the database and even the code to make it work.</span>
            ) : isAutoPlayingSoundSubmitBlock(submitBlock) ||
              isFourDolphinsSubmitBlock(submitBlock) ||
              isGalerieColbertSubmitBlock(submitBlock) ||
              isSundialSubmitBlock(submitBlock) ||
              isWineCellarSubmitBlock(submitBlock) ? (
                <span>No additional fields, fill the expected answer field with "1"</span>
            ) : null}
        </>
    );
};
