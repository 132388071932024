const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function getWeekday(date: Date) {
    return WEEKDAYS[date.getDay()];
}

export function getFormattedDateAndTime(date: Date, options?: { showWeekday: boolean }) {
    return `${date.toLocaleDateString('fr-FR')} ${
        options?.showWeekday ? `(${getWeekday(date)}) ` : ''
    }at ${date.toLocaleTimeString('fr-FR')}`;
}
