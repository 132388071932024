import { Input, Label } from 'components';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { SubmitBlockInput } from 'data/generated';
import { TranslationsHelper } from 'hooks/useTranslations';
import { FaPlus, FaTrash } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';

export const ImagesSortingSubmitBlock = ({
    submitBlock,
    setSubmitBlock,
    translationsHelper,
}: {
    submitBlock: SubmitBlockInput;
    setSubmitBlock: (value: SubmitBlockInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const theme = useTheme();

    const { imageTranslationsHook } = translationsHelper;
    const { deleteImageTranslation } = imageTranslationsHook;

    const { imageItems } = submitBlock;

    if (!imageItems) {
        throw Error('imageItems is not defined in ImagesSortingSubmitBlock');
    }

    return (
        <Container>
            <Label>Images</Label>
            {imageItems.map(({ imageKey, position }, index) => {
                return (
                    <InputWithImage key={imageItems.length + index}>
                        <InputsContainer>
                            <ImageTranslationInput
                                label="Image"
                                onChange={(newImageKey) =>
                                    setSubmitBlock({
                                        ...submitBlock,
                                        imageItems: imageItems.map((imageItem, imageItemIndex) =>
                                            index === imageItemIndex
                                                ? { ...imageItem, imageKey: newImageKey }
                                                : imageItem
                                        ),
                                    })
                                }
                                value={imageKey}
                                translationsHelper={{
                                    ...translationsHelper,
                                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${index}.image`,
                                }}
                            />

                            {position || position === 0 ? (
                                <Input
                                    onChange={(newValue) =>
                                        setSubmitBlock({
                                            ...submitBlock,
                                            imageItems: imageItems.map((imageItem, imageItemIndex) =>
                                                index === imageItemIndex
                                                    ? { ...imageItem, position: parseInt(newValue) }
                                                    : imageItem
                                            ),
                                        })
                                    }
                                    value={position}
                                    type="number"
                                    label="Position"
                                />
                            ) : null}
                        </InputsContainer>
                        <ButtonContainer
                            backgroundColor={theme.colors.backgroundError}
                            onClick={() => {
                                setSubmitBlock({
                                    ...submitBlock,
                                    imageItems: imageItems.filter((_, imageItemIndex) => imageItemIndex !== index),
                                });
                                deleteImageTranslation(imageKey);
                            }}
                        >
                            <FaTrash color="white" />
                        </ButtonContainer>
                    </InputWithImage>
                );
            })}
            <Row>
                <ButtonContainer
                    backgroundColor={theme.colors.backgroundSecondary}
                    onClick={() =>
                        setSubmitBlock({
                            ...submitBlock,
                            imageItems: [
                                ...imageItems,
                                {
                                    imageKey: '',
                                    position: imageItems.length,
                                },
                            ],
                        })
                    }
                >
                    <FaPlus color="white" />
                </ButtonContainer>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const InputWithImage = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-left: 7px solid #222222;
    border-radius: 7px;
    padding-left: 5px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const ButtonContainer = styled.div<{ backgroundColor: string }>`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
