import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { Label } from 'components';

export const Input = ({
    label,
    sublabel,
    name,
    value,
    onChange,
    type = 'text',
    disabled = false,
    placeholder,
}: {
    label?: string;
    sublabel?: string;
    name?: string;
    value: string | number;
    onChange: (value: string) => void;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
}) => {
    return (
        <Container>
            {label && <Label>{label}</Label>}
            {sublabel && <Sublabel>{sublabel}</Sublabel>}

            <InputContainer>
                <StyledInput
                    value={value}
                    name={name}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabled}
                    type={type}
                    placeholder={placeholder}
                />
            </InputContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
`;

const Sublabel = styled.span``;

const StyledInput = styled.input`
    width: 100%;
    flex-grow: 1;
    font-size: 16px;
    padding: 10px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border: 1px solid ${Colors.secondary.lightGrey};
    :focus {
        outline: none;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
`;
