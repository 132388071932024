import { TableView, FullPageLoader, ScrollContainer, Title } from 'components';
import { useGetScenariosQuery } from 'data/generated';
import styled from 'styled-components';
import { ScenarioStateTag } from './ScenarioStateTag';
import { displayPrice } from 'utils/price';
import { useTranslations } from 'hooks/useTranslations';
import { useImageTranslations } from 'hooks/useImageTranslations';

export const Scenarios = () => {
    const { data, loading, error } = useGetScenariosQuery();
    const { translateKey } = useTranslations();
    const { translateImageKey } = useImageTranslations();

    if (loading) {
        return <FullPageLoader />;
    }

    if (error || !data) {
        return <>{error?.message}</>;
    }

    const SCENARIO_FIELDS = [
        { name: '_id', label: 'ID' },
        { name: 'image', label: 'Image' },
        { name: 'price', label: 'Price' },
        { name: 'title', label: 'Title' },
        { name: 'state', label: 'State' },
    ];

    const { scenarios } = data;

    const scenarioEntries = scenarios.map(({ _id, imageKey, price, state, title }) => {
        const { url } = translateImageKey({ key: imageKey });

        return {
            _id,
            image: (
                <ImageContainer>
                    <img
                        src={url ?? undefined}
                        alt={title}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </ImageContainer>
            ),
            price: displayPrice(price),
            state: <ScenarioStateTag state={state} />,
            title: (
                <Title>
                    {translateKey({
                        key: title,
                    })}
                </Title>
            ),
        };
    });

    return (
        <ScrollContainer>
            <TableView fields={SCENARIO_FIELDS} entries={scenarioEntries} path="/scenario/" />
        </ScrollContainer>
    );
};

const ImageContainer = styled.div`
    height: 150px;
    aspect-ratio: 3;
`;
