import styled from 'styled-components';
import { Button, Input, Label } from 'components';

export const NullableInput = ({
    label,
    sublabel,
    name,
    value,
    onChange,
    type = 'text',
    disabled = false,
    placeholder,
}: {
    label?: string;
    sublabel?: string;
    name?: string;
    value?: string | number | null;
    onChange: (value: string | null) => void;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
}) => {
    return value === null || value === undefined ? (
        <Container>
            {label && <Label>{label}</Label>}
            {sublabel && <Sublabel>{sublabel}</Sublabel>}
            <Button onClick={() => onChange('')}>Add</Button>
        </Container>
    ) : (
        <InputContainer>
            <Input
                label={label}
                sublabel={sublabel}
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
            />
            <Button onClick={() => onChange(null)}>Remove</Button>
        </InputContainer>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
`;

const Sublabel = styled.span``;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
`;
