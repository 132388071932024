import styled from 'styled-components';

import { Column, ColumnsContainer, Title } from 'components';
import { Selector, SelectorOption, isSelectorOptionMultiValue } from 'components/Selector';
import { SubmitSection } from 'routes/ScenarioLocations/Puzzles/SubmitSection';
import { PuzzleInput } from 'routes/ScenarioLocations/Puzzles/CreatePuzzleModal';
import { TranslationsHelper } from 'hooks/useTranslations';
import { TranslationArrayInput } from 'components/TranslationArrayInput';
import { NullableInput } from 'components/NullableInput';
import { TranslationInput } from 'components/TranslationInput';

export function PuzzleInfo({
    puzzleForm,
    setPuzzleForm,
    scenarioLocationPuzzleOptions,
    translationsHelper,
}: {
    puzzleForm: PuzzleInput;
    setPuzzleForm: (value: PuzzleInput) => void;
    scenarioLocationPuzzleOptions: SelectorOption<string>[];
    translationsHelper: TranslationsHelper;
}) {
    const { blockingPuzzleIds, hints, title, expectedAnswer, submitBlock } = puzzleForm;

    return (
        <ColumnsContainer numberOfColumns={2}>
            <Column>
                <TranslationInput
                    value={title}
                    label="Title"
                    onChange={(value) =>
                        setPuzzleForm({
                            ...puzzleForm,
                            title: value,
                        })
                    }
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                    }}
                />
                <NullableInput
                    value={expectedAnswer}
                    label="Expected answer"
                    onChange={(value) =>
                        setPuzzleForm({
                            ...puzzleForm,
                            expectedAnswer: value,
                        })
                    }
                />
                <Selector<string>
                    isMulti
                    label="Blocking puzzles"
                    options={scenarioLocationPuzzleOptions}
                    value={blockingPuzzleIds}
                    onChange={(options) =>
                        isSelectorOptionMultiValue(options)
                            ? setPuzzleForm({
                                  ...puzzleForm,
                                  blockingPuzzleIds: options.map(({ value }) => value),
                              })
                            : undefined
                    }
                />
                <HintsContainer>
                    <TranslationArrayInput
                        label="Hints (1st to last)"
                        values={hints}
                        setValues={(array) => setPuzzleForm({ ...puzzleForm, hints: array })}
                        buttonSize={25}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.hints`,
                        }}
                    />
                </HintsContainer>
                <Title>Score Tiers: todo</Title>
            </Column>
            <Column>
                <SubmitSection
                    submitBlock={submitBlock}
                    setSubmitBlock={(submitBlock) => setPuzzleForm({ ...puzzleForm, submitBlock })}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.submit`,
                    }}
                />
            </Column>
        </ColumnsContainer>
    );
}

const HintsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
