import { gql } from '@apollo/client';
import { GAME_FRAGMENT } from 'data/fragments/game';

export const GET_GAMES_QUERY = gql`
    query GetGames {
        games {
            _id
            completedAt
            createdAt
            isInDemoMode
            joinCode
            scenario {
                imageKey
                title
            }
            startedAt
            state
            transaction {
                origin
                priceDetails {
                    total
                    paid
                    discount
                }
            }
            user {
                email
                firstName
                lastName
            }
        }
    }
`;

export const GET_GAME_QUERY = gql`
    query GetGame($gameId: ID!) {
        game(gameId: $gameId) {
            ...Game
        }
    }
    ${GAME_FRAGMENT}
`;
