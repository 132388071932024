import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const ScrollContainer = ({ children }: PropsWithChildren) => {
    return (
        <Container>
            <InnerContainer>{children}</InnerContainer>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
    flex: 1;
    overflow: hidden;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 15px;
    gap: 15px;
`;
