import { useApolloClient } from '@apollo/client';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import {
    GetTranslationsQuery,
    TranslationCreateInput,
    TranslationFragment,
    TranslationUpdateInput,
    useGetTranslationsQuery,
    useUpdateTranslationsMutation,
} from 'data/generated';
import { GET_TRANSLATIONS_QUERY } from 'data/queries/translation';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ImageTranslationsHook } from './useImageTranslations';

export type Translation = TranslationCreateInput | TranslationUpdateInput;

export enum LanguageType {
    French = 'French',
    English = 'English',
}

export type TranslationsHook = {
    translations: TranslationFragment[];
    doesKeyExist: (key: string) => boolean;
    generateUniqueTranslationKey: () => string;
    createEmptyTranslationFromKey: ({
        key,
        sectionId,
        isGlobal,
    }: {
        key: string;
        sectionId: string;
        isGlobal: boolean;
    }) => Translation;
    getTranslationFromKey: (key: string) => Translation | null;
    translateKey: ({ key }: { key: string }) => string;
    updateTranslation: (translationInput: Translation) => void;
    deleteTranslation: (translationKey?: string | null) => void;
    saveTranslations: () => Promise<void>;
    areTranslationsSaved: boolean;
};

export type TranslationsHelper = {
    translationsHook: TranslationsHook;
    imageTranslationsHook: ImageTranslationsHook;
    translationsKeyPrefix: string;
    isGlobal: boolean;
    sectionId: string;
};

export const useTranslations = (): TranslationsHook => {
    const apolloClient = useApolloClient();

    const { displayFrench } = useTranslationParameters();

    const [createdTranslations, setCreatedTranslations] = useState<TranslationCreateInput[]>([]);
    const [updatedTranslations, setUpdatedTranslations] = useState<TranslationUpdateInput[]>([]);
    const [deletedTranslationIds, setDeletedTranslationIds] = useState<string[]>([]);

    const { data } = useGetTranslationsQuery();
    const [updateTranslations] = useUpdateTranslationsMutation();

    const translations = data?.translations ?? [];

    function doesKeyExist(key: string): boolean {
        const existingCreatedTranslation = createdTranslations?.find((translation) => translation.key === key);
        const existingUpdatedTranslation = updatedTranslations?.find((translation) => translation.key === key);
        const existingTranslation = translations?.find((translation) => translation.key === key);

        if (existingCreatedTranslation || existingUpdatedTranslation) {
            return true;
        }

        if (!existingTranslation) {
            return false;
        }

        const isExistingTranslationAlreadyUpdated = !!updatedTranslations?.find(
            (translation) => translation._id === existingTranslation._id
        );

        return !isExistingTranslationAlreadyUpdated;
    }

    function generateUniqueTranslationKey(): string {
        return new Date().getTime().toString();
    }

    function createEmptyTranslationFromKey({
        key,
        sectionId,
        isGlobal,
    }: {
        key: string;
        sectionId: string;
        isGlobal: boolean;
    }): Translation {
        return {
            english: key,
            french: key,
            isGlobal,
            sectionId,
            key,
        };
    }

    function getTranslationFromKey(key: string): Translation | null {
        return (
            createdTranslations?.find((translation) => translation.key === key) ??
            updatedTranslations?.find((translation) => translation.key === key) ??
            translations?.find((translation) => translation.key === key) ??
            null
        );
    }

    function translateKey({ key }: { key: string }): string {
        const translation = getTranslationFromKey(key);
        const language = displayFrench ? LanguageType.French : LanguageType.English;
        switch (language) {
            case LanguageType.French:
                return translation?.french || key;
            case LanguageType.English:
                return translation?.english || key;
        }
    }

    function updateTranslation(translationInput: Translation): void {
        if (translationInput.key === '') {
            toast.warning(`You cannot have an empty translation key`);
            return;
        }
        // if no _id, it means it's a new translation input
        if (!('_id' in translationInput)) {
            // we update a already added new translation
            const createdTranslationsIndex = createdTranslations.findIndex(({ key }) => key === translationInput.key);
            if (createdTranslationsIndex !== -1) {
                const newCreatedTranslations = [...createdTranslations];
                newCreatedTranslations[createdTranslationsIndex] = translationInput;
                setCreatedTranslations(newCreatedTranslations);
                return;
            }

            // we create a new translation
            // first check that the key doesn't already exist
            if (doesKeyExist(translationInput.key)) {
                toast.error('We already have a translation with this key');
                return;
            }

            const newCreatedTranslations = [...createdTranslations];
            newCreatedTranslations.push(translationInput);
            setCreatedTranslations(newCreatedTranslations);

            return;
        }

        // we update an already updated translation
        const updatedTranslationsIndex = updatedTranslations.findIndex(({ _id }) => _id === translationInput._id);
        if (updatedTranslationsIndex !== -1) {
            const newUpdatedTranslations = [...updatedTranslations];
            newUpdatedTranslations[updatedTranslationsIndex] = translationInput;
            setUpdatedTranslations(newUpdatedTranslations);
            return;
        }

        // we update an existing translation
        const translationsIndex = data?.translations?.findIndex(({ _id }) => _id === translationInput._id);
        if (translationsIndex !== -1) {
            const newUpdatedTranslations = [...updatedTranslations];
            newUpdatedTranslations.push(translationInput);
            setUpdatedTranslations(newUpdatedTranslations);
            return;
        }
    }

    function deleteTranslation(translationKey?: string | null): void {
        if (translationKey === undefined || translationKey === null) {
            return;
        }
        const translationId =
            updatedTranslations.find(({ key }) => key === translationKey)?._id ??
            translations.find(({ key }) => key === translationKey)?._id;

        if (translationId) {
            setDeletedTranslationIds([...deletedTranslationIds, translationId]);
            setUpdatedTranslations(updatedTranslations.filter(({ _id }) => _id !== translationId));
            toast.info(`Translation key ${translationKey} will be deleted in base after saving`);
        } else {
            setCreatedTranslations(createdTranslations.filter(({ key }) => key !== translationKey));
            toast.info(`Translation key ${translationKey} was only created in front. It will not be saved`);
        }
    }

    function updateCacheOnTranslationsSaved(newTranslations: TranslationFragment[]) {
        const data = apolloClient.readQuery<GetTranslationsQuery>({
            query: GET_TRANSLATIONS_QUERY,
        });

        if (!data) {
            return;
        }

        apolloClient.writeQuery<GetTranslationsQuery>({
            query: GET_TRANSLATIONS_QUERY,
            data: { translations: newTranslations },
        });
    }

    async function saveTranslations(): Promise<void> {
        // we remove __typename before calling the mutation
        const loadingToastId = toast.warning('Waiting for translations to be updated...', { autoClose: false });
        const { data } = await updateTranslations({
            variables: {
                archivedTranslationIds: deletedTranslationIds,
                newTranslations: createdTranslations.map(({ english, french, key, isGlobal, sectionId }) => ({
                    english,
                    french,
                    key,
                    isGlobal,
                    sectionId,
                })),
                updatedTranslations: updatedTranslations.map(({ _id, english, french, key, isGlobal, sectionId }) => ({
                    _id,
                    english,
                    french,
                    key,
                    isGlobal,
                    sectionId,
                })),
            },
        });

        if (data) {
            const { updateTranslations } = data;

            if (updateTranslations) {
                updateCacheOnTranslationsSaved(updateTranslations);
                toast.dismiss(loadingToastId);
                toast.success('Translations updated!', { autoClose: 3000 });
            }
        }

        setCreatedTranslations([]);
        setUpdatedTranslations([]);
        setDeletedTranslationIds([]);
    }

    return {
        translations,
        doesKeyExist,
        generateUniqueTranslationKey,
        createEmptyTranslationFromKey,
        getTranslationFromKey,
        translateKey,
        updateTranslation,
        deleteTranslation,
        saveTranslations,
        areTranslationsSaved:
            !createdTranslations.length && !updatedTranslations.length && !deletedTranslationIds.length,
    };
};
