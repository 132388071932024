import { Button, ColumnsContainer, Input, Selector, Title } from 'components';
import { isSelectorOptionMultiValue } from 'components/Selector';
import { CommuteStation, MetroLine, RerLine } from 'data/generated';
import styled from 'styled-components';

export const CommuteStations = ({
    stations,
    onChange,
}: {
    stations: CommuteStation[];
    onChange: (value: CommuteStation[]) => void;
}) => {
    const metroOptions = Object.values(MetroLine).map((line) => ({ value: line, label: line }));
    const rerOptions = Object.values(RerLine).map((line) => ({ value: line, label: line }));

    function updateStation(index: number, newValue: CommuteStation) {
        onChange(
            stations.map((station, stationIndex) => {
                if (stationIndex !== index) {
                    return station;
                }

                return newValue;
            })
        );
    }

    function addStation() {
        onChange([...stations, { name: '', description: '', commuteLines: { metroLines: [], rerLines: [] } }]);
    }

    function removeStation(stationIndex: number) {
        onChange(stations.filter((_station, index) => index !== stationIndex));
    }

    return (
        <>
            <Title>Recommended meeting points</Title>
            {stations.map((station, stationIndex) => {
                const { name } = station;

                return (
                    <StationContainer>
                        <ColumnsContainer numberOfColumns={3}>
                            <Input
                                label="Name"
                                value={name}
                                onChange={(value) =>
                                    updateStation(stationIndex, {
                                        ...station,
                                        name: value,
                                    })
                                }
                            />
                            <Selector
                                label="Metro lines"
                                options={metroOptions}
                                isMulti
                                value={station.commuteLines.metroLines}
                                onChange={(options) =>
                                    isSelectorOptionMultiValue(options)
                                        ? updateStation(stationIndex, {
                                              ...station,
                                              commuteLines: {
                                                  ...station.commuteLines,
                                                  metroLines: options.map((option) => option.value),
                                              },
                                          })
                                        : undefined
                                }
                            />
                            <Selector
                                label="Rer lines"
                                options={rerOptions}
                                isMulti
                                value={station.commuteLines.rerLines}
                                onChange={(options) =>
                                    isSelectorOptionMultiValue(options)
                                        ? updateStation(stationIndex, {
                                              ...station,
                                              commuteLines: {
                                                  ...station.commuteLines,
                                                  rerLines: options.map((option) => option.value),
                                              },
                                          })
                                        : undefined
                                }
                            />
                        </ColumnsContainer>
                        <Button onClick={() => removeStation(stationIndex)}>Remove</Button>
                    </StationContainer>
                );
            })}
            <Button onClick={addStation}>Add new station</Button>
        </>
    );
};

const StationContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid black;
    padding: 10px;
    gap: 10px;
`;
