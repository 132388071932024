import { TranslationsHelper } from 'hooks/useTranslations';
import styled from 'styled-components';

export const PreviewTitleBlock = ({
    text,
    translationsHelper,
}: {
    text: string;
    translationsHelper: TranslationsHelper;
}) => {
    const {
        translationsHook: { translateKey },
    } = translationsHelper;

    const textTranslation = translateKey({
        key: text,
    });

    return <Title>{textTranslation}</Title>;
};

const Title = styled.div`
    margin: 0;
    font-size: 28px;
`;
