import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './image';

export const COLLECTION_ITEM_FRAGMENT = gql`
    fragment CollectionItem on CollectionItem {
        _id
        fuzzyLocation {
            latitude
            longitude
        }
        image {
            ...Image
        }
        imageKey
        location {
            latitude
            longitude
        }
        name
    }
    ${IMAGE_FRAGMENT}
`;
