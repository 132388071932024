import { gql } from '@apollo/client';
import { CONTENT_BLOCK_FRAGMENT } from './content';

export const QUIZ_FRAGMENT = gql`
    fragment Quiz on Quiz {
        questions {
            blocks {
                ...ContentBlock
            }
            expectedAnswerKey
            labelKey
        }
        titleKey
    }
    ${CONTENT_BLOCK_FRAGMENT}
`;
