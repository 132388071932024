import styled from 'styled-components';
import { TbListDetails } from 'react-icons/tb';

import { CustomLink } from 'components/CustomLink';

type Field = { name: string; label: string };

type Entry = Record<string, string | number | JSX.Element | string[] | null>;

export const TableView = ({
    fields,
    entries,
    noEntriesMessage,
    path,
}: {
    fields: Field[];
    entries: Entry[];
    noEntriesMessage?: string;
    path?: string;
}) => {
    return (
        <Table>
            <thead>
                <tr>
                    {fields.map(({ label }) => (
                        <th key={label}>{label}</th>
                    ))}
                    {path && <th>Details</th>}
                </tr>
            </thead>
            <tbody>
                {entries.length
                    ? entries.map((entry, entryIndex) => {
                          return (
                              <tr key={entry._id?.toString() ?? entryIndex}>
                                  {fields.map(({ name }) => {
                                      const values = entry[name];
                                      return (
                                          <td key={`${entry._id}-${name}`}>
                                              {values instanceof Array ? values.join(', ') : values}
                                          </td>
                                      );
                                  })}
                                  {path && (
                                      <CenteredCell>
                                          <CustomLink to={`${path}${entry._id}`}>
                                              <TbListDetails size={24} />
                                          </CustomLink>
                                      </CenteredCell>
                                  )}
                              </tr>
                          );
                      })
                    : noEntriesMessage ?? 'No entries'}
            </tbody>
        </Table>
    );
};

const Table = styled.table`
    position: relative;
    margin: 0 0 40px 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border: 0;
    overflow: hidden;
    border-collapse: collapse;

    th,
    td {
        padding: 10px;
        border: 0px solid transparent;
        background-clip: padding-box;
    }

    th {
        font-weight: 900;
        color: #ffffff;
        background-color: #2b2b2b;
    }

    tr {
        width: 100%;
        background-color: #f6f6f6;
        border-radius: ${({ theme }) => theme.borderRadius.sm}px;
        overflow: hidden;

        &:nth-of-type(odd) {
            background-color: #e9e9e9;
        }
    }

    tr > th + th {
        border-color: #bbbbbb;
        border-left-width: 1px;
    }

    tr > td + td {
        border-color: #bbbbbb;
        border-left-width: 1px;
    }

    tr + tr > td {
        border-color: #bbbbbb;
        border-top-width: 1px;
    }
`;

const CenteredCell = styled.td`
    text-align: center;
`;
