import { Label } from 'components/Label';
import { GameLocationState } from 'data/generated';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

function getGameLocationStateTagColors(state: GameLocationState): {
    tagColor: string;
    tagTextColor?: string;
} {
    switch (state) {
        case GameLocationState.Locked:
            return { tagColor: Colors.secondary.red };
        case GameLocationState.Unlocked:
            return { tagColor: Colors.secondary.blue };
        case GameLocationState.Completed:
            return { tagColor: Colors.secondary.green };
        case GameLocationState.Archived:
            return { tagColor: Colors.secondary.mediumGrey };
    }
}

export const GameLocationStateTag = ({ state, label }: { state: GameLocationState; label?: string }) => {
    const { tagColor, tagTextColor = Colors.primary.white } = getGameLocationStateTagColors(state);
    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Tag tagColor={tagColor} tagTextColor={tagTextColor}>
                {state}
            </Tag>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Tag = styled.div<{ tagColor: string; tagTextColor: string }>`
    font-size: 14px;
    color: ${({ tagTextColor }) => tagTextColor};
    background-color: ${({ tagColor }) => tagColor};
    width: min-content;
    padding: 5px 10px;
    border-radius: 10px;
`;
