import { Label, Input, Button } from 'components';
import { useState } from 'react';
import styled from 'styled-components';
import * as ThumbHash from 'thumbhash';
import { arrayBufferToBase64, base64ToArrayBuffer } from 'utils/conversion';

export const ImageUrlToThumbhash = () => {
    const [imageUrl, setImageUrl] = useState('');
    const [thumbhash, setThumbhash] = useState('');
    const [aspectRatio, setAspectRatio] = useState(0);

    async function convertUrlToThumbhash() {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = imageUrl;
        await new Promise((resolve) => (image.onload = resolve));
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (!context) {
            return;
        }

        const scale = 100 / Math.max(image.width, image.height);
        canvas.width = Math.round(image.width * scale);
        canvas.height = Math.round(image.height * scale);
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        const pixels = context.getImageData(0, 0, canvas.width, canvas.height);
        const binaryThumbHash = ThumbHash.rgbaToThumbHash(pixels.width, pixels.height, pixels.data);
        const aspectRatio = canvas.width / canvas.height;

        setThumbhash(arrayBufferToBase64(binaryThumbHash));
        setAspectRatio(aspectRatio);
    }

    return (
        <Container>
            <Input label="Image URL" value={imageUrl} onChange={(value) => setImageUrl(value)} />
            <Button onClick={convertUrlToThumbhash}>Convert to thumbhash</Button>
            <Label>Thumbhash</Label>
            {thumbhash ? (
                <PreviewPlaceholder
                    src={ThumbHash.thumbHashToDataURL(base64ToArrayBuffer(thumbhash))}
                    alt="Placeholder"
                />
            ) : null}
            {thumbhash || 'Enter a URL first'}
            <Label>Aspect ratio</Label>
            {aspectRatio}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const PreviewPlaceholder = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;
