import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    LandmarkState,
    ScenarioFragment,
    ScenarioUpdateInput,
    useGetScenarioDetailsQuery,
    useUpdateScenarioMutation,
} from 'data/generated';

import { Button, FullPageLoader, Header, ScrollContainer } from 'components';
import { ScenarioInfo } from 'routes/Scenarios/ScenarioInfo';
import { ScenarioLocations } from 'routes/Scenarios/ScenarioLocations';
import { ScenarioLandmarks } from './ScenarioLandmarks';
import { removeFieldFromObject } from 'routes/utils';
import { useTranslations } from 'hooks/useTranslations';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { BackpackItems } from './BackpackItems';
import { useImageTranslations } from 'hooks/useImageTranslations';
import { EndQuiz } from './EndQuiz';

export const ScenarioDetails = () => {
    const { scenarioId } = useParams<{ scenarioId: string }>() as { scenarioId: string };

    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [scenarioInitialInfo, setScenarioInitialInfo] = useState<ScenarioFragment | null>(null);
    const [scenarioForm, setScenarioForm] = useState<ScenarioUpdateInput | null>(null);
    const {
        data: scenarioData,
        loading,
        error,
    } = useGetScenarioDetailsQuery({
        variables: {
            scenarioId,
        },
    });
    const [updateScenario, { loading: updateLoading }] = useUpdateScenarioMutation();

    useEffect(() => {
        if (scenarioData) {
            const { scenario } = scenarioData;

            const cleanedScenario = removeFieldFromObject({ ...scenario }, '__typename');
            setScenarioForm(cleanedScenario as ScenarioUpdateInput);
            setScenarioInitialInfo(cleanedScenario as ScenarioFragment);
        }
    }, [scenarioData, setScenarioForm]);

    if (loading) {
        return <FullPageLoader />;
    }

    if (error || !scenarioData) {
        return <>{error?.message || 'Error fetching scenario'}</>;
    }

    const updateScenarioHandler = async () => {
        if (!scenarioForm) {
            return;
        }

        const {
            accessibility,
            cityId,
            conclusionBlocks,
            description,
            difficulty,
            distanceEstimate,
            durationEstimate,
            endQuiz,
            imageKey,
            introductionBlocks,
            meta,
            previewImageKeys,
            price,
            recommendedCommuteStations,
            recommendedHours,
            slug,
            title,
        } = scenarioForm;

        const { data } = await updateScenario({
            variables: {
                scenarioId: scenarioId,
                scenarioUpdateInput: {
                    accessibility,
                    cityId,
                    conclusionBlocks,
                    description,
                    difficulty,
                    distanceEstimate,
                    durationEstimate,
                    endQuiz,
                    imageKey,
                    introductionBlocks,
                    meta,
                    previewImageKeys,
                    price,
                    recommendedCommuteStations,
                    recommendedHours,
                    slug,
                    title,
                },
            },
        });

        if (data) {
            const {
                updateScenario: { scenario, errors },
            } = data;

            if (errors) {
                errors.map((error) => toast.error(error, { autoClose: 3000 }));
            }
            if (scenario) {
                toast.success('Scenario updated!', { autoClose: 3000 });

                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
            }
        }
    };

    const {
        scenario: { landmarks, scenarioLocations },
    } = scenarioData;

    const activeLandmarks = landmarks.filter(({ state }) => state === LandmarkState.Regular);

    const scenarioLocationOptions = scenarioLocations.map(({ _id, title }) => ({
        value: _id,
        label: translationsHook.translateKey({
            key: title,
        }),
    }));

    return scenarioForm && scenarioInitialInfo ? (
        <>
            <Header title="Scenario">
                <ButtonsContainer>
                    <Button onClick={updateScenarioHandler} disabled={updateLoading}>
                        <FaSave />
                    </Button>
                    <Link to="/scenarios">
                        <Button>Return</Button>
                    </Link>
                </ButtonsContainer>
            </Header>
            <ScrollContainer>
                <ScenarioInfo
                    setScenarioForm={setScenarioForm}
                    scenarioForm={scenarioForm}
                    scenarioInitialInfo={scenarioInitialInfo}
                    translationsHelper={translationsHelper}
                />
                <ScenarioLocations
                    scenarioLocations={scenarioLocations}
                    scenarioId={scenarioId}
                    translationsHelper={translationsHelper}
                />
                <BackpackItems scenarioId={scenarioId} scenarioLocationOptions={scenarioLocationOptions} />
                <EndQuiz
                    setScenarioForm={setScenarioForm}
                    scenarioForm={scenarioForm}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.end_quiz`,
                    }}
                />
                <ScenarioLandmarks landmarks={activeLandmarks} scenarioId={scenarioId} />
            </ScrollContainer>
        </>
    ) : null;
};

const ButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
`;
