import { Loader } from 'components/Loader';
import styled from 'styled-components';

export const FullPageLoader = () => {
    return (
        <Container>
            <Loader />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
`;
