import Select, { MultiValue, SingleValue } from 'react-select';
import { Label } from 'components';
import styled from 'styled-components';

export type SelectorOption<T> = {
    value: T;
    label: string;
};

type OptionType<T> = SingleValue<SelectorOption<T>> | MultiValue<SelectorOption<T>>;

export function isSelectorOptionMultiValue<T>(option: OptionType<T>): option is MultiValue<SelectorOption<T>> {
    return Array.isArray(option);
}

export function isSelectorOptionSingleValue<T>(option: OptionType<T>): option is SingleValue<SelectorOption<T>> {
    return !Array.isArray(option);
}

export function Selector<T>({
    label,
    value,
    options,
    isMulti,
    isClearable,
    onChange,
}: {
    label?: string;
    value?: T | T[];
    options: SelectorOption<T>[];
    isClearable?: boolean;
    isMulti?: boolean;
    onChange: (option: OptionType<T>) => void;
}) {
    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Select
                isMulti={isMulti}
                isClearable={isClearable}
                options={options}
                onChange={onChange}
                value={
                    isMulti
                        ? options.filter(({ value: optionValue }) =>
                              value instanceof Array ? value.includes(optionValue) : optionValue === value
                          )
                        : options.find(({ value: optionValue }) => optionValue === value)
                }
            />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
