import { useApolloClient } from '@apollo/client';
import {
    BackpackItemFragment,
    GetBackpackItemsOfScenarioQuery,
    GetBackpackItemsOfScenarioQueryVariables,
} from 'data/generated';
import { GET_BACKPACK_ITEMS_OF_SCENARIO_QUERY } from 'data/queries/backpackItem';

export const useBackpackItemHandlers = () => {
    const apolloClient = useApolloClient();

    function backpackItemAddedHandler(backpackItem: BackpackItemFragment) {
        const { scenarioId } = backpackItem;

        const data = apolloClient.readQuery<GetBackpackItemsOfScenarioQuery, GetBackpackItemsOfScenarioQueryVariables>({
            query: GET_BACKPACK_ITEMS_OF_SCENARIO_QUERY,
            variables: { scenarioId },
        });

        if (!data) {
            return;
        }

        const { backpackItemsOfScenario } = data;

        apolloClient.writeQuery<GetBackpackItemsOfScenarioQuery, GetBackpackItemsOfScenarioQueryVariables>({
            query: GET_BACKPACK_ITEMS_OF_SCENARIO_QUERY,
            variables: { scenarioId },
            data: { backpackItemsOfScenario: [...backpackItemsOfScenario, backpackItem] },
        });
    }

    return { backpackItemAddedHandler };
};
