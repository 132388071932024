import { Selector } from 'components';
import { isSelectorOptionSingleValue } from 'components/Selector';
import { useGetAllCitiesQuery } from 'data/generated';

export const CitySelector = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
    const { data } = useGetAllCitiesQuery();

    if (!data) {
        return null;
    }

    const { cities } = data;

    const cityOptions = cities.map(({ _id, name }) => ({ value: _id, label: name }));

    return (
        <Selector
            label="City"
            options={cityOptions}
            value={value}
            onChange={(option) =>
                isSelectorOptionSingleValue(option) && option !== null ? onChange(option.value) : undefined
            }
        />
    );
};
