import { gql } from '@apollo/client';

export const GAME_PUZZLE_FRAGMENT = gql`
    fragment GamePuzzle on GamePuzzle {
        _id
        completedAt
        guesses
        numberOfUsedHints
        puzzle {
            _id
            blockingPuzzleIds
            title
        }
        startedAt
        state
    }
`;
