import { Button, Selector } from 'components';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { SelectorOption, isSelectorOptionSingleValue } from 'components/Selector';
import { TranslationInput } from 'components/TranslationInput';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import {
    BackpackItemFragment,
    BackpackItemInput,
    useArchiveBackpackItemMutation,
    useUpdateBackpackItemMutation,
} from 'data/generated';
import { useImageTranslations } from 'hooks/useImageTranslations';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { FaSave, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const BackpackItemForm = ({
    scenarioId,
    backpackItem,
    index,
    scenarioLocationOptions,
}: {
    scenarioId: string;
    backpackItem: BackpackItemFragment;
    index: number;
    scenarioLocationOptions: SelectorOption<string>[];
}) => {
    const { _id: backpackItemId, title, description, imageKey, blockingScenarioLocationId } = backpackItem;

    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.backpack.${keyPrefixLocation}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [backpackItemForm, setBackpackItemForm] = useState<BackpackItemInput>({
        blockingScenarioLocationId,
        title,
        description,
        imageKey,
    });

    const [updateBackpackItem] = useUpdateBackpackItemMutation();
    const [archiveBackpackItemMutation] = useArchiveBackpackItemMutation();

    async function submitBackpackItem() {
        const { data } = await updateBackpackItem({
            variables: { backpackItemId, updateInput: backpackItemForm },
        });

        if (data) {
            const {
                updateBackpackItem: { errors, backpackItem },
            } = data;

            if (errors) {
                errors.forEach((error) => toast.error(error, { autoClose: 5000 }));
            }

            if (backpackItem) {
                toast.success('BackpackItem has been updated', { autoClose: 5000 });

                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
            }
        }
    }

    async function archiveBackpackItem() {
        if (window.confirm("Voulez vous archiver l'objet du sac à dos ?")) {
            const { data } = await archiveBackpackItemMutation({ variables: { backpackItemId } });

            if (data) {
                const {
                    archiveBackpackItem: { errors, backpackItem },
                } = data;

                if (errors) {
                    errors.forEach((error) => toast.error(error, { autoClose: 5000 }));
                }

                if (backpackItem) {
                    toast.success('BackpackItem has been archived', { autoClose: 5000 });
                }
            }
        }
    }

    return (
        <Container>
            <BackpackItemIdentifier color={backpackItemId.slice(0, 6)} />
            <BackpackItemContainer>
                <Header>
                    <HeaderSubtitle>
                        BackpackItem {backpackItemId} (No {index + 1})
                    </HeaderSubtitle>
                    <Button onClick={archiveBackpackItem} backgroundColor={Colors.secondary.red}>
                        <FaTrash />
                    </Button>
                    <Button onClick={submitBackpackItem}>
                        <FaSave />
                    </Button>
                </Header>
                <TranslationInput
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                    }}
                    label="Title"
                    value={backpackItemForm.title}
                    onChange={(value) => setBackpackItemForm({ ...backpackItemForm, title: value })}
                />
                <TranslationInput
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.description`,
                    }}
                    label="Description"
                    value={backpackItemForm.description}
                    onChange={(value) => setBackpackItemForm({ ...backpackItemForm, description: value })}
                />
                <ImageTranslationInput
                    label="Image"
                    onChange={(value) => setBackpackItemForm({ ...backpackItemForm, imageKey: value })}
                    value={backpackItemForm.imageKey}
                    translationsHelper={{
                        ...translationsHelper,
                        translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                    }}
                />
                <Selector<string>
                    label="Blocking Location"
                    isClearable
                    options={scenarioLocationOptions}
                    value={backpackItemForm.blockingScenarioLocationId ?? undefined}
                    onChange={(option) =>
                        isSelectorOptionSingleValue(option)
                            ? setBackpackItemForm({
                                  ...backpackItemForm,
                                  blockingScenarioLocationId: option?.value ?? null,
                              })
                            : undefined
                    }
                />
            </BackpackItemContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const BackpackItemIdentifier = styled.div<{ color: string }>`
    height: 100%;
    width: 10px;
    background-color: #${({ color }) => color};
`;

const BackpackItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
    gap: 10px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

const HeaderSubtitle = styled.span`
    flex: 1;
    font-size: 16px;
    font-style: italic;
`;
