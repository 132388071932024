import { gql } from '@apollo/client';

export const SUBMIT_BLOCK_FRAGMENT = gql`
    fragment SubmitBlock on SubmitBlock {
        ... on AugmentedReality {
            type
            anchorImages {
                url
                physicalWidth
                name
                hiddenMessage
            }
        }
        ... on AutoPlayingSound {
            type
        }
        ... on ButtonBlock {
            type
            label
        }
        ... on CameraLayer {
            type
            layerImageUrl
        }
        ... on Carousel {
            type
            answerImageUrl
            wrongImageUrls
            imagesSize
        }
        ... on CesarCode {
            type
            stringCode
            plainText
        }
        ... on ColorLetters {
            type
            colorLetters {
                color
                letter
            }
        }
        ... on CombinationPadlock {
            type
        }
        ... on Digicode {
            type
        }
        ... on FourDolphins {
            type
        }
        ... on GalerieColbert {
            type
        }
        ... on HideAndSeek {
            type
            hidingImageKey
            hiddenImageKey
        }
        ... on ImageAreas {
            type
            numberOfRows
            numberOfColumns
            imageKey
            answerCells {
                row
                column
            }
        }
        ... on ImagesOrdering {
            type
            imageItems {
                imageKey
                isInitiallyRevealed
                position
            }
        }
        ... on ImagesSelection {
            type
            imageItems {
                imageKey
                isCorrect
            }
        }
        ... on ImagesSorting {
            type
            imageItems {
                imageKey
                position
            }
        }
        ... on InputBlock {
            type
            inputLabel
            keyboardType
            placeholder
        }
        ... on LightBulbs {
            type
            imageOnUrl
            imageOffUrl
        }
        ... on MorseCode {
            type
            code
        }
        ... on MultiSelect {
            type
            answerIndices
            optionLabels
        }
        ... on MusicSheet {
            type
            sequencesToGuess {
                letter
                noteIndex
            }
        }
        ... on Paving {
            type
            numberOfColumns
            numberOfRows
            pavingImageKeys
            pavingBottonImageKey
        }
        ... on PhotoPuzzle {
            type
            numberOfColumns
            numberOfRows
            photoPuzzleImageKey
        }
        ... on Rotonde {
            type
        }
        ... on Select {
            type
            options {
                value
                label
            }
        }
        ... on SimonSays {
            type
            sequence
        }
        ... on Skyline {
            type
            skylineOptions
            expectedSequence
        }
        ... on Sundial {
            type
        }
        ... on WineCellar {
            type
        }
    }
`;
