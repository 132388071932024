import { gql } from '@apollo/client';

const FUN_FACT_FRAGMENT = gql`
    fragment FunFact on FunFact {
        title
        text
        imageKey
    }
`;

export const SCENARIO_LOCATION_FRAGMENT = gql`
    fragment ScenarioLocation on ScenarioLocation {
        _id
        blockingScenarioLocationIds
        funFacts {
            ...FunFact
        }
        imageKey
        location {
            latitude
            longitude
        }
        state
        title
        visitedLandmark
    }
    ${FUN_FACT_FRAGMENT}
`;

export const SCENARIO_LOCATION_OF_SCENARIO_FRAGMENT = gql`
    fragment ScenarioLocationOfScenario on ScenarioLocation {
        _id
        blockingScenarioLocationIds
        funFacts {
            ...FunFact
        }
        imageKey
        location {
            latitude
            longitude
        }
        numberOfPuzzles
        title
    }
    ${FUN_FACT_FRAGMENT}
`;
