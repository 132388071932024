import React from 'react';
import ReactDOM from 'react-dom/client';

import { createGlobalStyle } from 'styled-components';

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
// @ts-ignore
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';

const graphQLUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql';

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const authorization = window.localStorage.getItem('authentication-token') || '';

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization,
            'Apollo-Require-Preflight': 'true',
        },
    };
});

const uploadLink = createUploadLink({ uri: graphQLUrl });

const client = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache({
        possibleTypes: {
            ContentBlock: ['TitleBlock', 'SubtitleBlock', 'ParagraphBlock', 'ImageBlock', 'EquationsBlock'],
            PuzzleTemplate: ['ContentTemplate', 'HotAndColdTemplate', 'NavigateAndFindTemplate'],
            ScoreTier: ['DefaultScoreTier', 'NumberOfMovesScoreTier', 'NumberOfTriesScoreTier', 'TimedScoreTier'],
            SubmitBlock: [
                'AugmentedReality',
                'AutoPlayingSound',
                'ButtonBlock',
                'CameraLayer',
                'Carousel',
                'CesarCode',
                'ColorLetters',
                'CombinationPadlock',
                'Digicode',
                'FourDolphins',
                'GalerieColbert',
                'HideAndSeek',
                'ImageAreas',
                'ImagesOrdering',
                'ImagesSelection',
                'ImagesSorting',
                'InputBlock',
                'LightBulbs',
                'MorseCode',
                'MultiSelect',
                'MusicSheet',
                'Paving',
                'PhotoPuzzle',
                'Rotonde',
                'Select',
                'Sundial',
                'SimonSays',
                'Skyline',
                'WineCellar',
            ],
        },
    }),
});

const GlobalStyle = createGlobalStyle`
  body, #root {
    width: 100vw;
    height: 100vh;
    margin: 0;
    font-family: 'Arial';
  }

  * {
    box-sizing: border-box
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ApolloProvider>
        <GlobalStyle />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
