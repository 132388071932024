import { createContext, useContext } from 'react';

type TranslationParametersContextType = {
    displayKey: boolean;
    displayFrench: boolean;
    displayEnglish: boolean;
    setDisplayKey: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayFrench: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayEnglish: React.Dispatch<React.SetStateAction<boolean>>;

    useMarkdownEditor: boolean;
    setUseMarkdownEditor: React.Dispatch<React.SetStateAction<boolean>>;

    keyPrefixCity: string;
    keyPrefixLocation: string;
    setKeyPrefixCity: React.Dispatch<React.SetStateAction<string>>;
    setKeyPrefixLocation: React.Dispatch<React.SetStateAction<string>>;
};

export const TranslationParametersContext = createContext<TranslationParametersContextType>(null!);

export function useTranslationParameters() {
    return useContext(TranslationParametersContext);
}
