import { CircleF, GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { CollectionItemFragment, Point } from 'data/generated';

import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

const center = {
    lat: 48.8575943077439,
    lng: 2.341761833549674,
};

export const CollectionItemsMap = ({
    collectionItems,
    newItemLocation,
    areaRadius = 50,
    onClick,
}: {
    collectionItems: CollectionItemFragment[];
    newItemLocation: { lat: number; lng: number } | { lat: null; lng: null };
    areaRadius?: number | null;
    onClick: (e: google.maps.MapMouseEvent) => void;
}) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);

    const locations = collectionItems.map(({ location }) => ({ lat: location.latitude, lng: location.longitude }));
    const fuzzyLocations = collectionItems
        .map(({ fuzzyLocation }) => fuzzyLocation)
        .filter((fuzzyLocation): fuzzyLocation is Point => !!fuzzyLocation)
        .map((fuzzyLocation) => ({
            lat: fuzzyLocation.latitude,
            lng: fuzzyLocation.longitude,
        }));

    const allLocations = locations.concat(fuzzyLocations);

    if (newItemLocation.lat && newItemLocation.lng) {
        allLocations.push(newItemLocation);
    }

    useEffect(() => {
        if (map && allLocations.length > 0) {
            const { minLat, minLng, maxLat, maxLng } = allLocations.reduce(
                (acc, point) => {
                    return {
                        minLat: Math.min(acc.minLat, point.lat),
                        minLng: Math.min(acc.minLng, point.lng),
                        maxLat: Math.max(acc.maxLat, point.lat),
                        maxLng: Math.max(acc.maxLng, point.lng),
                    };
                },
                { minLat: 100, minLng: 100, maxLat: 0, maxLng: 0 }
            );

            const coordinatesDelta = 0;

            map.fitBounds(
                new google.maps.LatLngBounds(
                    new google.maps.LatLng(minLat - coordinatesDelta, minLng - coordinatesDelta),
                    new google.maps.LatLng(maxLat + coordinatesDelta, maxLng + coordinatesDelta)
                )
            );
        }
    }, [map, allLocations]);

    const onMapLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map);
    }, []);

    const onMapUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    return (
        <MapContainer>
            <LoadScript googleMapsApiKey="AIzaSyDSwTo3v5o77PoHFlyMTLgtWBgXVDB_oDY">
                <GoogleMap
                    id="test"
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={center}
                    zoom={12}
                    onLoad={onMapLoad}
                    onUnmount={onMapUnmount}
                    onClick={onClick}
                    options={{ draggableCursor: 'crosshair', gestureHandling: 'cooperative' }}
                >
                    {locations.map((location, index) => (
                        <MarkerF key={`location-${index}`} position={location} />
                    ))}
                    {fuzzyLocations.map((location, index) => (
                        <CircleF
                            key={`fuzzy-location-${index}`}
                            center={location}
                            radius={areaRadius ?? undefined}
                            options={{ fillColor: Colors.secondary.blue }}
                        />
                    ))}
                    {newItemLocation?.lat ? <MarkerF key="new-location" position={newItemLocation} /> : null}
                </GoogleMap>
            </LoadScript>
        </MapContainer>
    );
};

const MapContainer = styled.div`
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;
