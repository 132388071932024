import { Button, InfoField, Title } from 'components';
import { EditableContentBlocks } from 'components/EditableContentBlocks';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { TranslationInput } from 'components/TranslationInput';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { ContentBlockInput, useCreateLandmarkMutation } from 'data/generated';
import { useImageTranslations } from 'hooks/useImageTranslations';
import { useLandmarkHandlers } from 'hooks/useLandmarkHandlers';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';

export const NewLandmarkForm = ({
    location,
    scenarioId,
    resetLocation,
}: {
    location: { lat: number; lng: number };
    scenarioId: string;
    resetLocation: () => void;
}) => {
    const translationsHook = useTranslations();
    const imageTranslationsHook = useImageTranslations();
    const { keyPrefixCity, keyPrefixLocation } = useTranslationParameters();
    const translationsHelper = {
        translationsHook,
        imageTranslationsHook,
        translationsKeyPrefix: `${keyPrefixCity}.landmark.${keyPrefixLocation}`,
        sectionId: scenarioId,
        isGlobal: false,
    };

    const [newLandmark, setNewLandmark] = useState<{
        title: string;
        blocks: ContentBlockInput[];
        imageKey: string;
        location: { latitude: number; longitude: number };
        scenarioId: string;
    }>({
        title: '',
        blocks: [],
        imageKey: '',
        location: { latitude: location.lat, longitude: location.lng },
        scenarioId,
    });

    const [createLandmarkMutation] = useCreateLandmarkMutation();
    const { landmarkAddedHandler } = useLandmarkHandlers();

    async function submitLandmark() {
        const { data } = await createLandmarkMutation({ variables: { landmarkInput: newLandmark } });

        if (data) {
            const {
                createLandmark: { landmark },
            } = data;

            if (landmark) {
                landmarkAddedHandler(landmark);

                await Promise.all([translationsHook.saveTranslations(), imageTranslationsHook.saveImageTranslations()]);
            }

            resetLocation();
        }
    }

    return (
        <Container>
            <Header>
                <Title>New landmark</Title>
                <Button onClick={submitLandmark}>
                    <FaSave />
                </Button>
            </Header>
            <InfoField label="Position" value={location.lat + ', ' + location.lng}></InfoField>
            <TranslationInput
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                }}
                label="Title"
                value={newLandmark.title}
                onChange={(value) => setNewLandmark({ ...newLandmark, title: value })}
            />
            <ImageTranslationInput
                label="Image"
                onChange={(value) => setNewLandmark({ ...newLandmark, imageKey: value })}
                value={newLandmark.imageKey}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                }}
            />
            <EditableContentBlocks
                blocks={newLandmark.blocks}
                setBlocks={(blocks) => setNewLandmark({ ...newLandmark, blocks })}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.content`,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
