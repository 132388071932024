import { gql } from '@apollo/client';

export const BACKPACK_ITEM_FRAGMENT = gql`
    fragment BackpackItem on BackpackItem {
        _id
        scenarioId
        blockingScenarioLocationId
        imageKey
        state
        title
        description
    }
`;
