import { gql } from '@apollo/client';
import { GAME_PUZZLE_FRAGMENT } from './gamePuzzle';

export const GAME_LOCATION_FRAGMENT = gql`
    fragment GameLocation on GameLocation {
        _id
        completedAt
        gamePuzzles {
            ...GamePuzzle
        }
        scenarioLocation {
            _id
            blockingScenarioLocationIds
            imageKey
            title
        }
        startedAt
        state
    }
    ${GAME_PUZZLE_FRAGMENT}
`;
