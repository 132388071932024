import { Label } from 'components';
import { useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import * as ThumbHash from 'thumbhash';
import { arrayBufferToBase64, base64ToArrayBuffer } from 'utils/conversion';

export const ImageToThumbhash = () => {
    const [image, setImage] = useState<{ file: File; url: string; thumbhash: string; type: string } | null>(null);

    const onDrop = useCallback(
        async (acceptedFiles: FileWithPath[]) => {
            const file = acceptedFiles[0];

            const originalURL = URL.createObjectURL(file);
            const image = new Image();
            image.src = originalURL;
            await new Promise((resolve) => (image.onload = resolve));
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) {
                return;
            }

            const scale = 100 / Math.max(image.width, image.height);
            canvas.width = Math.round(image.width * scale);
            canvas.height = Math.round(image.height * scale);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            const pixels = context.getImageData(0, 0, canvas.width, canvas.height);
            const binaryThumbHash = ThumbHash.rgbaToThumbHash(pixels.width, pixels.height, pixels.data);

            setImage({
                file,
                url: URL.createObjectURL(file),
                thumbhash: arrayBufferToBase64(binaryThumbHash),
                type: file.type,
            });
        },
        [setImage]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

    return (
        <Container {...getRootProps()}>
            <Label>Image</Label>
            <UploadContainer>
                <InputContainer>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                </InputContainer>

                {image && image.url ? <PreviewImage src={image.url} alt="Preview" /> : null}
                {image && image.thumbhash ? (
                    <PreviewPlaceholder
                        src={ThumbHash.thumbHashToDataURL(base64ToArrayBuffer(image.thumbhash))}
                        alt="Placeholder"
                    />
                ) : null}
            </UploadContainer>
            <Label>Thumbhash</Label>
            {image ? image.thumbhash : 'Upload an image first'}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const UploadContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const InputContainer = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    text-align: center;
`;

const PreviewImage = styled.img`
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const PreviewPlaceholder = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;
