import { useApolloClient } from '@apollo/client';
import { CollectionItemFragment, GetItemsOfCollectionQuery } from 'data/generated';
import { GET_ITEMS_OF_COLLECTION_QUERY } from 'data/queries/collectionItem';

export const useCollectionItemHandlers = () => {
    const apolloClient = useApolloClient();

    function collectionItemAddedHandler({
        collectionItem,
        collectionId,
    }: {
        collectionItem: CollectionItemFragment;
        collectionId: string;
    }) {
        const data = apolloClient.readQuery<GetItemsOfCollectionQuery>({
            query: GET_ITEMS_OF_COLLECTION_QUERY,
            variables: { collectionId },
        });

        if (!data) {
            return;
        }

        const { itemsOfCollection } = data;

        apolloClient.writeQuery<GetItemsOfCollectionQuery>({
            query: GET_ITEMS_OF_COLLECTION_QUERY,
            variables: { collectionId },
            data: { itemsOfCollection: [...itemsOfCollection, collectionItem] },
        });
    }

    return { collectionItemAddedHandler };
};
