import { PropsWithChildren } from 'react';
import styled, { css, CSSProperties } from 'styled-components';

export const ColumnsContainer = ({
    children,
    style,
    numberOfColumns,
}: PropsWithChildren<{ numberOfColumns: number; style?: CSSProperties }>) => {
    return (
        <StyledColumnsContainer style={style} numberOfColumns={numberOfColumns}>
            {children}
        </StyledColumnsContainer>
    );
};

const StyledColumnsContainer = styled.div<{ numberOfColumns: number }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
        ${({ numberOfColumns }: { numberOfColumns: number }) => css`
            width: calc((100% - ${20 * (numberOfColumns - 1)}px) / ${numberOfColumns});
        `};
    }

    & > :not(:nth-child(${({ numberOfColumns }: { numberOfColumns: number }) => numberOfColumns}n + 1)) {
        margin-left: 20px;
    }

    & > :nth-child(n + ${({ numberOfColumns }: { numberOfColumns: number }) => numberOfColumns + 1}) {
        margin-top: 15px;
    }
`;
