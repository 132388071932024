import { Button, Card, EditableContentBlocks, Label, Title } from 'components';
import { QuestionInput, ScenarioUpdateInput } from 'data/generated';
import styled from 'styled-components';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { TranslationsHelper } from 'hooks/useTranslations';
import { TranslationInput } from 'components/TranslationInput';
import { Colors } from 'styles/colors';

export const EndQuiz = ({
    scenarioForm,
    setScenarioForm,
    translationsHelper,
}: {
    scenarioForm: ScenarioUpdateInput;
    setScenarioForm: (value: ScenarioUpdateInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { translationsHook } = translationsHelper;
    const { deleteTranslation } = translationsHook;

    const endQuiz = scenarioForm.endQuiz;
    const { questions, titleKey } = endQuiz;

    function updateQuestion({ question, index }: { question: QuestionInput; index: number }) {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = question;
        setScenarioForm({ ...scenarioForm, endQuiz: { ...endQuiz, questions: updatedQuestions } });
    }

    function deleteQuestion(indexToDelete: number) {
        if (window.confirm('Voulez vous supprimer la question ?')) {
            const deletedQuestion = questions[indexToDelete];
            deleteTranslation(deletedQuestion.expectedAnswerKey);
            deleteTranslation(deletedQuestion.labelKey);
            const updatedQuestions = [...questions].filter((_, index) => index !== indexToDelete);
            setScenarioForm({ ...scenarioForm, endQuiz: { ...endQuiz, questions: updatedQuestions } });
        }
    }

    return (
        <Card style={{ marginTop: 10, gap: 10 }}>
            <Title>Scenario End Quiz</Title>

            <TranslationInput
                label="Title"
                value={titleKey}
                onChange={(value) => setScenarioForm({ ...scenarioForm, endQuiz: { ...endQuiz, titleKey: value } })}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                }}
            />
            <Label>Questions</Label>
            {questions.map((question, index) => {
                const { blocks, expectedAnswerKey, labelKey } = question;

                return (
                    <PreviewContainer key={`${questions.length}-${index}`}>
                        <Label>Question n°{index + 1}</Label>
                        <Button onClick={() => deleteQuestion(index)} backgroundColor={Colors.secondary.red}>
                            <FaTrash />
                        </Button>
                        <EditableContentBlocks
                            title="Pre-question blocks"
                            blocks={blocks}
                            setBlocks={(newBlocksValue) =>
                                updateQuestion({ index, question: { ...question, blocks: newBlocksValue } })
                            }
                            translationsHelper={{
                                ...translationsHelper,
                                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.blocks`,
                            }}
                        />
                        <TranslationInput
                            label="Label"
                            value={labelKey}
                            onChange={(value) => updateQuestion({ index, question: { ...question, labelKey: value } })}
                            translationsHelper={{
                                ...translationsHelper,
                                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.question.${index}.label`,
                            }}
                        />
                        <TranslationInput
                            label="Expected Answer"
                            value={expectedAnswerKey}
                            onChange={(value) =>
                                updateQuestion({ index, question: { ...question, expectedAnswerKey: value } })
                            }
                            translationsHelper={{
                                ...translationsHelper,
                                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.question.${index}.expectedAnswer`,
                            }}
                        />
                    </PreviewContainer>
                );
            })}

            <BackpackItemAddContainer
                onClick={() =>
                    setScenarioForm({
                        ...scenarioForm,
                        endQuiz: {
                            ...endQuiz,
                            questions: [...questions, { blocks: [], expectedAnswerKey: '', labelKey: '' }],
                        },
                    })
                }
            >
                <FaPlus size={40} />
            </BackpackItemAddContainer>
        </Card>
    );
};

const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const BackpackItemAddContainer = styled.button`
    height: 300px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
`;
