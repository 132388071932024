import { createContext, useContext } from 'react';

export type User = {
    _id: String;
};

type AuthContextType = {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
};

export const AuthContext = createContext<AuthContextType>(null!);

export function useAuth() {
    return useContext(AuthContext);
}
