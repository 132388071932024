import { Title } from 'components/Title';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Header = ({ children, title }: PropsWithChildren<{ title?: string }>) => {
    return (
        <Container>
            <Title>{title}</Title>
            {children}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    box-shadow: 0px 3px 5px 3px rgba(136, 136, 136, 0.2);
    z-index: 1;
`;
