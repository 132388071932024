import { GameLocationFragment, GamePuzzleFragment } from 'data/generated';
import { GamePuzzleDetails } from './GamePuzzleDetails';
import styled from 'styled-components';
import { GameLocationStateTag } from './GameLocationStateTag';
import { useTranslations } from 'hooks/useTranslations';

function orderGamePuzzlesByBlocking(gamePuzzles: GamePuzzleFragment[]) {
    const unblockedPuzzles = gamePuzzles.filter((gamePuzzle) => gamePuzzle.puzzle.blockingPuzzleIds.length === 0);
    let locationsToOrder = gamePuzzles.filter((gamePuzzle) => gamePuzzle.puzzle.blockingPuzzleIds.length > 0);
    const orderedPuzzles = [...unblockedPuzzles];

    let operations = 0;

    while (locationsToOrder.length > 0 && operations < 100) {
        const unblockedPuzzleIds = unblockedPuzzles.map(({ puzzle }) => puzzle._id);
        const newUnblockedPuzzles = locationsToOrder.filter((gamePuzzle) =>
            gamePuzzle.puzzle.blockingPuzzleIds.every((puzzleId) => unblockedPuzzleIds.includes(puzzleId))
        );

        orderedPuzzles.push(...newUnblockedPuzzles);
        unblockedPuzzles.push(...newUnblockedPuzzles);

        const orderedPuzzleIds = orderedPuzzles.map(({ _id }) => _id);
        locationsToOrder = locationsToOrder.filter((gamePuzzle) => !orderedPuzzleIds.includes(gamePuzzle._id));
        operations++;
    }

    return orderedPuzzles;
}

export const GameLocationDetails = ({ gameLocation }: { gameLocation: GameLocationFragment }) => {
    const { translateKey } = useTranslations();

    const {
        scenarioLocation: { title },
        state,
    } = gameLocation;

    const orderedPuzzles = orderGamePuzzlesByBlocking(gameLocation.gamePuzzles);

    return (
        <Container>
            <Header>
                <Title>
                    {translateKey({
                        key: title,
                    })}
                </Title>
                <GameLocationStateTag state={state} />
            </Header>
            <GamePuzzles>
                {orderedPuzzles.map((gamePuzzle) => (
                    <GamePuzzleDetails key={gamePuzzle._id} gamePuzzle={gamePuzzle} />
                ))}
            </GamePuzzles>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

const Title = styled.h2`
    margin: 0;
`;

const GamePuzzles = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`;
