import { TranslationsHelper } from 'hooks/useTranslations';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const PreviewSubtitleBlock = ({
    text,
    translationsHelper,
}: {
    text: string;
    translationsHelper: TranslationsHelper;
}) => {
    const {
        translationsHook: { translateKey },
    } = translationsHelper;

    const textTranslation = translateKey({
        key: text,
    });

    return <Subtitle>{textTranslation}</Subtitle>;
};

const Subtitle = styled.div`
    margin: 0;
    font-size: 20px;
    color: ${Colors.secondary.darkGrey};
`;
