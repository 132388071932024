import { gql } from '@apollo/client';

export const CONTENT_BLOCK_FRAGMENT = gql`
    fragment ContentBlock on ContentBlock {
        ... on TitleBlock {
            type
            text
        }
        ... on SubtitleBlock {
            type
            text
        }
        ... on ParagraphBlock {
            type
            text
        }
        ... on ImageBlock {
            type
            imageKey
        }
        ... on EquationsBlock {
            type
            equations
        }
    }
`;
