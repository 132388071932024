import styled from 'styled-components';

import { ScenarioDifficulty, ScenarioFragment, ScenarioUpdateInput } from 'data/generated';

import { Card, Column, ColumnsContainer, EditableContentBlocks, InfoField, Input, Selector, Title } from 'components';
import { ScenarioStateTag } from 'routes/Scenarios/ScenarioStateTag';
import { isSelectorOptionSingleValue } from 'components/Selector';
import { CommuteStations } from './CommuteStations';
import { TranslationsHelper } from 'hooks/useTranslations';
import { TranslationInput } from 'components/TranslationInput';
import { CitySelector } from './CitySelector';
import { NullableTranslationInput } from 'components/NullableTranslationInput';
import { ImageTranslationInput } from 'components/ImageTranslationInput';
import { PreviewImages } from './PreviewImages';

export const ScenarioInfo = ({
    scenarioForm,
    scenarioInitialInfo,
    setScenarioForm,
    translationsHelper,
}: {
    scenarioForm: ScenarioUpdateInput;
    scenarioInitialInfo: ScenarioFragment;
    setScenarioForm: (value: ScenarioUpdateInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const { imageTranslationsHook } = translationsHelper;
    const { translateImageKey } = imageTranslationsHook;

    const updateScenarioValue = (partialScenario: Partial<ScenarioUpdateInput>) => {
        setScenarioForm({ ...scenarioForm, ...partialScenario });
    };

    const difficultyOptions = [
        {
            value: ScenarioDifficulty.Easy,
            label: ScenarioDifficulty.Easy,
        },
        {
            value: ScenarioDifficulty.Medium,
            label: ScenarioDifficulty.Medium,
        },
        {
            value: ScenarioDifficulty.Hard,
            label: ScenarioDifficulty.Hard,
        },
    ];

    const {
        accessibility,
        cityId,
        conclusionBlocks,
        description,
        difficulty,
        distanceEstimate,
        durationEstimate,
        introductionBlocks,
        meta,
        previewImageKeys,
        price,
        recommendedCommuteStations,
        recommendedHours,
        slug,
        title,
        imageKey,
    } = scenarioForm;

    const image = translateImageKey({ key: imageKey });

    const { state } = scenarioInitialInfo;

    return (
        <Card>
            <CardHeader>
                <Title>Scenario Details</Title>
            </CardHeader>
            <ColumnsContainer numberOfColumns={3}>
                <Column>
                    <ImageTranslationInput
                        label="Image"
                        onChange={(value) => updateScenarioValue({ imageKey: value })}
                        value={imageKey}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.image`,
                        }}
                    />
                    <img
                        src={image.url ?? undefined}
                        height="100%"
                        alt="LocationImage"
                        style={{ objectFit: 'cover' }}
                    />
                </Column>
                <Column>
                    <ScenarioStateTag label="State" state={state} />
                    <CitySelector value={cityId} onChange={(value) => updateScenarioValue({ cityId: value })} />
                    <TranslationInput
                        value={title}
                        label="Title"
                        onChange={(value) => updateScenarioValue({ title: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.title`,
                        }}
                    />
                    <Input
                        value={slug}
                        label="Slug"
                        sublabel="Do NOT forget to add redirections if you ever change this"
                        onChange={(value) => updateScenarioValue({ slug: value })}
                    />

                    <NullableTranslationInput
                        label="Meta title"
                        value={meta.title ?? null}
                        onChange={(value) => updateScenarioValue({ meta: { ...meta, title: value } })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.meta.title`,
                        }}
                    />
                    <NullableTranslationInput
                        label="Meta description"
                        value={meta.description ?? null}
                        onChange={(value) => updateScenarioValue({ meta: { ...meta, description: value } })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.meta.description`,
                        }}
                    />
                    <InfoField label="Image url" value={image.url} />
                    <InfoField label="thumbhash" value={image.thumbhash} />
                    <InfoField label="Aspect ratio" value={image.aspectRatio} />
                </Column>
                <Column>
                    <TranslationInput
                        label="Distance estimate"
                        value={distanceEstimate}
                        onChange={(value) => updateScenarioValue({ distanceEstimate: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.distance`,
                        }}
                    />
                    <TranslationInput
                        label="Duration estimate"
                        value={durationEstimate}
                        onChange={(value) => updateScenarioValue({ durationEstimate: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.duration`,
                        }}
                    />
                    <Selector<ScenarioDifficulty>
                        label="Difficulty"
                        options={difficultyOptions}
                        onChange={(option) =>
                            isSelectorOptionSingleValue(option) && option !== null
                                ? updateScenarioValue({ difficulty: option.value })
                                : undefined
                        }
                        value={difficulty}
                    />
                    <Input
                        value={price}
                        label={`Price (in cents) (equals ${price / 100} €)`}
                        onChange={(value) => updateScenarioValue({ price: Number(value) })}
                    />
                </Column>
            </ColumnsContainer>
            <ColumnsContainer numberOfColumns={3}>
                <Column>
                    <TranslationInput
                        isTextArea
                        label="Description"
                        value={description}
                        onChange={(value) => updateScenarioValue({ description: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.description`,
                        }}
                    />
                </Column>
                <Column>
                    <TranslationInput
                        isTextArea
                        label="Accessibility"
                        value={accessibility}
                        onChange={(value) => updateScenarioValue({ accessibility: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.accessibility`,
                        }}
                    />
                </Column>
                <Column>
                    <TranslationInput
                        isTextArea
                        label="Recommended hours"
                        value={recommendedHours}
                        onChange={(value) => updateScenarioValue({ recommendedHours: value })}
                        translationsHelper={{
                            ...translationsHelper,
                            translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.recommended_hours`,
                        }}
                    />
                </Column>
            </ColumnsContainer>
            <PreviewImages
                onChange={(previewImageKeys) => updateScenarioValue({ previewImageKeys })}
                previewImageKeys={previewImageKeys}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.preview_images`,
                }}
            />
            <CommuteStations
                stations={recommendedCommuteStations}
                onChange={(value) => updateScenarioValue({ recommendedCommuteStations: value })}
            />
            <EditableContentBlocks
                title="Introduction blocks"
                blocks={introductionBlocks}
                setBlocks={(newBlocksValue) => updateScenarioValue({ introductionBlocks: newBlocksValue })}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.introduction`,
                }}
            />
            <EditableContentBlocks
                title="Conclusion blocks"
                blocks={conclusionBlocks}
                setBlocks={(newBlocksValue) => updateScenarioValue({ conclusionBlocks: newBlocksValue })}
                translationsHelper={{
                    ...translationsHelper,
                    translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.conclusion`,
                }}
            />
        </Card>
    );
};

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
