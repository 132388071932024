import { PropsWithChildren } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Colors } from 'styles/colors';

export const Card = ({ children, style }: PropsWithChildren<{ style?: CSSProperties; title?: string }>) => {
    return <CardContainer style={style}>{children}</CardContainer>;
};

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    padding: 15px;
    border: 1px solid ${Colors.secondary.lightGrey};
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    gap: 15px;
`;
