import { PuzzleTemplateInput } from 'data/generated';

import { Title } from 'components';
import { NavigateAndFindPuzzle } from 'routes/ScenarioLocations/Puzzles/NavigateAndFindPuzzle';
import { ContentPuzzle } from 'routes/ScenarioLocations/Puzzles/ContentPuzzle';

import {
    isContentPuzzleTemplate,
    isHotAndColdPuzzleTemplate,
    isNavigateAndFindPuzzleTemplate,
} from 'routes/ScenarioLocations/Puzzles/utils';
import styled from 'styled-components';
import { HotAndColdPuzzleTemplate } from 'routes/ScenarioLocations/Puzzles/HotAndColdPuzzleTemplate';
import { TranslationsHelper } from 'hooks/useTranslations';

export const TemplateSection = ({
    template,
    setTemplate,
    translationsHelper,
}: {
    template: PuzzleTemplateInput;
    setTemplate: (value: PuzzleTemplateInput) => void;
    translationsHelper: TranslationsHelper;
}) => {
    return (
        <TemplateContainer>
            <TemplateTitle>Template section ({template.type})</TemplateTitle>
            {isNavigateAndFindPuzzleTemplate(template) ? (
                <NavigateAndFindPuzzle
                    template={template}
                    setTemplate={setTemplate}
                    translationsHelper={translationsHelper}
                />
            ) : isHotAndColdPuzzleTemplate(template) ? (
                <HotAndColdPuzzleTemplate
                    template={template}
                    setTemplate={setTemplate}
                    translationsHelper={translationsHelper}
                />
            ) : isContentPuzzleTemplate(template) ? (
                <ContentPuzzle template={template} setTemplate={setTemplate} translationsHelper={translationsHelper} />
            ) : null}
        </TemplateContainer>
    );
};

const TemplateContainer = styled.div`
    margin-top: 5px;
    padding-top: 15px;
    border-top: 1px solid lightgrey;
`;

const TemplateTitle = styled(Title)`
    margin-bottom: 10px;
`;
