import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import { Home } from './routes/Home';
import { Login } from './routes/Login';

import { AuthContext } from 'contexts/AuthContext';
import { darkTheme, lightTheme } from 'styles/themes';
import { useVerifyTokenMutation } from 'data/generated';
import { FullPageLoader } from 'components';
import { TranslationParametersContext } from 'contexts/TranslationParametersContext';

export default function App() {
    const [user, setUser] = useState<any>(null);
    const [themeMode] = useState('light');

    const [displayEnglish, setDisplayEnglish] = useState<boolean>(true);
    const [displayFrench, setDisplayFrench] = useState<boolean>(true);
    const [displayKey, setDisplayKey] = useState<boolean>(true);

    const [useMarkdownEditor, setUseMarkdownEditor] = useState<boolean>(false);

    const [keyPrefixCity, setKeyPrefixCity] = useState<string>('');
    const [keyPrefixLocation, setKeyPrefixLocation] = useState<string>('');

    const [loading, setLoading] = useState(true);

    const [verifyTokenMutation] = useVerifyTokenMutation();

    useEffect(() => {
        const checkAuthToken = async () => {
            const { data } = await verifyTokenMutation();

            if (data?.verifyToken?.admin) {
                setUser(data.verifyToken.admin);
            }
            setLoading(false);
        };

        checkAuthToken();
    }, [verifyTokenMutation, setUser]);

    if (loading) {
        return <FullPageLoader />;
    }

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            <TranslationParametersContext.Provider
                value={{
                    displayEnglish,
                    displayFrench,
                    displayKey,
                    setDisplayEnglish,
                    setDisplayFrench,
                    setDisplayKey,
                    keyPrefixCity,
                    keyPrefixLocation,
                    setKeyPrefixCity,
                    setKeyPrefixLocation,
                    useMarkdownEditor,
                    setUseMarkdownEditor,
                }}
            >
                <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
                    <AppContainer>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/*" element={<Home />} />
                        </Routes>
                    </AppContainer>
                </ThemeProvider>
            </TranslationParametersContext.Provider>
        </AuthContext.Provider>
    );
}

const AppContainer = styled.div`
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;
