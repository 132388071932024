import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { Button, Label } from 'components';
import { TranslationsHelper } from 'hooks/useTranslations';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { useTranslationParameters } from 'contexts/TranslationParametersContext';
import { NullableInput } from './NullableInput';
import { ImageTranslationUpload } from './ImageTranslationUpload';
import { ImageInput } from 'data/generated';
import { toast } from 'react-toastify';

export const ImageTranslationInput = ({
    label,
    sublabel,
    name,
    value,
    onChange,
    disabled = false,
    translationsHelper,
    previousImplementationImage,
}: {
    label?: string;
    sublabel?: string;
    name?: string;
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    translationsHelper: TranslationsHelper;
    previousImplementationImage?: ImageInput | null;
}) => {
    const { imageTranslationsHook, translationsHook, translationsKeyPrefix, isGlobal, sectionId } = translationsHelper;
    const { getTranslationFromKey } = translationsHook;
    const {
        getImageTranslationFromKey,
        generateUniqueImageTranslationKey,
        createEmptyImageTranslationFromKey,
        updateImageTranslation,
        doesImageKeyExist,
        areImageTranslationsSaved,
    } = imageTranslationsHook;
    const { displayEnglish, displayFrench, displayKey } = useTranslationParameters();

    const [newImageKey, setNewImageKey] = useState<string>(value);

    const imageTranslation =
        getImageTranslationFromKey(value) ?? createEmptyImageTranslationFromKey({ isGlobal, key: value, sectionId });

    useEffect(() => {
        if (previousImplementationImage && !value) {
            toast.warning('You have an image without Image Translations');
        }
    }, [previousImplementationImage, value]);

    return (
        <Container areImageTranslationsSaved={areImageTranslationsSaved}>
            {label && <Label>{label}</Label>}
            {sublabel && <Sublabel>{sublabel}</Sublabel>}

            {!imageTranslation?.key ? (
                <Button
                    onClick={() => {
                        onChange(generateUniqueImageTranslationKey()); // for new imageTranslations it's better to not have the value ''. It's better to generate an 'unique' key
                    }}
                >
                    Ajouter
                </Button>
            ) : (
                <>
                    {displayKey ? (
                        <InputContainer>
                            <Sublabel>{value}</Sublabel>
                            ➡️
                            <StyledInput
                                value={newImageKey}
                                name={name}
                                onChange={(e) => setNewImageKey(e.target.value)}
                                disabled={disabled}
                                style={
                                    newImageKey !== value && doesImageKeyExist(newImageKey)
                                        ? { color: Colors.secondary.red }
                                        : {}
                                }
                            />
                            <KeyGenerationButton
                                onClick={() => {
                                    setNewImageKey(translationsKeyPrefix);
                                }}
                            >
                                💡
                            </KeyGenerationButton>
                            {previousImplementationImage?.url ? (
                                <HydratationButton
                                    onClick={() => {
                                        const captionTranslation = previousImplementationImage.caption
                                            ? getTranslationFromKey(previousImplementationImage.caption)
                                            : null;
                                        updateImageTranslation({
                                            ...imageTranslation,
                                            french: {
                                                ...imageTranslation.french,
                                                url: previousImplementationImage.url,
                                                aspectRatio: previousImplementationImage.aspectRatio,
                                                caption: captionTranslation?.french ?? null,
                                                thumbhash: previousImplementationImage.thumbhash,
                                            },
                                            english: {
                                                ...imageTranslation.english,
                                                caption: captionTranslation?.english ?? null,
                                            },
                                        });
                                    }}
                                >
                                    📥
                                </HydratationButton>
                            ) : null}
                            <Button
                                onClick={() => {
                                    updateImageTranslation({ ...imageTranslation, key: newImageKey });
                                    onChange(newImageKey);
                                }}
                                disabled={doesImageKeyExist(newImageKey)}
                            >
                                Changer
                            </Button>
                            <Switch
                                onChange={() =>
                                    updateImageTranslation({
                                        ...imageTranslation,
                                        isGlobal: !imageTranslation.isGlobal,
                                    })
                                }
                                checked={imageTranslation.isGlobal}
                                onColor={Colors.secondary.green}
                                offColor={Colors.secondary.lightGrey}
                            />
                            {imageTranslation.isGlobal ? 'Global' : null}
                        </InputContainer>
                    ) : null}

                    <ImagesContainer>
                        {displayFrench ? (
                            <ImageFormContainer>
                                <Sublabel>Image FR 🇫🇷</Sublabel>
                                <ImageTranslationUpload
                                    image={imageTranslation.french}
                                    setImage={(french) => updateImageTranslation({ ...imageTranslation, french })}
                                />
                                <Sublabel>Caption FR 🇫🇷</Sublabel>
                                <InputContainer>
                                    <NullableInput
                                        value={imageTranslation.french.caption}
                                        name={name}
                                        onChange={(newValue) =>
                                            updateImageTranslation({
                                                ...imageTranslation,
                                                french: { ...imageTranslation.french, caption: newValue },
                                            })
                                        }
                                        disabled={disabled}
                                    />
                                </InputContainer>
                            </ImageFormContainer>
                        ) : null}

                        {displayEnglish ? (
                            <ImageFormContainer>
                                <Sublabel>Image EN 🇬🇧</Sublabel>
                                <ImageTranslationUpload
                                    image={imageTranslation.english}
                                    setImage={(english) => updateImageTranslation({ ...imageTranslation, english })}
                                />
                                <Sublabel>Caption EN 🇬🇧</Sublabel>
                                <InputContainer>
                                    <NullableInput
                                        value={imageTranslation.english.caption}
                                        name={name}
                                        onChange={(newValue) =>
                                            updateImageTranslation({
                                                ...imageTranslation,
                                                english: { ...imageTranslation.english, caption: newValue },
                                            })
                                        }
                                        disabled={disabled}
                                    />
                                </InputContainer>
                            </ImageFormContainer>
                        ) : null}
                    </ImagesContainer>
                </>
            )}
        </Container>
    );
};

const Container = styled.div<{ areImageTranslationsSaved: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    border: 1px solid
        ${({ areImageTranslationsSaved }) =>
            areImageTranslationsSaved ? Colors.secondary.lightGrey : Colors.secondary.red};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    padding: 5px;
`;

const Sublabel = styled.span`
    margin-top: 10px;
`;

const StyledInput = styled.input`
    flex-grow: 1;
    font-size: 16px;
    padding: 10px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border: 1px solid ${Colors.secondary.lightGrey};
    :focus {
        outline: none;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;
`;

const KeyGenerationButton = styled.div`
    cursor: pointer;
`;

const HydratationButton = styled.div`
    cursor: pointer;
`;

const ImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-around;
`;

const ImageFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
