export const Colors = {
    primary: {
        black: '#222222',
        white: '#ffffff',
        lightGrey: '#f9f9f9',
    },
    secondary: {
        blue: '#537FE7',
        green: '#2db92d',
        darkGrey: '#555555',
        lightGrey: '#DCDCDC',
        mediumGrey: '#bbbbbb',
        red: '#DC143C',
        yellow: '#E6CC00',
    },
};
