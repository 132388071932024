import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Button = styled.button<{ backgroundColor?: string }>`
    padding: 10px 25px 10px 25px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.backgroundSecondary}f0;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border: none;
    :hover {
        background-color: ${({ theme }) => theme.colors.backgroundSecondary};
        cursor: pointer;
    }

    :disabled {
        background-color: ${Colors.secondary.lightGrey};
        pointer-events: none;
    }
`;
