import styled from 'styled-components';
import Logo from '../assets/logo.png';

export const Loader = () => {
    return (
        <LoaderContainer>
            <Spinner className="spin">
                <img src={Logo} alt="Enigmo Loader" width={100} height={100} style={{ borderRadius: 10 }} />
            </Spinner>
        </LoaderContainer>
    );
};

const LoaderContainer = styled.div`
    position: relative;
    width: 100px;
    height: 100px;

    .spin {
        animation-timing-function: linear;
    }

    @keyframes spin {
        20% {
            transform: scale(1, 1);
        }
        25% {
            transform: rotate(90deg);
        }
        45% {
            transform: rotate(90deg);
        }
        50% {
            transform: rotate(180deg);
        }
        70% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(270deg);
        }
        95% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Spinner = styled.div`
    animation-name: spin;
    animation-duration: 4s;
    animation-iteration-count: infinite;
`;
