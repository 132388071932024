import { gql } from '@apollo/client';

export const IMAGE_FRAGMENT = gql`
    fragment Image on Image {
        aspectRatio
        caption
        thumbhash
        url
    }
`;

export const NULLABLE_IMAGE_FRAGMENT = gql`
    fragment NullableImage on NullableImage {
        aspectRatio
        caption
        thumbhash
        url
    }
`;
