import { useGetCollectionQuery } from 'data/generated';
import styled from 'styled-components';
import { CollectionStateTag } from './CollectionStateTag';
import { useParams } from 'react-router-dom';
import { CollectionItems } from './CollectionItems';

export const CollectionDetails = () => {
    const { collectionId } = useParams<{ collectionId: string }>() as { collectionId: string };

    const { data } = useGetCollectionQuery({ variables: { collectionId } });

    if (!data) {
        return null;
    }

    const { collection } = data;

    const { name, state } = collection;

    return (
        <Container>
            <Header>
                <Title>{name}</Title>
                <CollectionStateTag state={state} />
            </Header>
            <ItemsContainer>
                <CollectionItems collection={collection} />
            </ItemsContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

const Title = styled.h1`
    margin: 0;
`;

const ItemsContainer = styled.div`
    flex: 1;
    display: flex;
    gap: 10px;
    overflow: hidden;
`;
