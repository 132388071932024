import { GamePuzzleFragment, GamePuzzleState, useUpdateGamePuzzleStateMutation } from 'data/generated';
import styled from 'styled-components';
import { GamePuzzleStateTag } from './GamePuzzleStateTag';
import { Button, Selector } from 'components';
import { useState } from 'react';
import { isSelectorOptionSingleValue } from 'components/Selector';
import { getFormattedDateAndTime } from 'utils/time-formatting';
import { useTranslations } from 'hooks/useTranslations';

export const GamePuzzleDetails = ({ gamePuzzle }: { gamePuzzle: GamePuzzleFragment }) => {
    const { translateKey } = useTranslations();

    const {
        _id: gamePuzzleId,
        completedAt,
        guesses,
        numberOfUsedHints,
        puzzle: { title },
        startedAt,
        state,
    } = gamePuzzle;

    const [newState, setNewState] = useState<GamePuzzleState | null>(null);

    const [updateGamePuzzleStateMutation] = useUpdateGamePuzzleStateMutation();

    function updateState() {
        if (newState) {
            updateGamePuzzleStateMutation({ variables: { gamePuzzleId, state: newState } });
            setNewState(null);
        }
    }

    return (
        <Container>
            <Header>
                <Title>
                    {translateKey({
                        key: title,
                    })}
                </Title>
                <GamePuzzleStateTag state={state} />
            </Header>
            <span>ID: {gamePuzzleId}</span>
            <span>Start date: {startedAt ? getFormattedDateAndTime(new Date(startedAt)) : '-'}</span>
            <span>Completion date: {completedAt ? getFormattedDateAndTime(new Date(completedAt)) : '-'}</span>
            <span>Number of hints: {numberOfUsedHints}</span>
            <span>Number of guesses: {guesses.length}</span>
            <Footer>
                <Selector
                    options={[
                        { label: GamePuzzleState.Locked, value: GamePuzzleState.Locked },
                        { label: GamePuzzleState.Unlocked, value: GamePuzzleState.Unlocked },
                        { label: GamePuzzleState.Completed, value: GamePuzzleState.Completed },
                    ]}
                    onChange={(option) =>
                        isSelectorOptionSingleValue(option) && option !== null ? setNewState(option.value) : undefined
                    }
                />
                <Button onClick={updateState} disabled={!newState}>
                    Update
                </Button>
            </Footer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc((100% - 20px) / 3);
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
    gap: 5px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.h3`
    margin: 0;
`;

const Footer = styled.div`
    display: flex;
    gap: 5px;
`;
