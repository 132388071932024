import { useApolloClient } from '@apollo/client';
import { GetScenarioDetailsQuery, LandmarkFragment } from 'data/generated';
import { GET_SCENARIO_DETAILS_QUERY } from 'data/queries/scenario';

export const useLandmarkHandlers = () => {
    const apolloClient = useApolloClient();

    function landmarkAddedHandler(landmark: LandmarkFragment) {
        const { scenarioId } = landmark;

        const data = apolloClient.readQuery<GetScenarioDetailsQuery>({
            query: GET_SCENARIO_DETAILS_QUERY,
            variables: { scenarioId },
        });

        if (!data) {
            return;
        }

        const { scenario } = data;
        const { landmarks } = scenario;

        apolloClient.writeQuery<GetScenarioDetailsQuery>({
            query: GET_SCENARIO_DETAILS_QUERY,
            variables: { scenarioId },
            data: { scenario: { ...scenario, landmarks: [...landmarks, landmark] } },
        });
    }

    return { landmarkAddedHandler };
};
