import styled from 'styled-components';
import { MdArrowDownward, MdArrowUpward, MdDelete } from 'react-icons/md';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { TranslationsHelper } from 'hooks/useTranslations';
import { TranslationInput } from 'components/TranslationInput';

export const EditableTextBlock = ({
    text,
    label,
    onChange,
    moveDownBlock,
    moveUpBlock,
    deleteBlock,
    translationsHelper,
}: {
    text: string;
    label: string;
    onChange: (value: string) => void;
    moveDownBlock: () => void;
    moveUpBlock: () => void;
    deleteBlock: () => void;
    translationsHelper: TranslationsHelper;
}) => {
    const {
        translationsHook: { deleteTranslation },
    } = translationsHelper;

    return (
        <Container>
            <ActionsContainer>
                <Title>{label}</Title>
                <IconButton onClick={moveUpBlock}>
                    <MdArrowUpward size={15} />
                </IconButton>
                <IconButton onClick={moveDownBlock}>
                    <MdArrowDownward size={15} />
                </IconButton>
                <IconButton
                    onClick={() => {
                        deleteBlock();
                        deleteTranslation(text);
                    }}
                >
                    <MdDelete size={15} />
                </IconButton>
            </ActionsContainer>
            <TranslationInput
                isTextArea
                isMarkdown
                value={text}
                onChange={(value) => (value ? onChange(value) : undefined)}
                translationsHelper={translationsHelper}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(Label)`
    flex-grow: 1;
`;

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-left: 10px;
    padding: 0;
    width: 30px;
    height: 30px;
`;
