import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';

import { useCallback, useState } from 'react';
import styled from 'styled-components';

const center = {
    lat: 48.8575943077439,
    lng: 2.341761833549674,
};

export type Path = { lat: number; lng: number }[];

export enum LocationType {
    Point = 'Point',
    Polygon = 'Polygon',
}

export const LocationSelector = ({
    allowedTypes,
    path,
    setPath,
    initialMapBounds,
}: {
    allowedTypes: LocationType[];
    path: Path;
    setPath: (path: Path) => void;
    initialMapBounds?: google.maps.LatLngBounds;
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDSwTo3v5o77PoHFlyMTLgtWBgXVDB_oDY',
    });

    const isPointAllowed = allowedTypes.includes(LocationType.Point);
    const isPolygonAllowed = allowedTypes.includes(LocationType.Polygon);

    function onClick({ latLng }: google.maps.MapMouseEvent) {
        if (!latLng) {
            return;
        }

        const lat = latLng.lat();
        const lng = latLng.lng();

        if (isPolygonAllowed) {
            setPath([...path, { lat, lng }]);
            return;
        }

        setPath([{ lat, lng }]);
    }

    const [, setMap] = useState<google.maps.Map | null>(null);
    const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);

    const onMapLoad = useCallback(
        function callback(map: google.maps.Map) {
            setMap(map);
            if (initialMapBounds) {
                map.fitBounds(initialMapBounds);
            } else if (path.length) {
                const { minLat, minLng, maxLat, maxLng } = path.reduce(
                    (acc, point) => {
                        return {
                            minLat: Math.min(acc.minLat, point.lat),
                            minLng: Math.min(acc.minLng, point.lng),
                            maxLat: Math.max(acc.maxLat, point.lat),
                            maxLng: Math.max(acc.maxLng, point.lng),
                        };
                    },
                    { minLat: 10000, minLng: 10000, maxLat: 0, maxLng: 0 }
                );

                const coordinatesDelta = 0.0003;

                const pathBounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(minLat - coordinatesDelta, minLng - coordinatesDelta),
                    new google.maps.LatLng(maxLat + coordinatesDelta, maxLng + coordinatesDelta)
                );

                map.fitBounds(pathBounds);
            }
        },
        [initialMapBounds, path]
    );

    const onMapUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    const onPolygonLoad = useCallback(function callback(polygon: google.maps.Polygon) {
        setPolygon(polygon);
    }, []);

    const onPolygonUnmount = useCallback(function callback() {
        setPolygon(null);
    }, []);

    const onMarkerLoad = useCallback(function callback(marker: google.maps.Marker) {
        setMarker(marker);
    }, []);

    const onMarkerUnmount = useCallback(function callback() {
        setMarker(null);
    }, []);

    const updatePath = useCallback(() => {
        if (polygon) {
            const nextPath = polygon
                .getPath()
                .getArray()
                .map((latLng) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
        }
    }, [setPath, polygon]);

    const updateMarkerPosition = useCallback(() => {
        if (marker) {
            const position = marker.getPosition();
            if (position) {
                setPath([{ lat: position.lat(), lng: position.lng() }]);
            }
        }
    }, [setPath, marker]);

    return (
        <MapContainer>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={center}
                    zoom={12}
                    onClick={onClick}
                    onLoad={onMapLoad}
                    onUnmount={onMapUnmount}
                    options={{ draggableCursor: 'crosshair', gestureHandling: 'cooperative' }}
                >
                    {path.length === 1 && isPointAllowed ? (
                        <Marker
                            onLoad={onMarkerLoad}
                            onUnmount={onMarkerUnmount}
                            position={path[0]}
                            draggable
                            onDragEnd={updateMarkerPosition}
                        />
                    ) : (
                        <Polygon
                            path={path}
                            draggable
                            editable
                            onDragEnd={updatePath}
                            onLoad={onPolygonLoad}
                            onUnmount={onPolygonUnmount}
                            onMouseUp={updatePath}
                            options={{
                                fillColor: 'rgba(0, 120, 240, 0.3)',
                                strokeColor: 'rgb(0, 120, 240)',
                                strokeWeight: 3,
                            }}
                        />
                    )}
                </GoogleMap>
            ) : (
                <></>
            )}
        </MapContainer>
    );
};

const MapContainer = styled.div`
    width: 600px;
    height: 400px;
    border: 1px solid lightgrey;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    overflow: hidden;
`;
