import {
    AugmentedReality,
    AutoPlayingSound,
    ButtonBlock,
    CameraLayer,
    Carousel,
    CesarCode,
    ColorLetters,
    CombinationPadlock,
    ContentTemplate,
    Digicode,
    FourDolphins,
    GalerieColbert,
    HideAndSeek,
    HotAndColdTemplate,
    ImageAreas,
    ImagesOrdering,
    ImagesSelection,
    ImagesSorting,
    InputBlock,
    KeyboardType,
    LightBulbs,
    MorseCode,
    MultiSelect,
    MusicSheet,
    NavigateAndFindTemplate,
    Paving,
    PhotoPuzzle,
    PuzzleTemplate,
    PuzzleTemplateInput,
    PuzzleType,
    Rotonde,
    Select,
    SimonSays,
    Skyline,
    SubmitBlock,
    SubmitBlockInput,
    SubmitBlockType,
    Sundial,
    WineCellar,
} from 'data/generated';

// Puzzle template typeguards

export function isContentPuzzleTemplate(template: PuzzleTemplateInput): template is ContentTemplate {
    return template.type === PuzzleType.Content;
}

export function isNavigateAndFindPuzzleTemplate(template: PuzzleTemplateInput): template is NavigateAndFindTemplate {
    return template.type === PuzzleType.NavigateAndFind;
}

export function isHotAndColdPuzzleTemplate(template: PuzzleTemplateInput): template is HotAndColdTemplate {
    return template.type === PuzzleType.HotAndCold;
}

export function getInitialTemplateForType(type: PuzzleType): PuzzleTemplate {
    switch (type) {
        case PuzzleType.Content:
            return { type: PuzzleType.Content, blocks: [] } as ContentTemplate;
        case PuzzleType.HotAndCold:
            return {
                type: PuzzleType.HotAndCold,
                blocks: [],
                targetLocation: { latitude: 0, longitude: 0 },
            } as HotAndColdTemplate;
        case PuzzleType.NavigateAndFind:
            return {
                type: PuzzleType.NavigateAndFind,
                locationPoints: [],
                blocks: [],
                imageKey: '',
            } as NavigateAndFindTemplate;
    }
}

// Submit block typeguards

export function isAugmentedRealitySubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.AugmentedReality;
}

export function isAutoPlayingSoundSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.AutoPlayingSound;
}

export function isButtonSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Button;
}

export function isCameraLayerPuzzleTemplate(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.CameraLayer;
}

export function isCarouselSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Carousel;
}

export function isCesarCodeSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.CesarCode;
}

export function isColorLettersSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.ColorLetters;
}

export function isCombinationPadlockSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.CombinationPadlock;
}

export function isDigicodeSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Digicode;
}

export function isFourDolphinsSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.FourDolphins;
}

export function isGalerieColbertSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.GalerieColbert;
}

export function isHideAndSeekSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.HideAndSeek;
}

export function isImageAreasSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.ImageAreas;
}

export function isImagesOrderingSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.ImagesOrdering;
}

export function isImagesSelectionSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.ImagesSelection;
}

export function isImagesSortingSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.ImagesSorting;
}

export function isInputSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Input;
}

export function isLightBulbsSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.LightBulbs;
}

export function isMorseCodeSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.MorseCode;
}

export function isMultiSelectSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.MultiSelect;
}

export function isMusicSheetSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.MusicSheet;
}

export function isRotondeSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Rotonde;
}

export function isPavingSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Paving;
}
export function isPhotoPuzzleSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.PhotoPuzzle;
}

export function isSelectSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Select;
}

export function isSimonSaysSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.SimonSays;
}

export function isSkylineSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Skyline;
}

export function isSundialSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.Sundial;
}

export function isWineCellarSubmitBlock(submitBlockInput: SubmitBlockInput | null) {
    return submitBlockInput?.type === SubmitBlockType.WineCellar;
}

export const getInitialSubmitBlockForType = (type: SubmitBlockType): SubmitBlock => {
    switch (type) {
        case SubmitBlockType.AugmentedReality:
            return { type: SubmitBlockType.AugmentedReality, anchorImages: [] } as AugmentedReality;
        case SubmitBlockType.AutoPlayingSound:
            return { type: SubmitBlockType.AutoPlayingSound } as AutoPlayingSound;
        case SubmitBlockType.Button:
            return { type: SubmitBlockType.Button, label: '' } as ButtonBlock;
        case SubmitBlockType.CameraLayer:
            return { type: SubmitBlockType.CameraLayer, layerImageUrl: '' } as CameraLayer;
        case SubmitBlockType.Carousel:
            return {
                type: SubmitBlockType.Carousel,
                answerImageUrl: '',
                wrongImageUrls: [],
                imagesSize: 0,
            } as Carousel;
        case SubmitBlockType.CesarCode:
            return { type: SubmitBlockType.CesarCode, stringCode: '', plainText: '' } as CesarCode;
        case SubmitBlockType.ColorLetters:
            return { type: SubmitBlockType.ColorLetters, colorLetters: [] } as ColorLetters;
        case SubmitBlockType.CombinationPadlock:
            return { type: SubmitBlockType.CombinationPadlock } as CombinationPadlock;
        case SubmitBlockType.Digicode:
            return { type: SubmitBlockType.Digicode } as Digicode;
        case SubmitBlockType.FourDolphins:
            return { type: SubmitBlockType.FourDolphins } as FourDolphins;
        case SubmitBlockType.ImageAreas:
            return {
                type: SubmitBlockType.ImageAreas,
                imageKey: '',
                numberOfRows: 0,
                numberOfColumns: 0,
                answerCells: [],
            } as ImageAreas;
        case SubmitBlockType.ImagesOrdering:
            return { type: SubmitBlockType.ImagesOrdering, imageItems: [] } as ImagesOrdering;
        case SubmitBlockType.ImagesSelection:
            return { type: SubmitBlockType.ImagesSelection, imageItems: [] } as ImagesSelection;
        case SubmitBlockType.Input:
            return {
                type: SubmitBlockType.Input,
                inputLabel: '',
                keyboardType: KeyboardType.Default,
                placeholder: '',
            } as InputBlock;
        case SubmitBlockType.GalerieColbert:
            return { type: SubmitBlockType.GalerieColbert } as GalerieColbert;
        case SubmitBlockType.HideAndSeek:
            return { type: SubmitBlockType.HideAndSeek, hidingImageKey: '', hiddenImageKey: '' } as HideAndSeek;
        case SubmitBlockType.LightBulbs:
            return { type: SubmitBlockType.LightBulbs, imageOnUrl: '', imageOffUrl: '' } as LightBulbs;
        case SubmitBlockType.MorseCode:
            return { type: SubmitBlockType.MorseCode, code: '' } as MorseCode;
        case SubmitBlockType.MultiSelect:
            return { type: SubmitBlockType.MultiSelect, answerIndices: [], optionLabels: [] } as MultiSelect;
        case SubmitBlockType.MusicSheet:
            return { type: SubmitBlockType.MusicSheet, sequencesToGuess: [] } as MusicSheet;
        case SubmitBlockType.Paving:
            return {
                type: SubmitBlockType.Paving,
                numberOfColumns: 1,
                numberOfRows: 1,
                pavingImageKeys: [],
                pavingBottonImageKey: null,
            } as Paving;
        case SubmitBlockType.PhotoPuzzle:
            return {
                type: SubmitBlockType.PhotoPuzzle,
                numberOfColumns: 1,
                numberOfRows: 1,
                photoPuzzleImageKey: '',
            } as PhotoPuzzle;
        case SubmitBlockType.Rotonde:
            return { type: SubmitBlockType.Rotonde } as Rotonde;
        case SubmitBlockType.Select:
            return { type: SubmitBlockType.Select, options: [] } as Select;
        case SubmitBlockType.SimonSays:
            return { type: SubmitBlockType.SimonSays, sequence: [] } as SimonSays;
        case SubmitBlockType.Skyline:
            return { type: SubmitBlockType.Skyline, skylineOptions: [], expectedSequence: [] } as Skyline;
        case SubmitBlockType.Sundial:
            return { type: SubmitBlockType.Sundial } as Sundial;
        case SubmitBlockType.WineCellar:
            return { type: SubmitBlockType.WineCellar } as WineCellar;
        case SubmitBlockType.ImagesSorting:
            return { type: SubmitBlockType.ImagesSorting, imageItems: [] } as ImagesSorting;
    }
};
