import styled, { useTheme } from 'styled-components';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Label } from './Label';
import { TranslationsHelper } from 'hooks/useTranslations';
import { TranslationInput } from './TranslationInput';
import { NullableTranslationInput } from './NullableTranslationInput';
import { ImageTranslationInput } from './ImageTranslationInput';

type TextWithImage = {
    title?: string | null;
    text: string;
    imageKey?: string | null;
};

export const TranslationArrayInputWithImage = ({
    label,
    values,
    setValues,
    translationsHelper,
}: {
    label?: string;
    values: TextWithImage[];
    setValues: (values: TextWithImage[]) => void;
    translationsHelper: TranslationsHelper;
}) => {
    const theme = useTheme();

    const { translationsHook, imageTranslationsHook } = translationsHelper;
    const { generateUniqueTranslationKey, deleteTranslation } = translationsHook;
    const { deleteImageTranslation } = imageTranslationsHook;

    return (
        <Container>
            {label ? <Label>{label}</Label> : null}
            {values.map((value, index) => (
                <InputWithImage key={values.length + index}>
                    <InputsContainer>
                        <NullableTranslationInput
                            label='Title (Default: "Le saviez-vous ?")'
                            value={value.title ?? null}
                            onChange={(newValue) =>
                                setValues(
                                    values.map((prevValue, valueIndex) =>
                                        index === valueIndex ? { ...prevValue, title: newValue } : prevValue
                                    )
                                )
                            }
                            translationsHelper={{
                                ...translationsHelper,
                                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${index}.title`,
                            }}
                        />
                        <TranslationInput
                            isTextArea
                            label="Body"
                            value={value.text}
                            onChange={(newValue) =>
                                setValues(
                                    values.map((prevValue, valueIndex) =>
                                        index === valueIndex ? { ...prevValue, text: newValue } : prevValue
                                    )
                                )
                            }
                            translationsHelper={{
                                ...translationsHelper,
                                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${index}.body`,
                            }}
                        />
                        <ImageTranslationInput
                            label="Image"
                            onChange={(newImageKey) =>
                                setValues(
                                    values.map((prevValue, valueIndex) =>
                                        index === valueIndex ? { ...prevValue, imageKey: newImageKey } : prevValue
                                    )
                                )
                            }
                            value={value.imageKey ?? ''}
                            translationsHelper={{
                                ...translationsHelper,
                                translationsKeyPrefix: `${translationsHelper.translationsKeyPrefix}.${index}.image`,
                            }}
                        />
                    </InputsContainer>
                    <ButtonContainer
                        backgroundColor={theme.colors.backgroundError}
                        onClick={() => {
                            setValues(values.filter((_value, valueIndex) => valueIndex !== index));
                            deleteTranslation(value.title);
                            deleteTranslation(value.text);
                            deleteImageTranslation(value.imageKey);
                        }}
                    >
                        <FaTrash color="white" />
                    </ButtonContainer>
                </InputWithImage>
            ))}
            <Row>
                <ButtonContainer
                    backgroundColor={theme.colors.backgroundSecondary}
                    onClick={() =>
                        setValues([...values, { title: null, text: generateUniqueTranslationKey(), imageKey: '' }])
                    }
                >
                    <FaPlus color="white" />
                </ButtonContainer>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const InputWithImage = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-left: 7px solid #222222;
    border-radius: 7px;
    padding-left: 5px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const ButtonContainer = styled.div<{ backgroundColor: string }>`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
