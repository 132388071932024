import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import * as ThumbHash from 'thumbhash';
import type { FileWithPath } from 'react-dropzone';
import { arrayBufferToBase64, base64ToArrayBuffer } from 'utils/conversion';
import { ImageInput } from 'data/generated';

async function getAspectRatio(imageUrl: string) {
    const imageHtml = new Image();
    imageHtml.src = imageUrl;
    await new Promise((resolve) => (imageHtml.onload = resolve));
    return imageHtml.width / imageHtml.height;
}

export const ImageTranslationUpload = ({
    image,
    setImage,
    hasVerticalDisplay = false,
    isSmall = false,
}: {
    image: ImageInput;
    setImage: (image: ImageInput) => void;
    hasVerticalDisplay?: boolean;
    isSmall?: boolean;
}) => {
    const [isImageHovered, setIsImageHovered] = useState<boolean>(false);

    const onDrop = useCallback(
        async (acceptedFiles: FileWithPath[]) => {
            const file = acceptedFiles[0];

            const originalURL = URL.createObjectURL(file);
            const imageHtml = new Image();
            imageHtml.src = originalURL;
            await new Promise((resolve) => (imageHtml.onload = resolve));
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) {
                return;
            }

            const scale = 100 / Math.max(imageHtml.width, imageHtml.height);
            canvas.width = Math.round(imageHtml.width * scale);
            canvas.height = Math.round(imageHtml.height * scale);
            context.drawImage(imageHtml, 0, 0, canvas.width, canvas.height);
            const pixels = context.getImageData(0, 0, canvas.width, canvas.height);
            const binaryThumbHash = ThumbHash.rgbaToThumbHash(pixels.width, pixels.height, pixels.data);
            const aspectRatio = canvas.width / canvas.height;

            setImage({
                file,
                aspectRatio,
                caption: image?.caption ?? null,
                url: URL.createObjectURL(file),
                thumbhash: arrayBufferToBase64(binaryThumbHash),
                type: file.type,
            });
        },
        [image, setImage]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

    useEffect(() => {
        async function computeAspectRatio(imageInput?: ImageInput | null) {
            if (imageInput?.url && !imageInput?.aspectRatio) {
                const newAspectRatio = await getAspectRatio(imageInput.url);
                setImage({ ...imageInput, aspectRatio: newAspectRatio });
            }
        }

        computeAspectRatio(image);
    }, [setImage, image]);

    return (
        <Container>
            <ImageContainer hasVerticalDisplay={hasVerticalDisplay}>
                <FileContainer>
                    <UploadContainer
                        {...getRootProps()}
                        onMouseEnter={() => setIsImageHovered(true)}
                        onMouseLeave={() => setIsImageHovered(false)}
                    >
                        <InputContainer isSmall={isSmall}>
                            <input {...getInputProps()} />

                            {image && image.url ? (
                                <PreviewImage
                                    src={
                                        isImageHovered && image.thumbhash
                                            ? ThumbHash.thumbHashToDataURL(base64ToArrayBuffer(image.thumbhash))
                                            : image.url
                                    }
                                    alt="Preview"
                                    isSmall={isSmall}
                                />
                            ) : isDragActive ? (
                                <p>Drop the files here ...</p>
                            ) : (
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            )}
                        </InputContainer>
                    </UploadContainer>
                </FileContainer>
            </ImageContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ImageContainer = styled.div<{ hasVerticalDisplay: boolean }>`
    display: flex;
    flex-direction: ${({ hasVerticalDisplay }) => (hasVerticalDisplay ? 'column' : 'row')};
    gap: 10px;
`;

const FileContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const UploadContainer = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
`;

const InputContainer = styled.div<{ isSmall: boolean }>`
    width: ${({ isSmall }) => (isSmall ? 100 : 200)}px;
    height: ${({ isSmall }) => (isSmall ? 100 : 200)}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    text-align: center;
`;

const PreviewImage = styled.img<{ isSmall: boolean }>`
    width: ${({ isSmall }) => (isSmall ? 100 : 200)}px;
    height: ${({ isSmall }) => (isSmall ? 100 : 200)}px;
    object-fit: contain;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;
